import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RootState } from "../../app/store";
import { getMotives, getRequestConfig, isFormatPercentage, isFormatStep1, isFormatStep2, setMotives } from "../../global/Utils";
import {
    AddOrUpdateTurnOverRequest,
    AddOrUpdateTurnOverResponse,
    ExistsObjectiveRequest,
    ExistsObjectiveResponse,
    GetAllPagedRequest,
    GetAllPagedRequestData,
    GetAllPagedResponse,
    GetDetailRequestData,
    GetDetailResponse,
    SubmissionsStateData
} from "../../models/SubmissionsModels";

export const getAllPaged: any = createAsyncThunk(
    'submissions/getAllPaged',
    async (requestData: GetAllPagedRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            let requestBody: GetAllPagedRequest;
            if (requestData) {
                requestBody = {
                    page: requestData.page,
                    pageSize: requestData.pageSize,
                    filter: requestData.filter,
                    isManagement: requestData.isManagement
                };
            } else {
                requestBody = {
                    page: allStates.submissions.currentPage,
                    pageSize: allStates.submissions.entriesNumberSelected.id,
                    filter: allStates.submissions.searchValue,
                    isManagement: !allStates.submissions.isEmployees
                };
            }
            const resp = await axios.post('/formFill/getAllPaged', requestBody, requestConfig);
            return resp.data as GetAllPagedResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const addOrUpdateTurnOver: any = createAsyncThunk(
    'submissions/addOrUpdateTurnOver',
    async (requestData: any, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            let requestBody: AddOrUpdateTurnOverRequest | null;
            if (allStates.submissions.currentForm.card !== null) {
                requestBody = {
                    storeNum: allStates.submissions.currentForm.card.storeNum,
                    year: allStates.submissions.currentForm.card.year,
                    month: allStates.submissions.currentForm.card.month,
                    isManagement: !allStates.submissions.isEmployees,
                    objectiveValue: parseInt(allStates.submissions.currentForm.step1.objective),
                    turnOverInfo: {
                        actives: parseInt(allStates.submissions.currentForm.step1.actives),
                        exits90Days: parseInt(allStates.submissions.currentForm.step1.exits90Days),
                        exits: parseInt(allStates.submissions.currentForm.step1.exitsEmployees),
                        hirings: parseInt(allStates.submissions.currentForm.step1.hirings),
                        didntStart: parseInt(allStates.submissions.currentForm.step1.didntStart),
                        hirings90Days: parseInt(allStates.submissions.currentForm.step1.hirings90Days),
                        exitsEntEmp: parseInt(allStates.submissions.currentForm.step1.exitsContractors),
                        turnOverMotives: getMotives(allStates.submissions.currentForm.step2)
                    }
                };
            } else {
                requestBody = null;
            }
            const resp = await axios.post('/formFill/addOrUpdateTurnOver', requestBody, requestConfig);
            return resp.data as AddOrUpdateTurnOverResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getDetail: any = createAsyncThunk(
    'submissions/getDetail',
    async (requestData: GetDetailRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = {
                ...getRequestConfig(allStates),
                params: {
                    TurnOverId: requestData.turnOverId,
                    IsManagement: requestData.isManagement
                }
            };
            const resp = await axios.get('/formFill/getDetail', requestConfig);
            return resp.data as GetDetailResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const existsObjective: any = createAsyncThunk(
    'submissions/existsObjective',
    async (requestData: any, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBody: ExistsObjectiveRequest = {
                storeNumber: allStates.submissions.currentForm.card ? allStates.submissions.currentForm.card?.storeNum : 0,
                year: allStates.submissions.currentForm.card ? allStates.submissions.currentForm.card?.year : 0,
                month: allStates.submissions.currentForm.card ? allStates.submissions.currentForm.card?.month : 0
            };
            const resp = await axios.post('/administration/existsObjective', requestBody, requestConfig);
            return resp.data as ExistsObjectiveResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

const initialState: SubmissionsStateData = {
    isInitialLoading: true,
    cards: [],
    isEntriesNumberOpen: false,
    isEdit: false,
    resultsNumber: 0,
    isResultsNumberHidden: true,
    entriesNumber: [
        {
            id: 6,
            description: '6'
        },
        {
            id: 12,
            description: '12'
        },
        {
            id: 24,
            description: '24'
        }
    ],
    entriesNumberSelected: {
        id: 6,
        description: '6'
    },
    currentPage: 1,
    totalPages: 0,
    totalPagesArray: [],
    visiblePagesArray: [],
    isEmployees: true,
    currentForm: {
        card: null,
        hasObjective: false,
        step1: {
            objective: '',
            exitsEmployees: '',
            exitsContractors: '',
            actives: '',
            hirings: '',
            exits90Days: '',
            hirings90Days: '',
            didntStart: '',
            hasErrors: false
        },
        step2: {
            less90Days: ['', '', '', '', '', '', '', ''],
            more90Days: ['', '', '', '', '', '', '', '']
        }
    },
    activeStep: 1,
    stepLabels: [{ label: 1 }, { label: 2 }],
    searchValue: '',
    isSearchBarHidden: false,
    isBeforeMarch: false,
    toast: [],
    currentToastId: 0,
    nextToastId: 1
};

const submissionsSlice = createSlice({
    name: 'submissions',
    initialState,
    reducers: {
        closeToast: (state, action) => {
            const toastArray = state.toast.filter((toast) => toast.id !== action.payload );
            state.toast = toastArray;
        },
        clearToasts: (state) => {
            state.toast = [];
        },
        addToast: (state, action) => {
            state.currentToastId = state.nextToastId;
            state.toast.push({
                ...action.payload,
                id: state.nextToastId
            });
            state.nextToastId++;
        },
        toggleDropdown: (state) => {
            state.isEntriesNumberOpen = !state.isEntriesNumberOpen;
        },
        changeNumberResults: (state, action) => {
            state.entriesNumberSelected = action.payload;
        },
        calcTotalPages: (state, action) => {
            const totalPages = Math.ceil(state.resultsNumber / state.entriesNumberSelected.id);
            state.totalPages = totalPages;
            state.totalPagesArray = [];
            for (let i = 0; i < totalPages; i++) {
                state.totalPagesArray.push(i + 1);
            }
            const currentPage = action.payload !== null ? action.payload : state.currentPage;
            if (totalPages <= 8) {
                state.visiblePagesArray = state.totalPagesArray;
            } else if (currentPage <= 4) {
                let visiblePages = [];
                for (let i = 0; i < 8; i++) {
                    visiblePages.push(i + 1);
                }
                state.visiblePagesArray = visiblePages;
            } else if (currentPage > totalPages - 4) {
                let visiblePages = [];
                for (let i = 0; i < 8; i++) {
                    visiblePages.push(totalPages - 7 + i);
                }
                state.visiblePagesArray = visiblePages;
            } else {
                let visiblePages = [];
                for (let i = 0; i < 8; i++) {
                    visiblePages.push(currentPage - 4 + i);
                }
                state.visiblePagesArray = visiblePages;
            }
        },
        changePage: (state, action) => {
            state.currentPage = action.payload;
        },
        nextPage: (state) => {
            state.currentPage += 1;
        },
        previousPage: (state) => {
            state.currentPage -= 1;
        },
        checkScope: (state, action) => {
            state.isEmployees = action.payload === 'crew';
        },
        changeObjective: (state, action) => {
            if (isFormatPercentage(action.payload)) {
                state.currentForm.step1.objective = action.payload;
            }
        },
        setStep: (state, action) => {
          state.activeStep = action.payload;
        },
        changeExitsEmployees: (state, action) => {
            if (isFormatStep1(action.payload)) {
                state.currentForm.step1.exitsEmployees = action.payload;
            }
        },
        changeExitsContractors: (state, action) => {
            if (isFormatStep1(action.payload)) {
                state.currentForm.step1.exitsContractors = action.payload;
            }
        },
        changeActives: (state, action) => {
            if (isFormatStep1(action.payload)) {
                state.currentForm.step1.actives = action.payload;
            }
        },
        changeHirings: (state, action) => {
            if (isFormatStep1(action.payload)) {
                state.currentForm.step1.hirings = action.payload;
            }
        },
        changeExits90Days: (state, action) => {
            if (isFormatStep1(action.payload)) {
                state.currentForm.step1.exits90Days = action.payload;
            }
        },
        changeHirings90Days: (state, action) => {
            if (isFormatStep1(action.payload)) {
                state.currentForm.step1.hirings90Days = action.payload;
            }
        },
        changeDidntStart: (state, action) => {
            if (isFormatStep1(action.payload)) {
                state.currentForm.step1.didntStart = action.payload;
            }
        },
        toggleStep1Errors: (state) => {
            state.currentForm.step1.hasErrors = true;
        },
        changeMotives: (state, action) => {
            if (isFormatStep2(action.payload.value)) {
                if (action.payload.isLess90Days) {
                    state.currentForm.step2.less90Days[action.payload.motiveNumber - 1] = action.payload.value;
                } else {
                    state.currentForm.step2.more90Days[action.payload.motiveNumber - 1] = action.payload.value;
                }
            }
        },
        changeSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        clearSearchValue: (state) => {
            state.searchValue = '';
        },
        setInitialLoading: (state, action) => {
            state.isInitialLoading = action.payload;
        },
        toggleHideResults: (state, action) => {
            state.isResultsNumberHidden = action.payload;
        },
        setHideSearchBar: (state, action) => {
            state.isSearchBarHidden = action.payload;
        },
        setCurrentCard: (state, action) => {
            state.currentForm.card = action.payload;
        },
        resetForm: (state) => {
            state.isBeforeMarch = false;
            state.currentForm = {
                card: null,
                hasObjective: false,
                step1: {
                    objective: '',
                    exitsEmployees: '',
                    exitsContractors: '',
                    actives: '',
                    hirings: '',
                    exits90Days: '',
                    hirings90Days: '',
                    didntStart: '',
                    hasErrors: false
                },
                step2: {
                    less90Days: ['', '', '', '', '', '', '', '', '', ''],
                    more90Days: ['', '', '', '', '', '', '', '', '', '']
                }
            }
        },
        setIsEdit: (state, action) => {
            state.isEdit = action.payload;
        },
        setIsBeforeMarch: (state, action) => {
            state.isBeforeMarch = action.payload;
        },
    }, extraReducers: {
        // getAllPaged
        [getAllPaged.pending]: (state) => {
        },
        [getAllPaged.fulfilled]: (state, action) => {
            if ( action.payload.statusCode === 200 ) {
                state.cards = action.payload.data.data;
                state.resultsNumber = action.payload.data.recordsTotal;
                state.isInitialLoading = false;
            }
        },
        [getAllPaged.rejected]: (state, action) => {
        },
        // addOrUpdateTurnOver
        [addOrUpdateTurnOver.pending]: (state) => {
        },
        [addOrUpdateTurnOver.fulfilled]: (state, action) => {
            if ( action.payload.statusCode === 200 ) {
            }
        },
        [addOrUpdateTurnOver.rejected]: (state, action) => {
        },
        // getDetail
        [getDetail.pending]: (state) => {
        },
        [getDetail.fulfilled]: (state, action) => {
            if ( action.payload.statusCode === 200 ) {
                const formData = action.payload.data;
                state.currentForm.step1.actives = formData.actives;
                state.currentForm.step1.exitsEmployees = formData.exits;
                state.currentForm.step1.exitsContractors = formData.exitsEmployingEntity;
                state.currentForm.step1.exits90Days = formData.exits90Days;
                state.currentForm.step1.hirings = formData.entrances;
                state.currentForm.step1.hirings90Days = formData.entrances90Days;
                state.currentForm.step1.didntStart = formData.didntStart;
                state.currentForm.step2.less90Days = setMotives(formData.motives, false);
                state.currentForm.step2.more90Days = setMotives(formData.motives, true);
            }
        },
        [getDetail.rejected]: (state, action) => {
        },
        // existsObjective
        [existsObjective.pending]: (state) => {
        },
        [existsObjective.fulfilled]: (state, action) => {
            if ( action.payload.statusCode === 200 ) {
                state.currentForm.hasObjective = action.payload.data
                state.isBeforeMarch = true;
            }
        },
        [existsObjective.rejected]: (state, action) => {
        }
    }
});

export const {
    closeToast,
    clearToasts,
    addToast,
    toggleDropdown,
    changeNumberResults,
    calcTotalPages,
    changePage,
    previousPage,
    nextPage,
    checkScope,
    changeObjective,
    changeExitsEmployees,
    changeExitsContractors,
    changeActives,
    changeHirings,
    changeExits90Days,
    changeHirings90Days,
    toggleStep1Errors,
    changeMotives,
    setStep,
    changeSearchValue,
    clearSearchValue,
    setInitialLoading,
    toggleHideResults,
    setHideSearchBar,
    setCurrentCard,
    resetForm,
    changeDidntStart,
    setIsEdit,
    setIsBeforeMarch
} = submissionsSlice.actions;

export default submissionsSlice.reducer;