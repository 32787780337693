import { FormGroup, Input, Label } from "reactstrap";
import { useAppDispatch } from "../../app/hooks";
import './RadioButtons.scss';

interface RadioButtonsData {
    labels: RadioButtonsLabels[];
    name: string;
    hasYellowBackground?: boolean;
    isInline?: boolean;
    isDisabled?: boolean;
    onChangeAction?: any;
    checkedValue: string;
}

export interface RadioButtonsLabels {
    label: string,
    value: string
}

const RadioButtons = ({ labels, name, hasYellowBackground = false, isInline = false, isDisabled = false, onChangeAction, checkedValue }: RadioButtonsData) => {
    const dispatch = useAppDispatch();
    return (
        <div className={
            "radio-buttons-container" +
            (hasYellowBackground ? ' radio-buttons-black' : ' radio-buttons-yellow') +
            (isInline ? ' radio-buttons-inline' : '')
        }>
            <FormGroup tag="fieldset" disabled={isDisabled}>
                {labels.map((radioButton, index) => {
                    return (
                        <FormGroup check key={index}>
                            <Input name={name} type="radio" checked={checkedValue === radioButton.value} value={radioButton.value} onChange={(event) => {
                                onChangeAction && dispatch(onChangeAction(radioButton.value));
                            }} />
                            
                            <Label for={name} check>{radioButton.label}</Label>
                        </FormGroup>
                    );
                })}
            </FormGroup>
        </div>
    );
}

export default RadioButtons;