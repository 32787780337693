import { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';

import Accordion from '../../private/accordion-custom/Accordion';
import Header from '../../private/header/Header';
import Toast from '../../private/toast/Toast';
import AdministrationData from './administration-data/AdministrationData';

import { clearToasts, closeToast, getAllObjectives, setYearsFilter, toggleIsFiltersOpensAdministration } from '../../features/administration/AdministrationSlice';
import { openFilters } from '../../features/off-canvas-filter/OffCanvasFilterSlice';
import { changeValue, dropdownAdded, dropdownRemoved } from '../../features/dropdown/dropdownSlice';
import { dropCheckboxAdded, dropCheckboxRemoved } from '../../features/dropdownWithCheckbox/DropdownCheckboxSlice';
import { setLoading } from '../../features/main/MainSlice';

import { ErrorInfo } from '../../models/GlobalModels';
import { GetAllObjectivesResponse } from '../../models/AdministrationModels';
import { DropdownSelectors } from '../../private/dropdown/Normal/DropDown';
import { administrationFiltersStoreIds, oldestYearAdministrationFilter } from '../../global/Variables';
import './Administration.scss';

const Administration = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
    const { toast } = useAppSelector((store) => store.administration);
    const { currentToastId } = useAppSelector((store) => store.administration);
    const { isInitialLoading, isFiltersOpen } = useAppSelector((store) => store.administration);
    const nationalData = useAppSelector((store) => store.administration.national);
    const mcOpCoData = useAppSelector((store) => store.administration.mcOpCo);


    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        dispatch(setLoading(true));

        dispatch(toggleIsFiltersOpensAdministration(null));

        dispatch(clearToasts());

        let yearsValues: DropdownSelectors[] = [];
        const currentYear = new Date().getFullYear();
        for (let i = oldestYearAdministrationFilter; i <= currentYear; i++) {
            yearsValues.unshift({
                id: i,
                description: i + ''
            });
        }
        dispatch(setYearsFilter(yearsValues));
        dispatch(dropdownAdded({
          id: administrationFiltersStoreIds.year,
          value: yearsValues[0]
        }));

        dispatch(getAllObjectives()).unwrap()
            .then((resp: GetAllObjectivesResponse) => {
                if (
                    resp.nationalObjectives.statusCode === 200 &&
                    resp.mcOpCoObjectives.statusCode === 200 &&
                    resp.storesFilter.statusCode === 200
                ) {
                    let restaurants = resp.storesFilter.data;
                    restaurants.unshift({
                        id: null,
                        description: t("administration.filters.restaurantsSelectAll")
                    });
                    dispatch(dropCheckboxAdded(
                        {
                            id: administrationFiltersStoreIds.restaurants,
                            value: [],
                            totalArray: restaurants
                        }
                    ));
                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    }, []);

    const handleToggleFilters = () => {
        dispatch(openFilters('administration'));
        dispatch(toggleIsFiltersOpensAdministration(true));
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(closeToast(currentToastId));
        }, 2500);
    }, [currentToastId]);

    const accordionData = [
        {
            id: 1,
            label: t("administration.accordion.national"),
            description: ''
        },
        {
            id: 2,
            label: t("administration.accordion.mcOpCo"),
            description: ''
        }
    ];

    useEffect(() => {
      if (!isFiltersOpen && isFiltersOpen !== null) {
      }
    }, [isFiltersOpen]);

    useLayoutEffect(() => () => {
      dispatch(dropdownRemoved(administrationFiltersStoreIds.year));
      dispatch(dropCheckboxRemoved(administrationFiltersStoreIds.restaurants));
    }, [])

    if (isInitialLoading) {
      return <div className="administration-container"></div>
    } else {
      return (
        <div className="administration-container">
            <Header title={t("administration.header.title")} hasButton={true} handleToggleFilters={handleToggleFilters} />
            <Accordion
                accordionData={accordionData}
                uniqueId="-administration-accordion"
                childComponent={[
                    <AdministrationData data={nationalData} />,
                    <AdministrationData data={mcOpCoData} />
                ]} />
            <Toast data={toast} closeAction={closeToast} />
        </div>
      );
    }
}

export default Administration;