import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import tagsReducer from '../features/tags/TagsSlice';
import dropdownCheckboxReducer from '../features/dropdownWithCheckbox/DropdownCheckboxSlice';
import modalConfirmReducer from '../features/modal-confirm/ModalConfirmSlice';
import modalErrorReducer from '../features/modal-error/ModalErrorSlice';
import navMenuReducer from '../features/nav-menu/NavMenuSlice';
import offCanvasFilterReducer from '../features/off-canvas-filter/OffCanvasFilterSlice';
import dropdownReducer from '../features/dropdown/dropdownSlice';
import dropdownLanguagesReducer from '../features/dropdownLanguages/dropdownLanguagesSlice';
import administrationReducer from '../features/administration/AdministrationSlice';
import dashboardReducer from '../features/dashboard/DashboardSlice';
import authenticationReducer from '../features/authentication/AuthenticationSlice';
import MainReducer from '../features/main/MainSlice';
import SupportReducer from '../features/support/SupportSlice';
import SubmissionsReducer from '../features/submissions/SubmissionsSlice';

export const store = configureStore({
  reducer: {
    tags: tagsReducer,
    dropdowncheckbox: dropdownCheckboxReducer,
    modalConfirm: modalConfirmReducer,
    modalError: modalErrorReducer,
    navMenu: navMenuReducer,
    offCanvasFilter: offCanvasFilterReducer,
    dropdown: dropdownReducer,
    dropdownLanguages: dropdownLanguagesReducer,
    administration: administrationReducer,
    dashboard: dashboardReducer,
    authentication: authenticationReducer,
    main: MainReducer,
    support: SupportReducer,
    submissions: SubmissionsReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
