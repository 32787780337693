import { createSlice } from "@reduxjs/toolkit";
import { MainState } from "../../models/MainModels";

const initialState: MainState = {
    isLoading: false
};

const mainSlice = createSlice({ 
    name: 'main',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            if (action.payload) {
                document.body.classList.add('scroll-disable');
            } else {
                document.body.classList.remove('scroll-disable');
            }
            state.isLoading = action.payload;
        }
    }
});

export const { setLoading } = mainSlice.actions;

export default mainSlice.reducer;