import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Navigate, Route, Routes } from 'react-router-dom';

import Main from './public/main/Main';
import Dashboard from './public/dashboard/Dashboard';
import Support from './public/support/Support';
import Administration from './public/administration/Administration';
import Submissions from './public/submissions/Submissions';
import PageNotFound from './public/page-not-found/PageNotFound';
import ErrorPage from './public/error-page/ErrorPage';
import SubmissionsSearch from './public/submissions/submissions-search/SubmissionsSearch';
import SubmissionsFullPage from './public/submissions/submissions-full-page/SubmissionsFullPage';
import SubmissionsForm from './public/submissions/submissions-form/SubmissionsForm';
import SubmissionsStep1 from './public/submissions/submissions-form/SubmissionsStep1';
import SubmissionsStep2 from './public/submissions/submissions-form/SubmissionsStep2';
import SubmissionsConsult from './public/submissions/submissions-form/SubmissionsConsult';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { useEffect } from 'react';
import { forceLogout, getAuthorization, getRefresh, postAuthorize, postLogout, setProfileId, setUserId, signOut } from './features/authentication/AuthenticationSlice';
import { useNavigate } from "react-router-dom";
import { getParameter, isAuthPath } from './global/Utils';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from './features/dropdownLanguages/dropdownLanguagesSlice';
import { AuthorizationMenu } from './models/AuthenticationModels';
import { setLoading } from './features/main/MainSlice';
import { ErrorInfo } from './models/GlobalModels';

function App() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // Translation
    const { languages, currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
    const { i18n } = useTranslation();

    // Routing constants
    const { menu, isAuth } = useAppSelector((store) => store.authentication);
    const pathname = new URL(window.location.href).pathname.split("/");
    const langPrefix = pathname[1];

    // Authentication
    const { accessToken } = useAppSelector((store) => store.authentication);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        dispatch(setLoading(true));

        // Translation from path lang
        languages.forEach((language) => {
            if (language.lang === langPrefix && language.lang !== currentLanguage.lang) {
                dispatch(changeLanguage(language));
                i18n.changeLanguage(language.lang);
            }
        });

        // Auto Refresh
        setInterval(() => {
            // Get access_token with refresh_token
            dispatch(getRefresh()).unwrap()
                .then(() => { })
                .catch((error: ErrorInfo) => {
                    if (error.status === 302) {
                        document.location.href = error.response;
                    } else {
                        navigate(`/${currentLanguage.lang}/error-page`);
                    }
                });
        }, 13 * 60 * 1000 /* 13 minutes*/);

        // Check if access_token exists
        if (accessToken === null) {

            if (isAuthPath) {
                const logout = getParameter("logout");
                const code = getParameter("code");

                if (logout !== null) {
                    // Remove refresh token
                    dispatch(postLogout(logout)).unwrap()
                        .then((resp: any) => {
                            dispatch(signOut());
                            navigate('/pt/dashboard');
                        })
                        .catch((error: ErrorInfo) => {
                            navigate('/pt/dashboard');
                        });
                } else if (code !== null) {
                    // Get access_token with refresh_token
                    dispatch(postAuthorize(code)).unwrap()
                        .then((resp: string) => {
                            dispatch(getAuthorization(resp)).unwrap()
                                .then(() => { })
                                .catch((error: ErrorInfo) => {
                                    if (error.status === 302) {
                                        document.location.href = error.response;
                                    } else {
                                        navigate(`/${currentLanguage.lang}/error-page`);
                                    }
                                });
                        })
                        .catch((error: ErrorInfo) => {
                            if (error.status === 302) {
                                document.location.href = error.response;
                            } else {
                                navigate(`/${currentLanguage.lang}/error-page`);
                            }
                        });
                }
            } else {
                // Get access_token with refresh_token
                const profileId = getParameter("profileId");
                const userId = getParameter("userId");
                if (profileId !== null || userId !== null) {
                    dispatch(forceLogout()).unwrap()
                        .then((resp: string) => {
                            dispatch(setProfileId(profileId));
                            dispatch(setUserId(userId));
                            dispatch(getRefresh()).unwrap()
                                .then((resp: string) => {
                                    dispatch(getAuthorization(resp)).unwrap()
                                        .then(() => {
                                            window.history.pushState({}, document.title, window.location.pathname);
                                        })
                                        .catch((error: ErrorInfo) => {
                                            if (error.status === 302) {
                                                document.location.href = error.response;
                                            } else {
                                                navigate(`/${currentLanguage.lang}/error-page`);
                                            }
                                        });
                                })
                                .catch((error: ErrorInfo) => {
                                    if (error.status === 302) {
                                        document.location.href = error.response;
                                    } else {
                                        navigate(`/${currentLanguage.lang}/error-page`);
                                    }
                                });
                        })
                        .catch((error: ErrorInfo) => {
                            navigate(`/${currentLanguage.lang}/error-page`);
                        });
                } else {
                    dispatch(getRefresh()).unwrap()
                        .then((resp: string) => {
                            dispatch(getAuthorization(resp)).unwrap()
                                .then(() => { })
                                .catch((error: ErrorInfo) => {
                                    if (error.status === 302) {
                                        document.location.href = error.response;
                                    } else {
                                        navigate(`/${currentLanguage.lang}/error-page`);
                                    }
                                });
                        })
                        .catch((error: ErrorInfo) => {
                            if (error.status === 302) {
                                document.location.href = error.response;
                            } else {
                                navigate(`/${currentLanguage.lang}/error-page`);
                            }
                        });
                }
            }
        }
    }, []);

    return (
        <div className="App">
            <Routes>
                <Route path="/authentication" element={<Main />}></Route>
                <Route path="/authorization" element={<Main />}></Route>
                <Route path="/" element={<Main />}>
                    {
                        menu.map((menu: AuthorizationMenu) => {
                            if (menu.menuId === 1 && menu.visible) {
                                return (<Route key={'dashboard'} path="/:lang/dashboard" element={<Dashboard />}></Route>);
                            } else if (menu.menuId === 2 && menu.visible) {
                                return (<Route key={'support'} path="/:lang/support" element={<Support />}></Route>);
                            } else if (menu.menuId === 3 && menu.visible) {
                                return (<Route key={'administration'} path="/:lang/administration" element={<Administration />}></Route>);
                            } else if (menu.menuId === 4 && menu.visible) {
                                return (
                                    <Route key={'submissions'} path="/:lang/submissions" element={<Submissions />}>
                                        <Route path="/:lang/submissions/search" element={<SubmissionsFullPage />}>
                                            <Route path="/:lang/submissions/search/management" element={<SubmissionsSearch />}></Route>
                                            <Route path="/:lang/submissions/search/crew" element={<SubmissionsSearch />}></Route>
                                            <Route path="/:lang/submissions/search/form" element={<SubmissionsForm />}>
                                                <Route path="/:lang/submissions/search/form/step1" element={<SubmissionsStep1 />}></Route>
                                                <Route path="/:lang/submissions/search/form/step2" element={<SubmissionsStep2 />}></Route>
                                            </Route>
                                            <Route path="/:lang/submissions/search/consult" element={<SubmissionsConsult />}></Route>
                                        </Route>
                                    </Route>
                                );
                            } else {
                                return false;
                            }
                        })
                    }
                </Route>
                <Route path="/:lang/error-page" element={<ErrorPage />}></Route>
                <Route path="/:lang/page-not-found" element={<PageNotFound />}></Route>
                <Route
                    path="*"
                    element={!isAuth ? <Main /> : (langPrefix === "pt" || langPrefix === "en") ? <Navigate to={`/${langPrefix}/page-not-found`} /> : <Navigate to={"/pt/page-not-found"} />
                    }>
                </Route>
            </Routes>
        </div >
    );
}

export default App;