import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ChevronLeftIcon } from '../../../images/icons';
import Buttons from '../../../private/buttons/Buttons';
import { checkScope, clearToasts, closeToast, setInitialLoading } from '../../../features/submissions/SubmissionsSlice';
import { getCurrentPath } from '../../../global/Utils';
import Toast from '../../../private/toast/Toast';

const SubmissionsFullPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
  const { isEmployees, isInitialLoading, toast, currentToastId } = useAppSelector((store) => store.submissions);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const currentPath = getCurrentPath(location.pathname);
    dispatch(setInitialLoading(true));
    dispatch(clearToasts());
    if (currentPath === 'search') {
      navigate(`/${currentLanguage.lang}/submissions`);
    } else {
      dispatch(checkScope(currentPath));
    }
  }, []);

  useEffect(() => {
      setTimeout(() => {
          dispatch(closeToast(currentToastId));
      }, 2500);
  }, [currentToastId]);

  const handleGoBack = () => {
    const currentPath = getCurrentPath(location.pathname);
    if (currentPath === 'step1' || currentPath === 'consult' ) {
      navigate(`/${currentLanguage.lang}/submissions/search/${isEmployees ? 'crew': 'management'}`);
    } else if (currentPath === 'step2') {
      navigate(`/${currentLanguage.lang}/submissions/search/form/step1`);
    } else {
      navigate(`/${currentLanguage.lang}/submissions`);
    }
  };

  return (
    <div className={"submissions-search-container" + (isInitialLoading ? " submissions-search-container-hidden" : "")}>
      <div className="submissions-go-back">
        <Buttons
          label={t("submissions.goBackButtonLabel")}
          handleOnClick={handleGoBack}
          icon={<ChevronLeftIcon />}/>
      </div>
      <div className="submissions-search-wrapper">
        {
          isEmployees ?
          <div className="submissions-search-title-wrapper">
            <span className="submissions-search-title">{t("submissions.cards.employees")}</span>
            <span className="submissions-search-sub-title">{t("submissions.cards.employeesDescription")}</span>
          </div> :
          <div className="submissions-search-title-wrapper">
            <span className="submissions-search-title">{t("submissions.cards.teamManagement")}</span>
            <span className="submissions-search-sub-title">{t("submissions.cards.teamManagementDescription")}</span>
          </div>
        }
        <Outlet />
        <Toast data={toast} closeAction={closeToast} />
      </div>
    </div>
  );
}

export default SubmissionsFullPage;