import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RootState } from "../../app/store";
import { getDashboardRequestBody, getRequestConfig, getDashboardSelectedFiltersRequestBody } from "../../global/Utils";
import {
    DashboardState,
    EvolutionTableRequestData,
    GetEvolutionRestaurantTableResponse,
    GetEvolutionTableResponse,
    GetFilterInfoResponse,
    GetFiltersResponse,
    GetWhereChartResponse,
    GetWhyChartResponse,
    PostInitialInfoRequestData,
    PostInitialInfoResponse,
    TurnoverRequest,
    WhereChartRequestData,
    WhyChartRequestData
} from "../../models/DashboardModels";

export const postInitialInfo: any = createAsyncThunk(
    'dashboard/postInitialInfo',
    async (postInitialInfoRequestData: PostInitialInfoRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBody: TurnoverRequest = getDashboardRequestBody(allStates);
            requestBody.dashboardViewType = postInitialInfoRequestData.dashboardViewType !== null ? parseInt(postInitialInfoRequestData.dashboardViewType) : 1;
            if (postInitialInfoRequestData.isResetFilters) {
                requestBody.franchiseeIds = [];
                requestBody.storeIds = [];
                requestBody.consultantsIds = [];
                requestBody.regionIds = [];
                requestBody.tipologyIds = [];
            }
            let requestBodyWhyChartLess90Days: TurnoverRequest = {
                ...requestBody
            };
            requestBodyWhyChartLess90Days.isLessThan90Days = true;
            const [
                filterInfoResponse,
                whereChartByMarketResponse,
                whereChartByTypeResponse,
                whyChartLess90DaysResponse,
                whyChartMore90DaysResponse,
                getFiltersResponse
            ] = await Promise.all([
                axios.post('/dashboard/getFilterInfo', requestBody, requestConfig),
                axios.post('/dashboard/getWhereChartByMarket', requestBody, requestConfig),
                axios.post('/dashboard/getWhereChartByType', requestBody, requestConfig),
                axios.post('/dashboard/getWhyChart', requestBodyWhyChartLess90Days, requestConfig),
                axios.post('/dashboard/getWhyChart', requestBody, requestConfig),
                axios.post('/dashboard/getFilters', requestBody, requestConfig)
            ]);
            const resp: PostInitialInfoResponse = {
                cardsAndWhenChart: filterInfoResponse.data as GetFilterInfoResponse,
                whereChartByMarket: whereChartByMarketResponse.data as GetWhereChartResponse,
                whereChartByType: whereChartByTypeResponse.data as GetWhereChartResponse,
                whyChartLess90Days: whyChartLess90DaysResponse.data as GetWhyChartResponse,
                whyChartMore90Days: whyChartMore90DaysResponse.data as GetWhyChartResponse,
                filters: getFiltersResponse.data as GetFiltersResponse
            };
            return resp;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getFilters: any = createAsyncThunk(
    'dashboard/getFilters',
    async (dashboardViewType: string | null = null, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            let requestBody: TurnoverRequest;
            if (dashboardViewType) {
                requestBody = getDashboardSelectedFiltersRequestBody(allStates);
                requestBody.dashboardViewType = parseInt(dashboardViewType);
            } else {
                requestBody = getDashboardRequestBody(allStates);
            }
            const resp = await axios.post('/dashboard/getFilters', requestBody, requestConfig);
            return resp.data as GetFiltersResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getWhereChartByMarket: any = createAsyncThunk(
    'dashboard/getWhereChartByMarket',
    async (requestData: WhereChartRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBody: TurnoverRequest = getDashboardRequestBody(allStates);
            requestBody.isManagement = requestData.isManagementStaff;
            requestBody.isLastYear = requestData.isLastYear;
            requestBody.isLastMonth = requestData.isLastMonth;
            const resp = await axios.post('/dashboard/getWhereChartByMarket', requestBody, requestConfig);
            return resp.data as GetWhereChartResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getWhereChartByType: any = createAsyncThunk(
    'dashboard/getWhereChartByType',
    async (requestData: WhereChartRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBody: TurnoverRequest = getDashboardRequestBody(allStates);
            requestBody.isManagement = requestData.isManagementStaff;
            requestBody.isLastYear = requestData.isLastYear;
            requestBody.isLastMonth = requestData.isLastMonth;
            const resp = await axios.post('/dashboard/getWhereChartByType', requestBody, requestConfig);
            return resp.data as GetWhereChartResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getWhyChartLess90Days: any = createAsyncThunk(
    'dashboard/getWhyChartLess90Days',
    async (requestData: WhyChartRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            let requestBody: TurnoverRequest = getDashboardRequestBody(allStates);
            requestBody.isManagement = requestData.isManagementStaff;
            requestBody.isLessThan90Days = requestData.isLessThan90Days;
            const resp = await axios.post('/dashboard/getWhyChart', requestBody, requestConfig);
            return resp.data as GetWhyChartResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getWhyChartMore90Days: any = createAsyncThunk(
    'dashboard/getWhyChartMore90Days',
    async (requestData: WhyChartRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBody: TurnoverRequest = getDashboardRequestBody(allStates);
            requestBody.isManagement = requestData.isManagementStaff;
            requestBody.isLessThan90Days = requestData.isLessThan90Days;
            const resp = await axios.post('/dashboard/getWhyChart', requestBody, requestConfig);
            return resp.data as GetWhyChartResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getEvolutionTable: any = createAsyncThunk(
    'dashboard/getEvolutionTable',
    async (requestData: EvolutionTableRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBody: TurnoverRequest = getDashboardRequestBody(allStates);
            requestBody.isManagement = requestData.isManagement;
            requestBody.isLastYear = !requestData.isLastMonth;
            requestBody.isLastMonth = requestData.isLastMonth;
            requestBody.is90Days = requestData.is90Days;
            const resp = await axios.post('/dashboard/getEvolutionTable', requestBody, requestConfig);
            return resp.data as GetEvolutionTableResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getEvolutionRestaurantTable: any = createAsyncThunk(
    'dashboard/getEvolutionRestaurantTable',
    async (requestData: EvolutionTableRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBody: TurnoverRequest = getDashboardRequestBody(allStates);
            requestBody.isManagement = requestData.isManagement;
            requestBody.isLastYear = !requestData.isLastMonth;
            requestBody.isLastMonth = requestData.isLastMonth;
            requestBody.is90Days = requestData.is90Days;
            const resp = await axios.post('/dashboard/getEvolutionRestaurantTable', requestBody, requestConfig);
            return resp.data as GetEvolutionRestaurantTableResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

const initialState: DashboardState = {
    isInitialLoading: true,
    dropdownHeader: {
        isOpen: true,
        currentOption: null
    },
    appliedFiltersDropdownHeader: {
        id: '0',
        description: ''
    },
    cardsInfo: null,
    modalGrid: {
        isOpen: false,
        isLastMonth: false,
        isManagement: false,
        is90Days: false,
        data: []
    },
    modalLargeGrid: {
        isOpen: false,
        data: [],
        isCompareOpen: [],
        areAllComparesOpen: false,
        hasValues: null
    },
    details: {
        region: {
            labels: [],
            tn: [],
            compareTn: [],
            isManagementStaff: false,
            isLastYear: false,
            isLastMonth: false
        },
        typology: {
            labels: [],
            tn: [],
            compareTn: [],
            isManagementStaff: false,
            isLastYear: false,
            isLastMonth: false
        },
        whyLess90Days: {
            labels: [],
            tn: [],
            isManagementStaff: false,
            isLessThan90Days: true
        },
        whyMore90Days: {
            labels: [],
            tn: [],
            isManagementStaff: false,
            isLessThan90Days: false
        }
    },
    filters: {
        year: [],
        month: [],
        ttmMonthlyYtdRadios: [],
        ttmMonthlyYtdValue: '',
        appliedFilters: {
            month: null,
            year: null,
            timeFilterType: '1',
            franchiseeIds: [],
            storeIds: [],
            consultantsIds: [],
            regionIds: [],
            tipologyIds: []
        }
    },
    isFiltersOpen: null
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        toggleModalGrid: (state) => {
            state.modalGrid.isOpen = !state.modalGrid.isOpen;
            state.modalGrid.isLastMonth = false;
        },
        toggleLargeGrid: (state) => {
            state.modalLargeGrid.isOpen = !state.modalLargeGrid.isOpen;
        },
        toggleRow: (state, action) => {
            state.modalLargeGrid.isCompareOpen[action.payload] = !state.modalLargeGrid.isCompareOpen[action.payload];
            let areAllComparesOpen = true;
            state.modalLargeGrid.isCompareOpen.forEach((isOpen: boolean, index: number) => {
                if (isOpen === false && index % 2 !== 0) {
                    areAllComparesOpen = false;
                }
            });
            state.modalLargeGrid.areAllComparesOpen = areAllComparesOpen;
        },
        toggleAllRows: (state) => {
            state.modalLargeGrid.isCompareOpen = new Array(state.modalLargeGrid.data.length).fill(!state.modalLargeGrid.areAllComparesOpen);
            state.modalLargeGrid.areAllComparesOpen = !state.modalLargeGrid.areAllComparesOpen;
        },
        modalToggleSwitch: (state, action) => {
            state.modalGrid.isLastMonth = action.payload;
        },
        updateModalGrid: (state, action) => {
            state.modalGrid.isManagement = action.payload.isManagement;
            state.modalGrid.is90Days = action.payload.is90Days;
        },
        updateOption: (state, action) => {
            state.dropdownHeader.currentOption = action.payload;
        },
        setDefaultDropdownHeader: (state, action) => {
            state.dropdownHeader.currentOption = action.payload;
        },
        setCustomDropdownHeader: (state) => {
            state.dropdownHeader.currentOption = state.appliedFiltersDropdownHeader;
        },
        toggleRegionChart: (state, action) => {
            state.details.region.isManagementStaff = action.payload;
        },
        toggleRegionChartYear: (state, action) => {
            state.details.region.isLastYear = action.payload;
            state.details.region.isLastMonth = action.payload ? !action.payload : action.payload;
        },
        toggleRegionChartMonth: (state, action) => {
            state.details.region.isLastMonth = action.payload;
            state.details.region.isLastYear = action.payload ? !action.payload : action.payload;
        },
        toggleTypologyChart: (state, action) => {
            state.details.typology.isManagementStaff = action.payload;
        },
        toggleTypologyChartYear: (state, action) => {
            state.details.typology.isLastYear = action.payload;
            state.details.typology.isLastMonth = action.payload ? !action.payload : action.payload;
        },
        toggleTypologyChartMonth: (state, action) => {
            state.details.typology.isLastMonth = action.payload;
            state.details.typology.isLastYear = action.payload ? !action.payload : action.payload;
        },
        checkTtmMonthlyYtd: (state, action) => {
            state.filters.ttmMonthlyYtdValue = action.payload;
        },
        clearTtmMonthlyYtd: (state) => {
            state.filters.ttmMonthlyYtdValue = '';
        },
        setYearsFilter: (state, action) => {
            state.filters.year = action.payload;
        },
        setMonthsFilter: (state, action) => {
            state.filters.month = action.payload;
        },
        setTTMMonthlyYtdRadiosFilter: (state, action) => {
            state.filters.ttmMonthlyYtdRadios = action.payload;
        },
        setAppliedFilters: (state, action) => {
            state.filters.appliedFilters.year = action.payload.year;
            state.filters.appliedFilters.month = action.payload.month;
            state.filters.appliedFilters.timeFilterType = action.payload.ttmMonthlyYtdValue;
            state.filters.appliedFilters.franchiseeIds = action.payload.franchiseeIds;
            state.filters.appliedFilters.storeIds = action.payload.storeIds;
            state.filters.appliedFilters.consultantsIds = action.payload.consultantsIds;
            state.filters.appliedFilters.regionIds = action.payload.regionIds;
            state.filters.appliedFilters.tipologyIds = action.payload.tipologyIds;
        },
        toggleWhyChartLess90Days: (state, action) => {
            state.details.whyLess90Days.isManagementStaff = action.payload;
        },
        toggleWhyChartMore90Days: (state, action) => {
            state.details.whyMore90Days.isManagementStaff = action.payload;
        },
        toggleIsFiltersOpens: (state, action) => {
            state.isFiltersOpen = action.payload;
        }
    },
    extraReducers: {
        // postInitialInfo
        [postInitialInfo.pending]: (state) => {
        },
        [postInitialInfo.fulfilled]: (state, action) => {
            if (
                action.payload.cardsAndWhenChart.statusCode === 200 &&
                action.payload.whereChartByMarket.statusCode === 200 &&
                action.payload.whereChartByType.statusCode === 200 &&
                action.payload.whyChartLess90Days.statusCode === 200 &&
                action.payload.whyChartMore90Days.statusCode === 200 &&
                action.payload.filters.statusCode === 200
            ) {
                state.cardsInfo = action.payload.cardsAndWhenChart.data;

                if (action.payload.cardsAndWhenChart.data.hasTurnOverData) {
                    // Where Region Chart
                    const whereRegionData = action.payload.whereChartByMarket.data;
                    state.details.region.labels = whereRegionData.xLabels;
                    whereRegionData.yValues.forEach((yValue: number[], index: number) => {
                        if (index === 0 && JSON.stringify(state.details.region.tn) !== JSON.stringify(yValue)) {
                            state.details.region.tn = yValue;
                        } else if (index === 1) {
                            state.details.region.compareTn = yValue;
                        }
                    });

                    // Where Typology Chart
                    const whereTypologyData = action.payload.whereChartByType.data;
                    state.details.typology.labels = whereTypologyData.xLabels;
                    whereTypologyData.yValues.forEach((yValue: number[], index: number) => {
                        if (index === 0 && JSON.stringify(state.details.typology.tn) !== JSON.stringify(yValue)) {
                            state.details.typology.tn = yValue;
                        } else if (index === 1) {
                            state.details.typology.compareTn = yValue;
                        }
                    });

                    // Why Less 90 Days Chart
                    const whyLess90DaysData = action.payload.whyChartLess90Days.data;
                    state.details.whyLess90Days.labels = whyLess90DaysData.xLabels;
                    state.details.whyLess90Days.tn = whyLess90DaysData.yValues;

                    // Why More 90 Days Chart
                    const whyMore90DaysData = action.payload.whyChartMore90Days.data;
                    state.details.whyMore90Days.labels = whyMore90DaysData.xLabels;
                    state.details.whyMore90Days.tn = whyMore90DaysData.yValues;
                }
                state.isInitialLoading = false;
            }
        },
        [postInitialInfo.rejected]: (state, action) => {
        },
        // getFilters
        [getFilters.pending]: (state) => {
        },
        [getFilters.fulfilled]: (state, action) => {
            if (action.payload.statusCode === 200) {
            }
        },
        [getFilters.rejected]: (state, action) => {
        },
        // getWhereChartByMarket
        [getWhereChartByMarket.pending]: (state) => {
        },
        [getWhereChartByMarket.fulfilled]: (state, action) => {
            if (action.payload.statusCode === 200) {
                state.details.region.labels = action.payload.data.xLabels;
                action.payload.data.yValues.forEach((yValue: number[], index: number) => {
                    if (index === 0) {
                        state.details.region.tn = yValue;
                    } else if (index === 1) {
                        state.details.region.compareTn = yValue;
                    }
                });
            }
        },
        [getWhereChartByMarket.rejected]: (state, action) => {
        },
        // getWhereChartByType
        [getWhereChartByType.pending]: (state) => {
        },
        [getWhereChartByType.fulfilled]: (state, action) => {
            if (action.payload.statusCode === 200) {
                state.details.typology.labels = action.payload.data.xLabels;
                action.payload.data.yValues.forEach((yValue: number[], index: number) => {
                    if (index === 0) {
                        state.details.typology.tn = yValue;
                    } else if (index === 1) {
                        state.details.typology.compareTn = yValue;
                    }
                });
            }
        },
        // getWhyChartLess90Days
        [getWhyChartLess90Days.pending]: (state) => {
        },
        [getWhyChartLess90Days.fulfilled]: (state, action) => {
            if (action.payload.statusCode === 200) {
                state.details.whyLess90Days.labels = action.payload.data.xLabels;
                state.details.whyLess90Days.tn = action.payload.data.yValues;
            }
        },
        [getWhyChartLess90Days.rejected]: (state, action) => {
        },
        // getWhyChartMore90Days
        [getWhyChartMore90Days.pending]: (state) => {
        },
        [getWhyChartMore90Days.fulfilled]: (state, action) => {
            if (action.payload.statusCode === 200) {
                state.details.whyMore90Days.labels = action.payload.data.xLabels;
                state.details.whyMore90Days.tn = action.payload.data.yValues;
            }
        },
        [getWhyChartMore90Days.rejected]: (state, action) => {
        },
        // getEvolutionTable
        [getEvolutionTable.pending]: (state) => {
        },
        [getEvolutionTable.fulfilled]: (state, action) => {
            if (action.payload.statusCode === 200) {
                state.modalGrid.data = action.payload.data;
            }
        },
        [getEvolutionTable.rejected]: (state, action) => {
        },
        // getEvolutionRestaurantTable
        [getEvolutionRestaurantTable.pending]: (state) => {
        },
        [getEvolutionRestaurantTable.fulfilled]: (state, action) => {
            if (action.payload.statusCode === 200) {
                state.modalLargeGrid.data = action.payload.data;
                state.modalLargeGrid.isCompareOpen = new Array(state.modalLargeGrid.data.length).fill(false);
            }
        },
        [getEvolutionRestaurantTable.rejected]: (state, action) => {
        }
    }
});

export const {
    toggleModalGrid,
    toggleLargeGrid,
    toggleRow,
    toggleAllRows,
    modalToggleSwitch,
    updateModalGrid,
    updateOption,
    setDefaultDropdownHeader,
    setCustomDropdownHeader,
    clearTtmMonthlyYtd,
    checkTtmMonthlyYtd,
    toggleRegionChart,
    toggleRegionChartYear,
    toggleRegionChartMonth,
    toggleTypologyChart,
    toggleTypologyChartYear,
    toggleTypologyChartMonth,
    setTTMMonthlyYtdRadiosFilter,
    setMonthsFilter,
    setYearsFilter,
    setAppliedFilters,
    toggleWhyChartLess90Days,
    toggleWhyChartMore90Days,
    toggleIsFiltersOpens
} = dashboardSlice.actions;

export default dashboardSlice.reducer;