import { useTranslation } from 'react-i18next';
import { DashboardIcon, ErrorLogoIcon } from '../../images/icons';
import Buttons from '../../private/buttons/Buttons';
import './ErrorPage.scss';


const ErrorPage = () => {
    const handleRedirect = () => {
        window.location.href = '/';
    }

    const {t} = useTranslation();

    return (
        <div className='error-pages-wrapper'>
            <div className='error-container generic-error-page-wrapper'>
                <div className='error-content'>
                    <div className='error-body'>
                        <span><ErrorLogoIcon /></span>
                        <h2>{t("common.errorPages.generalError.title")}</h2>
                        <h5>{t("common.errorPages.generalError.subtitle")}</h5>
                        <p>{t("common.errorPages.generalError.description")} <a href="mailto:service.desk@pt.mcd.com" className='error-link-email' target="_blank" rel="noopener noreferrer">service.desk@pt.mcd.com</a></p>
                    </div>
                    <div className='error-footer'>
                        <Buttons icon={<DashboardIcon />} label={t("common.errorPages.generalError.textButton")} handleOnClick={handleRedirect} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;