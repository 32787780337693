import { useAppSelector } from '../../app/hooks';
import './ProfileBadge.scss';

const ProfileBadge = () => {
    
    const { userName } = useAppSelector((store) => store.authentication);

    return (
        <div className='profile-badge-container'>
            <span className='profile-name'>{ userName }</span>
            { userName &&
                <span className='profile-badge'>{ userName.split(' ')[0][0] + (userName.split(' ').length > 1 ? userName.split(' ')[userName.split(' ').length - 1][0] : '')}</span>
            }
        </div>
    );
}

export default ProfileBadge;