import './DropdownLanguages.scss';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { toggleOpen } from '../../features/dropdownLanguages/dropdownLanguagesSlice';
import { useLocation, useNavigate } from 'react-router-dom';

interface DropdownLanguageData {
    lang?: string,
    onChangeAction?: any
}

const DropdownLanguages = ({ lang, onChangeAction }: DropdownLanguageData) => {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const { isOpen, languages, currentLanguage } = useAppSelector((store) => store.dropdownLanguages);

    const handleLangToggle = () => {
        dispatch(toggleOpen());
    };

    const handleClick = (language: any) => {
        const path = location.pathname.split('/');
        path.shift();
        path.shift();
        path.join('/');
        navigate(`/${language.lang}/${path}`, { replace: true });
        window.location.reload();
    };

    return (
        <div className="languages-container">
            <Dropdown isOpen={isOpen} toggle={handleLangToggle} direction="down">
                <DropdownToggle caret>{currentLanguage.label}</DropdownToggle>
                <DropdownMenu>
                    {languages.map((language, index) => {
                        return <DropdownItem key={index} onClick={() => handleClick(language)} className={language.label === currentLanguage.label ? 'selected-language' : ''}>{language.label}</DropdownItem>
                    })}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

export default DropdownLanguages;