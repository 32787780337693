import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

type Tags = {
    id: string,
    isShowingMore: boolean
}

const tagsAdapter = createEntityAdapter<Tags>();

const tagsSlice = createSlice({
    name: 'tags',
    initialState: tagsAdapter.getInitialState(),
    reducers: {
        tagsAdded: tagsAdapter.addOne,
        toggleShowMore: (state, action) => {
            tagsAdapter.updateOne(state, {
                id: action.payload,
                changes: {
                    isShowingMore: !state.entities[action.payload]?.isShowingMore
                }
            });
        }
    }
});

export const { tagsAdded, toggleShowMore } = tagsSlice.actions;

export default tagsSlice.reducer;