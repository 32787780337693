import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setLoading } from '../../features/main/MainSlice';
import { ChevronRightIcon } from '../../images/icons';
import Header from '../../private/header/Header';
import './Submissions.scss';
import { getCurrentPath } from '../../global/Utils';

const Submissions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const currentPath = getCurrentPath(location.pathname);
    if (currentPath === 'submissions') {
      dispatch(setLoading(false));
    }
  }, [location]);

  const handleClickEmployees = () => {
    navigate(`/${currentLanguage.lang}/submissions/search/crew`);
  };

  const handleClickManagement = () => {
    navigate(`/${currentLanguage.lang}/submissions/search/management`);
  };

  return (
    <div className="submissions-container">
      <div className="submissions-title-container">
        <Header title={t("submissions.header.title")} />
        <span className="submissions-sub-title">{t("submissions.header.subTitle")}</span>
      </div>
      <div className="submissions-body-wrapper">
        <div className="submissions-card" onClick={() => handleClickEmployees()}>
          <div className="submissions-card-title-wrapper">
            <h6 className="submissions-card-title">{t("submissions.cards.employees")}</h6>
            <span className="submissions-sub-card-title">{t("submissions.cards.employeesDescription")}</span>
          </div>
          <ChevronRightIcon />
        </div>
        <div className="submissions-card" onClick={() => handleClickManagement()}>
          <div className="submissions-card-title-wrapper">
            <h6 className="submissions-card-title">{t("submissions.cards.teamManagement")}</h6>
            <span className="submissions-sub-card-title">{t("submissions.cards.teamManagementDescription")}</span>
          </div>
          <ChevronRightIcon />
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default Submissions;