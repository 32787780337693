import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false
};

const modalErrorSlice = createSlice({ 
    name: 'modalError',
    initialState,
    reducers: {
        toggleModalError: (state) => {
            state.isOpen = !state.isOpen;
        }
    }
});

export const { toggleModalError } = modalErrorSlice.actions;

export default modalErrorSlice.reducer;