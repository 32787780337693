import './Switch.scss';

interface SwitchData {
    labels: string[];
    name: string;
    isDisabled?: boolean;
    onChangeHandle?: any;
    value: boolean;
}

const Switch = ({ labels, name, isDisabled = false, onChangeHandle, value }: SwitchData) => {
    return (
        <div className="switch-container">
            <div className="form-check form-switch">
                <input
                    className="form-check-input"
                    name={name}
                    type="checkbox"
                    checked={value}
                    role="switch"
                    disabled={isDisabled}
                    onChange={(event) => {onChangeHandle(event.target.checked)}}/>
                <label className="form-check-label switch-label1" htmlFor={name}>{ labels[0] }</label>
                <label className="form-check-label switch-label2" htmlFor={name}>{ labels[1] }</label>
            </div>
        </div>
    );
}

export default Switch;