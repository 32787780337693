import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RootState } from "../../app/store";
import { getRequestConfig } from "../../global/Utils";
import { GetFaqsResponse, SupportStateData } from "../../models/SupportModels";
import { AccordionLabels } from "../../private/accordion-custom/Accordion";

export const getFaqs: any = createAsyncThunk(
    'administration/getFaqs',
    async (requestData: any, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const resp = await axios.get('/support/getFaqs', requestConfig);
            return resp.data as GetFaqsResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

const initialState: SupportStateData = {
    isInitialLoading: true,
    searchValue: '',
    accordionData: [],
    accordionSearch: [],
    isShowingSearch: false
};

const supportSlice = createSlice({
    name: 'support',
    initialState,
    reducers: {
        changeSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        newSearch: (state, action) => {
            let searchArray: AccordionLabels[] = [];
            action.payload.forEach((result: any) => {
                searchArray.push(result.item);
            });
            state.accordionSearch = searchArray;
            state.isShowingSearch = true;
        },
        setShowingSearch: (state) => {
            state.isShowingSearch = false;
        },
    }, extraReducers: {
        // setMainObjective
        [getFaqs.pending]: (state) => {
        },
        [getFaqs.fulfilled]: (state, action) => {
            if (action.payload.statusCode === 200) {
                state.accordionData = action.payload.data;
                state.isInitialLoading = false;
            }
        },
        [getFaqs.rejected]: (state, action) => {
        }
    }
});

export const { changeSearchValue, newSearch, setShowingSearch } = supportSlice.actions;

export default supportSlice.reducer;