import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  changeExitsEmployees,
  changeExitsContractors,
  changeActives,
  changeHirings,
  changeExits90Days,
  changeHirings90Days,
  toggleStep1Errors,
  setStep,
  changeDidntStart
} from '../../../features/submissions/SubmissionsSlice';
import Buttons from '../../../private/buttons/Buttons';
import TooltipBox from '../../../private/tooltip/TooltipBox';
import { QuestionCircleIcon } from '../../../images/icons';
import { Input, Label } from 'reactstrap';

const SubmissionsStep1 = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
  const { currentForm, isEmployees } = useAppSelector((store) => store.submissions);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(setStep(1));
  }, []);

  const handleExitsEmployees = (value: string) => {
    dispatch(changeExitsEmployees(value));
  };

  const handleExitsContractors = (value: string) => {
    dispatch(changeExitsContractors(value));
  };

  const handleActives = (value: string) => {
    dispatch(changeActives(value));
  };

  const handleHirings = (value: string) => {
    dispatch(changeHirings(value));
  };

  const handleExits90Days = (value: string) => {
    dispatch(changeExits90Days(value));
  };

  const handleHirings90Days = (value: string) => {
    dispatch(changeHirings90Days(value));
  };

  const handleDidntStart = (value: string) => {
    dispatch(changeDidntStart(value));
  };

  const handleGoNext = () => {
    if (
      currentForm.step1.exitsEmployees !== '' &&
      currentForm.step1.exitsContractors !== '' &&
      currentForm.step1.actives !== '' &&
      currentForm.step1.hirings !== '' &&
      currentForm.step1.exits90Days !== '' &&
      currentForm.step1.hirings90Days !== '' &&
      currentForm.step1.didntStart !== ''
    ) {
      navigate(`/${currentLanguage.lang}/submissions/search/form/step2`);
    } else {
      dispatch(toggleStep1Errors());
    }
  };

  return (
    <div className="submissions-step1">
      <div className="submissions-step1-question">
        <div className="submissions-step1-question-title">
          <Label for={'exitsEmployees'} className="submissions-step1-question-label">{t("submissions.step1.question1")}</Label>
          <span className="submissions-tooltip" id="tooltip-submissions-step1-1">
            <QuestionCircleIcon />
          </span>
          <TooltipBox value={t("submissions.step1.tooltip1")} target={'tooltip-submissions-step1-1'} placement={'right'} />
        </div>
        <div className="submissions-multi-input-wrapper">
          <Input
            className={currentForm.step1.hasErrors && currentForm.step1.exitsEmployees === '' ? 'input-error' : ''}
            type="text"
            name={'exitsEmployees'}
            value={currentForm.step1.exitsEmployees}
            onChange={(event) => handleExitsEmployees(event.target.value)}
            placeholder={isEmployees ? t("submissions.step1.placeholder1.1Employees") : t("submissions.step1.placeholder1.1Management")}/>
          <Input
            className={currentForm.step1.hasErrors && currentForm.step1.exitsContractors === '' ? 'input-error' : ''}
            type="text"
            name={'exitsContractors'}
            value={currentForm.step1.exitsContractors}
            onChange={(event) => handleExitsContractors(event.target.value)}
            placeholder={t("submissions.step1.placeholder1.2")}/>
        </div>
        <div className="submissions-multi-input-error">
          <span className={"input-error-message" + ((currentForm.step1.hasErrors && currentForm.step1.exitsEmployees === '') ? " input-error-message-show" : "")}>
            {t("submissions.inputError")}
          </span>
          <span className={"input-error-message" + ((currentForm.step1.hasErrors && currentForm.step1.exitsContractors === '') ? " input-error-message-show" : "")}>
            {t("submissions.inputError")}
          </span>
        </div>
      </div>
      <div className="submissions-step1-question">
        <div className="submissions-step1-question-title">
          <Label for={'actives'}  className="submissions-step1-question-label">{t("submissions.step1.question2")}</Label>
          <span className="submissions-tooltip" id="tooltip-submissions-step1-2">
            <QuestionCircleIcon />
          </span>
          <TooltipBox value={t("submissions.step1.tooltip2")} target={'tooltip-submissions-step1-2'} placement={'right'} />
        </div>
        <Input
          className={currentForm.step1.hasErrors && currentForm.step1.actives === '' ? 'input-error' : ''}
          type="text"
          name={'actives'}
          value={currentForm.step1.actives}
          onChange={(event) => handleActives(event.target.value)}
          placeholder={t("submissions.step1.placeholder2")}/>
        {
          (currentForm.step1.hasErrors && currentForm.step1.actives === '') &&
          <span className="input-error-message">{t("submissions.inputError")}</span>
        }
      </div>
      <div className="submissions-step1-question">
        <div className="submissions-step1-question-title">
          <Label for={'hirings'}  className="submissions-step1-question-label">{t("submissions.step1.question3")}</Label>
          <span className="submissions-tooltip" id="tooltip-submissions-step1-3">
            <QuestionCircleIcon />
          </span>
          <TooltipBox value={t("submissions.step1.tooltip3")} target={'tooltip-submissions-step1-3'} placement={'right'} />
        </div>
        <Input
          className={currentForm.step1.hasErrors && currentForm.step1.hirings === '' ? 'input-error' : ''}
          type="text"
          name={'hirings'}
          value={currentForm.step1.hirings}
          onChange={(event) => handleHirings(event.target.value)}
          placeholder={t("submissions.step1.placeholder3")}/>
        {
          (currentForm.step1.hasErrors && currentForm.step1.hirings === '') &&
          <span className="input-error-message">{t("submissions.inputError")}</span>
        }
      </div>
      <div className="submissions-step1-question">
        <div className="submissions-step1-question-title">
          <Label for={'didntStart'}  className="submissions-step1-question-label">{t("submissions.step1.question6")}</Label>
          <span className="submissions-tooltip" id="tooltip-submissions-step1-6">
            <QuestionCircleIcon />
          </span>
          <TooltipBox value={t("submissions.step1.tooltip6")} target={'tooltip-submissions-step1-6'} placement={'right'} />
        </div>
        <Input
          className={currentForm.step1.hasErrors && currentForm.step1.didntStart === '' ? 'input-error' : ''}
          type="text"
          name={'didntStart'}
          value={currentForm.step1.didntStart}
          onChange={(event) => handleDidntStart(event.target.value)}
          placeholder={t("submissions.step1.placeholder6")}/>
        {
          (currentForm.step1.hasErrors && currentForm.step1.didntStart === '') &&
          <span className="input-error-message">{t("submissions.inputError")}</span>
        }
      </div>
      <div className="submissions-step1-question">
        <div className="submissions-step1-question-title">
          <Label for={'exits90Days'}  className="submissions-step1-question-label">{t("submissions.step1.question4")}</Label>
          <span className="submissions-tooltip" id="tooltip-submissions-step1-4">
            <QuestionCircleIcon />
          </span>
          <TooltipBox value={t("submissions.step1.tooltip4")} target={'tooltip-submissions-step1-4'} placement={'right'} />
        </div>
        <Input
          className={currentForm.step1.hasErrors && currentForm.step1.exits90Days === '' ? 'input-error' : ''}
          type="text"
          name={'exits90Days'}
          value={currentForm.step1.exits90Days}
          onChange={(event) => handleExits90Days(event.target.value)}
          placeholder={t("submissions.step1.placeholder4")}/>
        {
          (currentForm.step1.hasErrors && currentForm.step1.exits90Days === '') &&
          <span className="input-error-message">{t("submissions.inputError")}</span>
        }
      </div>
      <div className="submissions-step1-question">
        <div className="submissions-step1-question-title">
          <Label for={'hirings90Days'}  className="submissions-step1-question-label">{t("submissions.step1.question5")}</Label>
          <span className="submissions-tooltip" id="tooltip-submissions-step1-5">
            <QuestionCircleIcon />
          </span>
          <TooltipBox value={t("submissions.step1.tooltip5")} target={'tooltip-submissions-step1-5'} placement={'right'} />
        </div>
        <Input
          className={currentForm.step1.hasErrors && currentForm.step1.hirings90Days === '' ? 'input-error' : ''}
          type="text"
          name={'hirings90Days'}
          value={currentForm.step1.hirings90Days}
          onChange={(event) => handleHirings90Days(event.target.value)}
          placeholder={t("submissions.step1.placeholder5")}/>
        {
          (currentForm.step1.hasErrors && currentForm.step1.hirings90Days === '') &&
          <span className="input-error-message">{t("submissions.inputError")}</span>
        }
      </div>
      <Buttons
        label={t("submissions.next")}
        handleOnClick={handleGoNext}/>
    </div>
  );
}

export default SubmissionsStep1;