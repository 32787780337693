import "./Stepline.scss";
import Step from "./step/Step";

export interface SteplineNavigationData {
  labels: StepsData[];
  activeStep: number
}

export interface StepsData {
  label: number;
}

const SteplineNavigation = ({ labels, activeStep }: SteplineNavigationData) => {
  return (
    <div className="stepline-container">
      <div className="stepWrapper">
        {labels.map((item, index) => (
          <Step key={index + 1} label={item.label} activeStep={activeStep} />
        ))}
      </div>
    </div>
  );
};

export default SteplineNavigation;
