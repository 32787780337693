import { useAppDispatch } from '../../../app/hooks';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';

import EditCard from '../../../private/edit-card/EditCard';
import SmallInput from '../../../private/small-input/SmallInput';

import {
    openEdit,
    changeValue,
    changeInputValue,
    changeValueTable,
    openEditTable,
    changeInputValueTable,
    cancelEdit,
    cancelEditTable,
    cancelOnChange,
    setMainObjective,
    toggleMainInputError,
    addToast,
    setStoreObjective,
    toggleTableInputError
} from '../../../features/administration/AdministrationSlice';
import { setLoading } from '../../../features/main/MainSlice';
import { toggleModalError } from '../../../features/modal-error/ModalErrorSlice';

import {
    ObjectivesData,
    SetMainObjectiveRequestData,
    SetMainObjectiveResponse,
    SetStoreObjectiveResponse,
    SetTableObjectiveRequestData
} from '../../../models/AdministrationModels';
import { ErrorInfo } from '../../../models/GlobalModels';
import { CheckIcon, CrossIcon, EditIcon } from '../../../images/icons';

interface AdministrationDataProps {
    data: any,
    onChangeAction?: any
}

const AdministrationData = ({ data }: AdministrationDataProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const toast = {
        sucess: {
            type: 'success',
            message: t("administration.toast.success"),
        }
    }

    const tableAdmin = useRef<HTMLElement>(null);
    const tableHeader = useRef<HTMLTableSectionElement>(null);

    useEffect(() => {
        dispatch(cancelOnChange());
        const tableElement = tableAdmin?.current?.firstElementChild;
        const tableHeaderRow = tableHeader?.current;

        tableElement?.addEventListener('scroll', () => {
            if (tableElement?.scrollTop > 0) {
                tableHeaderRow?.classList.add('header-box-shadow');
            } else {
                tableHeaderRow?.classList.remove('header-box-shadow');
            }
        });
    }, [data.id, dispatch]);

    const handleEdit = () => {
        dispatch(openEdit(data.id));
    };

    const handleCancel = () => {
        dispatch(cancelEdit({
            id: data.id
        }));
    };

    const handleChangeValue = () => {
        if (data.globalObjective.editValue !== '') {
            dispatch(setLoading(true));
            const requestData: SetMainObjectiveRequestData = {
                type: data.id,
                objectiveValue: data.globalObjective.editValue
            };
            dispatch(setMainObjective(requestData)).unwrap()
                .then((resp: SetMainObjectiveResponse) => {
                    if (resp.statusCode === 200) {
                        dispatch(changeValue(data.id));
                        dispatch(addToast(toast.sucess));
                    } else {
                        dispatch(toggleModalError());
                    }
                    dispatch(setLoading(false));
                })
                .catch((error: ErrorInfo) => {
                    dispatch(toggleModalError());
                    dispatch(setLoading(false));
                });
        } else {
            dispatch(toggleMainInputError(data.id));
        }
    };

    const handleChangeInputValue = (inputValue: any) => {
        dispatch(changeInputValue({
            id: data.id,
            value: inputValue.value
        }));
    };

    const handleChangeValueTable = (row: number) => {
        if (data.table.edit[row].editValue !== '') {
            dispatch(setLoading(true));
            const requestData: SetTableObjectiveRequestData = {
                type: data.id,
                objectiveValue: data.table.edit[row].editValue,
                storeNumber: data.table.data[row].storeNumber
            };
            dispatch(setStoreObjective(requestData)).unwrap()
                .then((resp: SetStoreObjectiveResponse) => {
                    if (resp.statusCode === 200) {
                        dispatch(changeValueTable({
                            row: row,
                            id: data.id
                        }));
                        dispatch(addToast(toast.sucess));
                    } else {
                        dispatch(toggleModalError());
                    }
                    dispatch(setLoading(false));
                })
                .catch((error: ErrorInfo) => {
                    dispatch(toggleModalError());
                    dispatch(setLoading(false));
                });
        } else {
            dispatch(toggleTableInputError({
                row: row,
                id: data.id
            }));
        }
    };

    const handleEditTable = (row: number) => {
        dispatch(openEditTable({
            row: row,
            id: data.id
        }));
    };

    const handleCancelTable = (row: number) => {
        dispatch(cancelEditTable({
            row: row,
            id: data.id
        }));
    };

    const handleChangeInputValueTable = (inputValue: any) => {
        dispatch(changeInputValueTable({
            id: data.id,
            row: inputValue.id,
            value: inputValue.value
        }));
    };

    return (
        <div className="administration-data-container">
            <div className="administration-global">
                <EditCard
                    title={t("administration.globalObjective")}
                    value={data.globalObjective.value}
                    isEditing={data.globalObjective.isEditing}
                    editValue={data.globalObjective.editValue}
                    sufix="%"
                    handleEdit={handleEdit}
                    handleCancel={handleCancel}
                    handleChangeValue={handleChangeValue}
                    handleChangeInputValue={handleChangeInputValue}
                    hasError={data.globalObjective.hasError}/>
            </div>
            <div className="administration-table-container" id={'tableAdmin-' + data.id} ref={tableAdmin as any}>
                <Table responsive>
                    <thead id="tableHeader" ref={tableHeader}>
                        <tr>
                            <th>{t("administration.tableHeaders.year")}</th>
                            <th>{t("administration.tableHeaders.restaurant")}</th>
                            <th>
                                <span className="objective-column-header">{t("administration.tableHeaders.objective")}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.table.data.map((cellRow: ObjectivesData, row: number) => {
                            return <tr key={row}>
                                <td>
                                    {cellRow.year}
                                </td>
                                <td>
                                    {cellRow.storeName}
                                </td>
                                <td className="small-table-highlight-cell-container yellow-text-cell">
                                    {
                                        data.table.edit[row].isEditing || cellRow.objectiveValue === null ?
                                            <div className="small-table-editable-cell">
                                                <SmallInput
                                                    value={data.table.edit[row].editValue}
                                                    id={row}
                                                    handleChangeInputValue={handleChangeInputValueTable}
                                                    handleEnter={handleChangeValueTable}
                                                    hasError={data.table.edit[row].hasError} />
                                                <div className='small-table-actions'>
                                                    <div className="small-table-icon confirm" onClick={() => handleChangeValueTable(row)}>
                                                        <CheckIcon />
                                                    </div>
                                                    <div className="small-table-icon cancel" onClick={() => handleCancelTable(row)}>
                                                        <CrossIcon />
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="small-table-edit-cell">
                                                <span>
                                                    {cellRow.objectiveValue + '%'}
                                                </span>
                                                <div className="small-table-edit-icon" onClick={() => handleEditTable(row)}>
                                                    <EditIcon />
                                                </div>
                                            </div>
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default AdministrationData;
