import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

type dropCheckbox = {
  id: string;
  value: arrayValues[];
  totalArray: any
};

export type arrayValues = {
  id: string,
  value: boolean,
  label: string
}


const dropCheckboxAdapter = createEntityAdapter<dropCheckbox>();

const dropdownCheckbox = createSlice({
  name: "dropdownCheckbox",
  initialState: dropCheckboxAdapter.getInitialState(),
  reducers: {
    dropCheckboxAdded: dropCheckboxAdapter.addOne,
    dropCheckboxRemoved: dropCheckboxAdapter.removeOne,
    changeValue: (state, action) => {
      let stateArray = state.entities[action.payload.storeId]?.value;
      let totalArray = state.entities[action.payload.storeId]?.totalArray;
      if (stateArray && action.payload.value === true) {
        if (action.payload.id === null) {
          let tempArray: arrayValues[] = [];
          totalArray.forEach((label: any) => {
            tempArray.push(
              {
                id: label.id,
                value: true,
                label: label.description
              }
            );
          });
          stateArray = tempArray;
        } else {
          stateArray?.push({ id: action.payload.id, value: action.payload.value, label: action.payload.label });
          if (stateArray.length === totalArray.length - 1) {
            stateArray?.push(
              {
                id: totalArray[0].id,
                value: true,
                label: totalArray[0].description
              })
          }
        }
      }
      if (stateArray && action.payload.value === false) {
        if (action.payload.id === null) {
          while (stateArray.length > 0) {
            stateArray = [];
          }
        } else {
          let id = action.payload.id;
          let tempArray = stateArray.filter((e, index) => e.id !== id && e.id !== null);
          stateArray = tempArray;
        }
      }
      dropCheckboxAdapter.updateOne(state, {
        id: action.payload.storeId,
        changes: {
          value: stateArray
        },
      });
    },
    clearValue: (state, action) => {
      action.payload.forEach((dropdown: string) => {
        dropCheckboxAdapter.updateOne(state, {
          id: dropdown,
          changes: {
            value: []
          },
        });

      })
    },
    updateTotalArray: (state, action) => {
      dropCheckboxAdapter.updateOne(state, {
        id: action.payload.storeId,
        changes: {
          totalArray: action.payload.totalArray
        },
      });
    },
    setValue: (state, action) => {
      let valueArray = [];
      for (let i = 0; i < action.payload.value.length; i++) {
        if (action.payload.totalArray.length > 1) {
          for (let j = 1; j < action.payload.totalArray.length; j++) {
            if (action.payload.value[i] === action.payload.totalArray[j].id) {
              valueArray.push({
                id: action.payload.value[i],
                value: true,
                label: action.payload.totalArray[j].description
              });
            }
          }
        } else {
          for (let j = 0; j < action.payload.totalArray.length; j++) {
            if (action.payload.value[i] === action.payload.totalArray[j].id) {
              valueArray.push({
                id: action.payload.value[i],
                value: true,
                label: action.payload.totalArray[j].description
              });
            }
          }
        }
      }
      dropCheckboxAdapter.updateOne(state, {
        id: action.payload.storeId,
        changes: {
          totalArray: action.payload.totalArray,
          value: valueArray
        },
      });
    },
    DELETE_ITEM: (state, action) => {
      let stateArray = state.entities[action.payload.storeId]?.value;

      if (stateArray) {
        if (action.payload.id === 1) {
          while (stateArray.length > 0) {
            stateArray.pop();
          }
        } else {
          let id = action.payload.id
          let tempArray = stateArray.filter((e, index) => e.id !== id && e.id !== null);
          stateArray = tempArray;
        }

      }
      dropCheckboxAdapter.updateOne(state, {
        id: action.payload.storeId,
        changes: {
          value: stateArray
        },
      });
    },
  },
});

export const { dropCheckboxAdded, dropCheckboxRemoved, changeValue, DELETE_ITEM, clearValue, updateTotalArray, setValue } = dropdownCheckbox.actions;

export default dropdownCheckbox.reducer;
