import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import NavMenu from '../nav-menu/NavMenu';
import NavbarMenu from '../navbar-menu/NavbarMenu';
import OffCanvasFilter from '../off-canvas-filter/OffCanvasFilter';
import './Main.scss';
import { useParams } from 'react-router-dom';
import { closeFilters } from '../../features/off-canvas-filter/OffCanvasFilterSlice';
import { useEffect } from 'react';
import LoadingPage from '../loading-page/LoadingPage';
import { getCurrentPath, isAuthPath } from '../../global/Utils';
import ModalError from '../../private/modal-error/ModalError';
import ModalConfirm from '../../private/modal-confirm/ModalConfirm';
import { useTranslation } from 'react-i18next';

const Main = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    let location = useLocation();

    const { isOpen } = useAppSelector((store) => store.navMenu);

    const { isAuth } = useAppSelector((store) => store.authentication);
    const { signOut } = useAppSelector((store) => store.authentication);

    const { lang } = useParams();
    const { languages } = useAppSelector((store) => store.dropdownLanguages);

    const { isLoading } = useAppSelector((store) => store.main);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const currentPath = getCurrentPath(location.pathname);
        const currentPathSplit = location.pathname.split('/');
        if(isAuth) {
            if (isAuthPath) {
                navigate('/pt/dashboard');
            } else {
                const pathLang = languages.filter((language) => language.lang === lang);
                if (pathLang.length === 0) {
                    navigate('/pt/dashboard');
                }
            }
        }
        if ((currentPath === 'pt' || currentPath === 'en') && currentPathSplit.length < 3) {
            navigate(`/${currentPath}/dashboard`);
        }
    }, [isAuth]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(closeFilters());
    }, [location]);

    if (signOut) {
        return <h2 style={{ textAlign: "center" }}>{t("common.signedOut")}</h2>
    } else {
        return (
            <div className='main-container'>
                {
                    isLoading && <LoadingPage />
                }
                {
                    isAuth &&
                    <div className='main-wrapper'>
                        <NavbarMenu />
                        <OffCanvasFilter />
                        <ModalError />
                        <ModalConfirm />
                        <div className='main-router-menu-wrapper'>
                            <NavMenu />
                            <div className={'main-router-outlet' + (isOpen ? ' main-router-outlet-menu-open' : '')}>
                                <Outlet />
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Main;