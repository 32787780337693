import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RootState } from "../../app/store";
import { getRequestConfig, getSelectedStores, isFormatPercentage } from "../../global/Utils";
import { administrationFiltersStoreIds } from "../../global/Variables";
import {
    AdministrationRowEdit,
    AdministrationStateData,
    GetAllObjectivesResponse,
    GetObjectivesRequest,
    GetObjectivesResponse,
    GetStoresFilterResponse,
    SetMainObjectiveRequest,
    SetMainObjectiveRequestData,
    SetMainObjectiveResponse,
    SetStoreObjectiveRequest,
    SetStoreObjectiveResponse, 
    SetTableObjectiveRequestData
} from "../../models/AdministrationModels";

export const getAllObjectives: any = createAsyncThunk(
    'administration/getAllObjectives',
    async (requestData: any, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBodyNational: GetObjectivesRequest = {
                year: allStates.dropdown.entities[administrationFiltersStoreIds.year]?.value?.id || null,
                stores: getSelectedStores(allStates.dropdowncheckbox.entities[administrationFiltersStoreIds.restaurants]?.value),
                missingStores: allStates.administration.filters.missingObjective,
                type: allStates.administration.national.id
            };
            const requestBodyMcOpCo: GetObjectivesRequest = {
                ...requestBodyNational
            };
            requestBodyMcOpCo.type = allStates.administration.mcOpCo.id;
            const storesFilterRequestConfig = {
                ...getRequestConfig(allStates),
                params: {
                    year: allStates.dropdown.entities[administrationFiltersStoreIds.year]?.value?.id || null
                }
            };
            const [nationalResponse, mcOpCoResponse, storesFilterResponse] = await Promise.all([
                axios.post('/administration/getObjectives', requestBodyNational, requestConfig),
                axios.post('/administration/getObjectives', requestBodyMcOpCo, requestConfig),
                axios.get('/administration/getStoresFilter', storesFilterRequestConfig)
            ]);
            const resp: GetAllObjectivesResponse = {
                nationalObjectives: nationalResponse.data as GetObjectivesResponse,
                mcOpCoObjectives: mcOpCoResponse.data as GetObjectivesResponse,
                storesFilter : storesFilterResponse.data as GetStoresFilterResponse
            };
            return resp;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getStoresFilter: any = createAsyncThunk(
    'administration/getStoresFilter',
    async (requestData: any, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = {
                ...getRequestConfig(allStates),
                params: {
                    year: allStates.dropdown.entities[administrationFiltersStoreIds.year]?.value?.id || null
                }
            };
            const resp = await axios.get('/administration/getStoresFilter', requestConfig);
            return resp.data as GetStoresFilterResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const setMainObjective: any = createAsyncThunk(
    'administration/setMainObjective',
    async (requestData: SetMainObjectiveRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBody: SetMainObjectiveRequest = {
                id: 0,
                year: allStates.dropdown.entities[administrationFiltersStoreIds.year]?.value?.id || null,
                type: requestData.type,
                objectiveValue: parseInt(requestData.objectiveValue)
            };
            const resp = await axios.post('/administration/setMainObjective', requestBody, requestConfig);
            return resp.data as SetMainObjectiveResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const setStoreObjective: any = createAsyncThunk(
    'administration/setStoreObjective',
    async (requestData: SetTableObjectiveRequestData, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = getRequestConfig(allStates);
            const requestBody: SetStoreObjectiveRequest = {
                id: 0,
                storeNumber: requestData.storeNumber,
                storeName: "",
                year: allStates.dropdown.entities[administrationFiltersStoreIds.year]?.value?.id || null,
                type: requestData.type,
                objectiveValue: parseInt(requestData.objectiveValue)
            };
            const resp = await axios.post('/administration/setStoreObjective', requestBody, requestConfig);
            return resp.data as SetStoreObjectiveResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

const initialState: AdministrationStateData = {
    isInitialLoading: true,
    toast: [],
    currentToastId: 0,
    nextToastId: 1,
    national: {
        id: 1,
        globalObjective: {
            isEditing: false,
            value: null,
            editValue: '',
            hasError: false
        },
        table: {
            data: [],
            edit: []
        }
    },
    mcOpCo: {
        id: 2,
        globalObjective: {
            isEditing: false,
            value: null,
            editValue: '',
            hasError: false
        },
        table: {
            data: [],
            edit: []
        }
    },
    filters: {
        year: [],
        missingObjective: false
    },
    isFiltersOpen: null,
    appliedFilters: {
        year: null,
        restaurants: [],
        missingObjective: false
    }
};

const administrationSlice = createSlice({
    name: 'administration',
    initialState,
    reducers: {
        closeToast: (state, action) => {
            const toastArray = state.toast.filter((toast) => toast.id !== action.payload );
            state.toast = toastArray;
        },
        clearToasts: (state) => {
            state.toast = [];
        },
        addToast: (state, action) => {
            state.currentToastId = state.nextToastId;
            state.toast.push({
                ...action.payload,
                id: state.nextToastId
            });
            state.nextToastId++;
        },
        openEdit: (state, action) => {
            if (action.payload === state.national.id) {
                state.national.globalObjective.editValue = state.national.globalObjective.value === null ?
                    "" :
                    state.national.globalObjective.value + "";
                state.national.globalObjective.isEditing = true;
            } else if (action.payload === state.mcOpCo.id) {
                state.mcOpCo.globalObjective.editValue = state.mcOpCo.globalObjective.value === null ?
                    "" :
                    state.mcOpCo.globalObjective.value + "";
                state.mcOpCo.globalObjective.isEditing = true;
            }
        },
        cancelEdit: (state, action) => {
            if (action.payload.id === state.national.id) {
                state.national.globalObjective.isEditing = false;
            } else if (action.payload.id === state.mcOpCo.id) {
                state.mcOpCo.globalObjective.isEditing = false;
            }
        },
        changeValue: (state, action) => {
            if (action.payload === state.national.id) {
                state.national.globalObjective.value = parseInt(state.national.globalObjective.editValue);
                state.national.globalObjective.isEditing = false;
            } else if (action.payload === state.mcOpCo.id) {
                state.mcOpCo.globalObjective.value = parseInt(state.mcOpCo.globalObjective.editValue);
                state.mcOpCo.globalObjective.isEditing = false;
            }
        },
        toggleMainInputError: (state, action) => {
            if (action.payload === state.national.id) {
                state.national.globalObjective.hasError = true;
            } else if (action.payload === state.mcOpCo.id) {
                state.mcOpCo.globalObjective.hasError = true;
            }
        },
        changeInputValue: (state, action) => {
            if (isFormatPercentage(action.payload.value)) {
                if (action.payload.id === state.national.id) {
                    state.national.globalObjective.editValue = action.payload.value;
                    if (state.national.globalObjective.hasError === true && action.payload.value !== '') {
                        state.national.globalObjective.hasError = false;
                    }
                } else if (action.payload.id === state.mcOpCo.id) {
                    state.mcOpCo.globalObjective.editValue = action.payload.value;
                    if (state.mcOpCo.globalObjective.hasError === true && action.payload.value !== '') {
                        state.mcOpCo.globalObjective.hasError = false;
                    }
                }
            }
        },
        openEditTable: (state, action) => {
            const row = action.payload.row;
            if (action.payload.id === state.national.id) {
                state.national.table.edit[row].isEditing = true;
                state.national.table.edit[row].editValue = state.national.table.data[row].objectiveValue === null ?
                    "" :
                    state.national.table.data[row].objectiveValue + "";
            } else if (action.payload.id === state.mcOpCo.id) {
                state.mcOpCo.table.edit[row].isEditing = true;
                state.mcOpCo.table.edit[row].editValue = state.mcOpCo.table.data[row].objectiveValue === null ?
                "" :
                state.mcOpCo.table.data[row].objectiveValue + "";;
            }
        },
        cancelEditTable: (state, action) => {
            const row = action.payload.row;
            if (action.payload.id === state.national.id) {
                state.national.table.edit[row].isEditing = false;
            } else if (action.payload.id === state.mcOpCo.id) {
                state.mcOpCo.table.edit[row].isEditing = false;
            }
        },
        changeValueTable: (state, action) => {
            const row = action.payload.row;
            if (action.payload.id === state.national.id) {
                state.national.table.data[row].objectiveValue = parseInt(state.national.table.edit[row].editValue);
                state.national.table.edit[row].isEditing = false;
            } else if (action.payload.id === state.mcOpCo.id) {
                state.mcOpCo.table.data[row].objectiveValue = parseInt(state.mcOpCo.table.edit[row].editValue);
                state.mcOpCo.table.edit[row].isEditing = false;
            }
        },
        toggleTableInputError: (state, action) => {
            const row = action.payload.row;
            if (action.payload.id === state.national.id) {
                state.national.table.edit[row].hasError = true;
            } else if (action.payload.id === state.mcOpCo.id) {
                state.mcOpCo.table.edit[row].hasError = true;
            }
        },
        changeInputValueTable: (state, action) => {
            const row = action.payload.row;
            if (isFormatPercentage(action.payload.value)) {
                if (action.payload.id === state.national.id) {
                    state.national.table.edit[row].editValue = action.payload.value;
                    if (state.national.table.edit[row].hasError === true && action.payload.value !== '') {
                        state.national.table.edit[row].hasError = false;
                    }
                } else if (action.payload.id === state.mcOpCo.id) {
                    state.mcOpCo.table.edit[row].editValue = action.payload.value;
                    if (state.mcOpCo.table.edit[row].hasError === true && action.payload.value !== '') {
                        state.mcOpCo.table.edit[row].hasError = false;
                    }
                }
            }
        },
        toggleMissingObjective: (state, action) => {
            state.filters.missingObjective = action.payload;
        },
        clearMissingObjective: (state) => {
            state.filters.missingObjective = false;
        },
        cancelOnChange: (state) => {
            state.national.globalObjective.isEditing = false;
            state.mcOpCo.globalObjective.isEditing = false;
        },
        setYearsFilter: (state, action) => {
            state.filters.year = action.payload;
        },
        toggleIsFiltersOpensAdministration: (state, action) => {
            state.isFiltersOpen = action.payload;
        },
        setAppliedFilters: (state, action) => {
            state.appliedFilters.year = action.payload.year;
            state.appliedFilters.missingObjective = action.payload.missingObjective;
            state.appliedFilters.restaurants = action.payload.restaurants;
        }
    }, extraReducers: {
        // getObjectives
        [getAllObjectives.pending]: (state) => {
        },
        [getAllObjectives.fulfilled]: (state, action) => {
            if (
                action.payload.nationalObjectives.statusCode === 200 &&
                action.payload.mcOpCoObjectives.statusCode === 200 &&
                action.payload.storesFilter.statusCode === 200
            ) {
                state.national.globalObjective.value = action.payload.nationalObjectives.data.mainObjective !== null ?
                    action.payload.nationalObjectives.data.mainObjective.objectiveValue :
                    null;
                state.national.table.data = action.payload.nationalObjectives.data.storeObjectives;
                const editNationalStateArray: AdministrationRowEdit[] = new Array(state.national.table.data.length).fill({
                    isEditing: false,
                    editValue: '',
                    hasError: false
                });
                state.national.table.edit = editNationalStateArray;
                state.mcOpCo.globalObjective.value = action.payload.mcOpCoObjectives.data.mainObjective !== null ?
                    action.payload.mcOpCoObjectives.data.mainObjective.objectiveValue :
                    null;
                state.mcOpCo.table.data = action.payload.mcOpCoObjectives.data.storeObjectives;
                const editMcOpCoStateArray: AdministrationRowEdit[] = new Array(state.mcOpCo.table.data.length).fill({
                    isEditing: false,
                    editValue: '',
                    hasError: false
                });
                state.mcOpCo.table.edit = editMcOpCoStateArray;
                state.isInitialLoading = false;
            }
        },
        [getAllObjectives.rejected]: (state, action) => {
        },
        // getStoresFilter
        [getStoresFilter.pending]: (state) => {
        },
        [getStoresFilter.fulfilled]: (state, action) => {
        },
        [getStoresFilter.rejected]: (state, action) => {
        },
        // setMainObjective
        [setMainObjective.pending]: (state) => {
        },
        [setMainObjective.fulfilled]: (state, action) => {
        },
        [setMainObjective.rejected]: (state, action) => {
        },
        // setMainObjective
        [setStoreObjective.pending]: (state) => {
        },
        [setStoreObjective.fulfilled]: (state, action) => {
        },
        [setStoreObjective.rejected]: (state, action) => {
        }
    }
});

export const {
    closeToast,
    clearToasts,
    addToast,
    openEdit,
    cancelEdit,
    changeInputValue,
    changeValue,
    toggleMainInputError,
    openEditTable,
    cancelEditTable,
    changeValueTable,
    toggleTableInputError,
    changeInputValueTable,
    toggleMissingObjective,
    clearMissingObjective,
    cancelOnChange,
    setYearsFilter,
    toggleIsFiltersOpensAdministration,
    setAppliedFilters
} = administrationSlice.actions;

export default administrationSlice.reducer;