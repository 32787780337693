import { RootState } from "../app/store";
import { arrayValues } from "../features/dropdownWithCheckbox/DropdownCheckboxSlice";
import { TurnoverRequest } from "../models/DashboardModels";
import { GetDetailMotivesDataResponse, MotivesData, Step2Data } from "../models/SubmissionsModels";
import { apiInfo, dashboardFiltersStoreIds } from "./Variables";

export const getParameter = (parameter: string) => {
    const urlParams = new URLSearchParams(document.location.search)
    return urlParams.get(parameter)
};

export const isLocalhost = window.location.host.includes('localhost');

export const isAuthPath = document.location.pathname.toLowerCase().startsWith("/auth");

export const formatCompare = (value: number, hasValues: boolean | null) => {
    let finalStr = value + '';
    if (hasValues) {
        if (finalStr === '0') {
            return ' (=)';
        } else if (finalStr[0] === '-') {
            return ` (${finalStr})`;
        } else {
            return ` (+${finalStr})`;
        }
    } else {
        return '';
    }
};

export const monthsTranslationAlias = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec"
];

export const isFormatPercentage = (value: string) => {
    return value === '' || (value.match('^[0-9]+$') && value.length <= 3);
};

export const getDashboardRequestBody = (allStates: RootState): TurnoverRequest => {
    return {
        dashboardViewType: allStates.dashboard.dropdownHeader.currentOption ? parseInt(allStates.dashboard.dropdownHeader.currentOption.id) : null,
        month: allStates.dashboard.filters.appliedFilters.month?.id || null,
        year: allStates.dashboard.filters.appliedFilters.year || null,
        timeFilterType: parseInt(allStates.dashboard.filters.appliedFilters.timeFilterType),
        franchiseeIds: allStates.dashboard.filters.appliedFilters.franchiseeIds,
        storeIds: allStates.dashboard.filters.appliedFilters.storeIds,
        consultantsIds: allStates.dashboard.filters.appliedFilters.consultantsIds,
        regionIds: allStates.dashboard.filters.appliedFilters.regionIds,
        tipologyIds: allStates.dashboard.filters.appliedFilters.tipologyIds,
        isManagement: false,
        isLastYear: false,
        isLastMonth: false,
        is90Days: true,
        isLessThan90Days: false
    };
};

export const getDashboardSelectedFiltersRequestBody = (allStates: RootState): TurnoverRequest => {
    return {
        dashboardViewType: allStates.dashboard.dropdownHeader.currentOption ? parseInt(allStates.dashboard.dropdownHeader.currentOption.id) : null,
        month: allStates.dropdown.entities[dashboardFiltersStoreIds.month]?.value?.id || null,
        year: allStates.dropdown.entities[dashboardFiltersStoreIds.year]?.value?.id || null,
        timeFilterType: parseInt(allStates.dashboard.filters.ttmMonthlyYtdValue),
        franchiseeIds: getSelectedCheckbox(allStates.dropdowncheckbox.entities[dashboardFiltersStoreIds.franchisees]?.value),
        storeIds: getSelectedCheckbox(allStates.dropdowncheckbox.entities[dashboardFiltersStoreIds.restaurants]?.value),
        consultantsIds: getSelectedCheckbox(allStates.dropdowncheckbox.entities[dashboardFiltersStoreIds.consultantsOps]?.value),
        regionIds: getSelectedCheckbox(allStates.dropdowncheckbox.entities[dashboardFiltersStoreIds.region]?.value),
        tipologyIds: getSelectedCheckbox(allStates.dropdowncheckbox.entities[dashboardFiltersStoreIds.typology]?.value),
        isManagement: allStates.dashboard.details.region.isManagementStaff,
        isLastYear: allStates.dashboard.details.region.isLastYear,
        isLastMonth: allStates.dashboard.details.region.isLastMonth,
        is90Days: true,
        isLessThan90Days: false
    };
}

export const getRequestConfig = (allStates: RootState, isAuth = false) => {
    return {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Accepted-Language': allStates.dropdownLanguages.currentLanguage.lang,
            'X-Api-Key': apiInfo.apiKey,
            'authorization': isAuth ? "" : ("Bearer " + allStates.authentication.accessToken)
        }
    };
};

export const getSelectedCheckbox = (selectedRestaurants: arrayValues[] | undefined): string[] => {
    if (selectedRestaurants) {
        let storesArray: string[] = [];
        selectedRestaurants.forEach(restaurant => {
            if (restaurant.id !== null) {
                storesArray.push(restaurant.id);
            }
        })
        return storesArray;
    } else {
        return [];
    }
}

export const getSelectedStores = (selectedRestaurants: arrayValues[] | undefined): number[] => {
    if (selectedRestaurants) {
        let storesArray: number[] = [];
        selectedRestaurants.forEach(restaurant => {
            if (restaurant.id !== null) {
                storesArray.push(parseInt(restaurant.id));
            }
        })
        return storesArray;
    } else {
        return [];
    }
}

export const getCurrentPath = (path: string): string => {
    let currentPath = [];
    if (path.charAt(path.length - 1) === '/') {
        currentPath = path.substring(0, path.length - 1).split('/');
    } else {
        currentPath = path.split('/');
    }
    return currentPath[currentPath.length - 1];
}

export const isFormatStep1 = (value: string) => {
    return value === '' || (value.match('^[0-9]+$') && value.length <= 6);
};

export const isFormatStep2 = (value: string) => {
    return value === '' || (value.match('^[0-9]+$') && value.length <= 6 && parseInt(value) > 0);
};

export const getMotives = (motives: Step2Data): MotivesData[] => {
    let motivesArray: MotivesData[] = [];
    for (let i = 0; i < motives.less90Days.length; i++) {
        if (motives.less90Days[i] !== '' || motives.more90Days[i] !== '') {
            motivesArray.push({
                motiveId: i + 1,
                lessThan90Days: parseInt(motives.less90Days[i]),
                moreThan90Days: parseInt(motives.more90Days[i])
            });
        }
    }
    return motivesArray;
};

export const setMotives = (motives: GetDetailMotivesDataResponse[], isMore90Days: boolean): string[] => {
    let motivesArray: string[] = ['', '', '', '', '', '', '', '', '', ''];
    for (let i = 0; i < motives.length; i++) {
        if (isMore90Days) {
            motivesArray[motives[i].motiveId - 1] = motives[i].greaterThan90DaysValue === null ? '' : motives[i].greaterThan90DaysValue + '';
        } else {
            motivesArray[motives[i].motiveId - 1] = motives[i].lessThan90DaysValue === null ? '' : motives[i].lessThan90DaysValue + '';
        }
    }
    
    return motivesArray;
};

export const getFormatedPercentageVariance = (variance: number): string => {
    return variance.toFixed(1);
};