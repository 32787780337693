import Fuse from 'fuse.js';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setLoading } from '../../features/main/MainSlice';
import { changeSearchValue, getFaqs, newSearch, setShowingSearch } from '../../features/support/SupportSlice';
import { NoResultsIcon } from '../../images/icons';
import { ErrorInfo } from '../../models/GlobalModels';
import { GetFaqsResponse } from '../../models/SupportModels';
import Accordion from '../../private/accordion-custom/Accordion';
import Buttons from '../../private/buttons/Buttons';
import Header from '../../private/header/Header';
import SearchBar from '../../private/search-bar/SearchBar';
import './Support.scss';
import videoUrl1 from '../../videos/Como_preencher_o_TN.mp4';
import videoUrl2 from '../../videos/Como_analisar_a_dashboard_de_TN.mp4';
import videoUrl3 from '../../videos/Como_preencher_Saidas_90_Dias_e_Contratacoes_12_meses.mp4';

const Support = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
    const { isInitialLoading } = useAppSelector((store) => store.support);
    const { searchValue } = useAppSelector((store) => store.support);
    const { accordionData } = useAppSelector((store) => store.support);
    const { accordionSearch } = useAppSelector((store) => store.support);
    const { isShowingSearch } = useAppSelector((store) => store.support);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(getFaqs()).unwrap()
            .then((resp: GetFaqsResponse) => {
                if (resp.statusCode === 200) {
                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    }, []);

    const handleValue = (value: string) => {
        dispatch(changeSearchValue(value));
        if (value.length > 1) {
            const fuse = new Fuse(accordionData, {
                keys: ['label', 'description'],
                threshold: 0.15,
                ignoreLocation: true
            });
            dispatch(newSearch(fuse.search(value)));
        } else {
            dispatch(setShowingSearch());
        }
    };

    const handleClear = () => {
        handleValue('');
    };

    if (isInitialLoading) {
      return <div className="support-container"></div>
    } else {
      return (
        <div className="support-container">
            <Header title={t("support.header.title")} />
            <div className="support-body-wrapper">
                <div className="questions-container">
                    <SearchBar
                        placeholder={t("support.search.placeholder")}
                        value={searchValue}
                        handleValue={handleValue}
                        handleClear={handleClear} />
                    {
                        isShowingSearch &&
                        <div className="results-text-wrapper">
                            {t("support.search.results1")}
                            <span className="results-bold">{' ' + accordionSearch.length + ' '}</span>
                            {t("support.search.results2") + ' "'}
                            <span className="results-bold">{searchValue}</span>
                            {'"'}
                        </div>
                    }
                    {
                        (!isShowingSearch && accordionData.length > 0) || (isShowingSearch && accordionSearch.length > 0) ?
                        <Accordion
                            accordionData={isShowingSearch ? accordionSearch : accordionData}
                            uniqueId="-support-accordion"/> :
                        <div className="no-results-container">
                            <NoResultsIcon />
                            <div className="no-results-text-wrapper">
                                <div className="no-results-text">
                                    <span className="no-results-title">{t("support.search.noResultsTitle")}</span>
                                    <span>{t("support.search.noResults")}</span>
                                </div>
                                <Buttons label={t("support.search.clearSearch")} handleOnClick={handleClear}/>
                            </div>
                        </div>
                    }
                </div>
                <div className="videos-container">
                    <span className="videos-title">{t("support.videos.title")}</span>
                    <div className="videos-section">
                        <div className="video-wrapper">
                            <span className="video-sub-title">{t("support.videos.subTitle1")}</span>
                            <video controls width="358px" height="200px" controlsList="nodownload">
                                <source src={videoUrl1} type='video/mp4' />
                            </video>
                        </div>
                        <div className="video-wrapper">
                            <span className="video-sub-title">{t("support.videos.subTitle2")}</span>
                            <video controls width="358px" height="200px" controlsList="nodownload">
                                <source src={videoUrl2} type='video/mp4' />
                            </video>
                        </div>
                        <div className="video-wrapper">
                            <span className="video-sub-title">{t("support.videos.subTitle3")}</span>
                            <video controls width="358px" height="200px" controlsList="nodownload">
                                <source src={videoUrl3} type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
}

export default Support;