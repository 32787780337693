import { FormGroup, Input, Label } from "reactstrap";
import { useAppDispatch } from "../../../app/hooks";
import '../../checkbox/Checkbox.scss';
interface CheckboxData {
    label?: string;
    name?: string;
    isSmall?: boolean;
    isDisabled?: boolean;
    onChangeAction?: any;
    onChangeHandle?: any;
    checkboxID?: string | null;
    dropdownStoreID?: string;
    checked?: any;
}


const Checkbox = ({ label, name, isSmall = false, isDisabled = false, onChangeAction, checkboxID, dropdownStoreID, checked, onChangeHandle = null }: CheckboxData) => {

    const dispatch = useAppDispatch();
    let checkedValue = checked?.id === checkboxID && checked?.value === true ? true : false;

    return (
        <div className={"checkbox-container check-box-yellow" + (isSmall ? ' checkbox-small' : ' checkbox-normal')}>
            <FormGroup check>
                <Input type="checkbox" name={name} disabled={isDisabled} checked={checkedValue} onChange={(event) => {
                    onChangeAction && dispatch(
                        onChangeAction(
                            dropdownStoreID,
                            checkboxID,
                            label,
                            event.target.checked
                        ))
                    if (onChangeHandle) {
                        onChangeHandle();
                    }
                }} />
                {' '}
                <Label for={name} check>
                    {label}
                </Label>
            </FormGroup>
        </div>
    );
}

export default Checkbox;