import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../app/hooks';
import { FilterIcon } from '../../images/icons';
import { langPt, langEn } from '../../global/Variables';
import Buttons from '../buttons/Buttons';
import './Header.scss';

interface HeaderData {
    title: string,
    subtitle?: string,
    hasButton?: boolean,
    isDropdown?: boolean,
    handleToggleFilters?: any
    handleSelect?: any
}

const Header = ({ title, subtitle, hasButton = false, isDropdown = false, handleToggleFilters, handleSelect }: HeaderData) => {
    const { t } = useTranslation();
    const { currentOption } = useAppSelector((store) => store.dashboard.dropdownHeader);
    const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
    const cardsData = useAppSelector((store) => store.dashboard.cardsInfo);

    return (
        <header className='header-container'>
            {!isDropdown &&
                <div className='titles-container'>
                    <h4>{title} <span>{subtitle}</span></h4>
                </div>
            }

            {isDropdown &&
                <div className='titles-container'>
                    <div className="dropdown">
                        {currentOption !== null &&
                            <>
                                <button className="btn btn-warning dropdown-toggle button-dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {currentLanguage.lang === langPt &&
                                        <span className='button-dropdown'>{title + ' '} <span>{currentOption.description}</span></span>
                                    }

                                    {currentLanguage.lang === langEn &&
                                        <span className='button-dropdown'><span>{currentOption.description}</span> {title + ' '}</span>
                                    }
                                </button>

                                <ul className="dropdown-menu menu-dropdown">
                                    {cardsData?.dashboardViews?.map((view: any, index) => {
                                        return (
                                            <li key={"view" + index}
                                                className={((currentOption.id === view.id) ? ' selected-option' : '')}>
                                                <button
                                                    onClick={() => handleSelect(view)}
                                                    className="dropdown-item">
                                                    {view.description}
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </>}
                    </div>
                </div>
            }

            {
                hasButton &&
                <div className='buttons-container'>
                    <Buttons icon={<FilterIcon />} label={t("common.header.filters")} handleOnClick={handleToggleFilters} />
                </div>
            }
        </header >
    );
}

export default Header;

