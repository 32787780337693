export const LogoIcon = () => {
  return (
    <svg
      width="272"
      height="272"
      viewBox="0 0 272 272"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 22.433C0 10.0436 10.0436 0 22.433 0H249.567C261.956 0 272 10.0436 272 22.433V249.567C272 261.956 261.956 272 249.567 272H22.433C10.0436 272 0 261.956 0 249.567V22.433Z"
        fill="#264F36"
      />
      <path
        d="M175.506 67.2267C191.043 67.2267 203.647 132.779 203.647 213.737H226.321C226.321 126.578 203.447 56.0234 175.439 56.0234C159.434 56.0234 145.297 77.163 135.961 110.373C126.624 77.163 112.487 56.0234 96.6156 56.0234C68.6074 56.0234 45.8007 126.511 45.8007 213.67H68.474C68.474 132.713 80.9443 67.2267 96.4823 67.2267C112.02 67.2267 124.624 127.778 124.624 202.467H147.164C147.164 127.778 159.834 67.2267 175.372 67.2267"
        fill="#FFBC0D"
      />
    </svg>
  );
};

export const DashboardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M6 1.5H1V15.5H6V1.5ZM15 1.5H10V6.5H15V1.5ZM15 10.5H10V15.5H15V10.5ZM0 1.5C0 1.23478 0.105357 0.98043 0.292893 0.792893C0.48043 0.605357 0.734784 0.5 1 0.5H6C6.26522 0.5 6.51957 0.605357 6.70711 0.792893C6.89464 0.98043 7 1.23478 7 1.5V15.5C7 15.7652 6.89464 16.0196 6.70711 16.2071C6.51957 16.3946 6.26522 16.5 6 16.5H1C0.734784 16.5 0.48043 16.3946 0.292893 16.2071C0.105357 16.0196 0 15.7652 0 15.5V1.5ZM9 1.5C9 1.23478 9.10536 0.98043 9.29289 0.792893C9.48043 0.605357 9.73478 0.5 10 0.5H15C15.2652 0.5 15.5196 0.605357 15.7071 0.792893C15.8946 0.98043 16 1.23478 16 1.5V6.5C16 6.76522 15.8946 7.01957 15.7071 7.20711C15.5196 7.39464 15.2652 7.5 15 7.5H10C9.73478 7.5 9.48043 7.39464 9.29289 7.20711C9.10536 7.01957 9 6.76522 9 6.5V1.5ZM10 9.5C9.73478 9.5 9.48043 9.60536 9.29289 9.79289C9.10536 9.98043 9 10.2348 9 10.5V15.5C9 15.7652 9.10536 16.0196 9.29289 16.2071C9.48043 16.3946 9.73478 16.5 10 16.5H15C15.2652 16.5 15.5196 16.3946 15.7071 16.2071C15.8946 16.0196 16 15.7652 16 15.5V10.5C16 10.2348 15.8946 9.98043 15.7071 9.79289C15.5196 9.60536 15.2652 9.5 15 9.5H10Z" fill="white" />
    </svg>
  );
}

export const ButtonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.94818 0.935C7.52818 0.355 8.46818 0.355 9.04818 0.935L15.5632 7.451C16.1432 8.031 16.1432 8.97 15.5632 9.549L9.04818 16.065C8.46818 16.645 7.52918 16.645 6.95018 16.065L0.433183 9.55C0.295247 9.41234 0.185813 9.24884 0.111147 9.06883C0.0364801 8.88883 -0.00195313 8.69587 -0.00195312 8.501C-0.00195313 8.30613 0.0364801 8.11317 0.111147 7.93317C0.185813 7.75316 0.295247 7.58966 0.433183 7.452L6.94818 0.935ZM8.34818 1.635C8.25535 1.54218 8.12946 1.49004 7.99818 1.49004C7.86691 1.49004 7.74101 1.54218 7.64818 1.635L1.13218 8.15C1.03936 8.24283 0.987219 8.36873 0.987219 8.5C0.987219 8.63127 1.03936 8.75717 1.13218 8.85L7.64818 15.366C7.74101 15.4588 7.86691 15.511 7.99818 15.511C8.12946 15.511 8.25535 15.4588 8.34818 15.366L14.8642 8.85C14.957 8.75717 15.0091 8.63127 15.0091 8.5C15.0091 8.36873 14.957 8.24283 14.8642 8.15L8.34818 1.634V1.635Z"
        fill="white"
      />
    </svg>
  );
};

export const SymbolIcon = () => {
  return (
    <svg
      width="181"
      height="158"
      viewBox="0 0 181 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M129.705 11.2033C145.243 11.2033 157.846 76.7559 157.846 157.713H180.52C180.52 70.5541 157.646 0 129.638 0C113.633 0 99.496 21.1396 90.1599 54.3493C80.8238 21.1396 66.6863 0 50.815 0C22.8067 0 0 70.4874 0 157.646H22.6733C22.6733 76.6893 35.1437 11.2033 50.6816 11.2033C66.2195 11.2033 78.8232 71.7545 78.8232 146.443H101.363C101.363 71.7545 114.034 11.2033 129.572 11.2033"
        fill="#FFBC0D"
      />
    </svg>
  );
};

export const MenuIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 27C3 26.1716 3.67157 25.5 4.5 25.5H31.5C32.3284 25.5 33 26.1716 33 27C33 27.8284 32.3284 28.5 31.5 28.5H4.5C3.67157 28.5 3 27.8284 3 27Z"
        fill="#FFBC0D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 19.5C3 18.6716 3.67157 18 4.5 18H31.5C32.3284 18 33 18.6716 33 19.5C33 20.3284 32.3284 21 31.5 21H4.5C3.67157 21 3 20.3284 3 19.5Z"
        fill="#1B440A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.205 9.42369C14.3835 9.47167 10.4207 10.7782 5.08694 13.3633C4.32447 13.7329 3.44377 13.3273 3.11982 12.4575C2.79588 11.5877 3.15137 10.583 3.91384 10.2135C9.36596 7.571 13.761 6.05696 18.172 6.00158C22.6051 5.94591 26.8955 7.36385 32.1504 10.2462C32.897 10.6558 33.2112 11.6782 32.8522 12.5299C32.4932 13.3816 31.597 13.7401 30.8504 13.3306C25.821 10.5718 22.0043 9.37598 18.205 9.42369Z"
        fill="#FFBC0D"
      />
    </svg>
  );
};

export const RestaurantIcon = () => {
  return (
    <svg
      width="141"
      height="150"
      viewBox="0 0 141 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.81982 125.1C2.81982 123.542 4.08238 122.28 5.63982 122.28H136.77C138.327 122.28 139.59 123.542 139.59 125.1C139.59 126.657 138.327 127.92 136.77 127.92H5.63982C4.08238 127.92 2.81982 126.657 2.81982 125.1Z"
        fill="#FFBC0D"
      />
      <path d="M56.3999 124.832V65.6124H68.8023V124.832" fill="#FFBC0D" />
      <path d="M71.0576 124.832V65.6124H83.46V124.832" fill="#FFBC0D" />
      <path
        d="M115.62 65.6124H93.0602C91.5028 65.6124 90.2402 66.875 90.2402 68.4324V122.012C90.2402 123.57 91.5028 124.832 93.0602 124.832H115.62C117.178 124.832 118.44 123.57 118.44 122.012V68.4324C118.44 66.875 117.178 65.6124 115.62 65.6124Z"
        fill="#FFBC0D"
      />
      <path
        d="M47.9401 65.6124H25.3801C23.8226 65.6124 22.5601 66.875 22.5601 68.4324V122.012C22.5601 123.57 23.8226 124.832 25.3801 124.832H47.9401C49.4975 124.832 50.7601 123.57 50.7601 122.012V68.4324C50.7601 66.875 49.4975 65.6124 47.9401 65.6124Z"
        fill="#FFBC0D"
      />
      <path
        d="M21.5876 23.4432L16.2146 39.4147C16.1656 39.5497 16.1042 39.6723 16.0184 39.7827L3.08898 56.6498C2.43883 57.4962 3.03991 58.6984 4.10714 58.6984H136.897C137.964 58.6984 138.565 57.4717 137.903 56.6253L125.464 40.8009C125.378 40.6782 125.305 40.5556 125.256 40.4084L119.674 23.4432C119.502 22.9157 119.012 22.5599 118.46 22.5599H22.7897C22.25 22.5845 21.7593 22.9279 21.5876 23.4432Z"
        fill="#FFBC0D"
      />
      <path
        d="M79.7729 28.0733C79.7729 28.0733 78.2849 26.1324 76.688 26.1324C76.688 26.1324 74.837 26.1324 73.2038 28.4761C73.2038 28.4761 71.6069 30.7098 70.5181 34.7013C70.5181 34.7013 69.393 30.7098 67.7961 28.4761C67.7961 28.4761 66.1628 26.1324 64.3482 26.1324C64.3482 26.1324 62.715 26.1324 61.2269 28.0733C61.2269 28.0733 59.8115 29.9775 58.7227 33.4197C58.7227 33.4197 56.3999 40.7069 56.3999 50.997H59.9204C59.9204 50.997 59.9204 41.4393 61.2269 34.6647C61.2269 34.6647 61.8077 31.4789 62.6061 29.7212C62.6061 29.7212 63.4408 27.9268 64.3482 27.9268C64.3482 27.9268 65.2192 27.9268 66.054 29.5747C66.054 29.5747 66.8524 31.2225 67.4331 34.1521C67.4331 34.1521 68.7397 40.4139 68.7397 49.2392H72.2601C72.2601 49.2392 72.2601 40.4139 73.5667 34.1521C73.5667 34.1521 74.1837 31.2225 74.9458 29.5747C74.9458 29.5747 75.7806 27.9268 76.6879 27.9268C76.6879 27.9268 77.559 27.9268 78.3937 29.7212C78.3937 29.7212 79.1922 31.4789 79.8091 34.6647C79.8091 34.6647 81.0794 41.4393 81.0794 50.997H84.5999C84.5999 50.997 84.5999 40.7069 82.2771 33.4197C82.2771 33.4197 81.1883 29.9775 79.7729 28.0733Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};

export const ErrorLogoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="92" height="80" viewBox="0 0 92 80" fill="none">
      <path d="M65.758 6.11263C73.5409 6.11263 79.8541 38.9478 79.8541 79.4991H91.2111C91.2111 35.8413 79.7539 0.500916 65.7246 0.500916C57.7078 0.500916 50.6264 11.0897 45.95 27.7244C41.2736 11.0897 34.1921 0.500916 26.2422 0.500916C12.2129 0.500916 0.789062 35.8079 0.789062 79.4657H12.1461C12.1461 38.9144 18.3925 6.11263 26.1754 6.11263C33.9583 6.11263 40.2715 36.4426 40.2715 73.854H51.5617C51.5617 36.4426 57.9083 6.11263 65.6912 6.11263" fill="#FFBC0D" />
    </svg>
  );
}

export const Error404Icon = () => {
  return (
    <svg
      width="167"
      height="151"
      viewBox="0 0 167 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.94401 37.9004C2.28024 41.9194 -0.477001 55.3785 4.94401 57.201C24.4783 63.7436 110.747 70.146 160.377 57.201C164.49 56.1262 166.593 51.2192 161.125 40.2838C161.125 40.2838 146.03 0.0467829 79.81 5.00214e-05C29.1984 -0.0466828 8.40224 32.6663 4.94401 37.9004Z"
        fill="#E6A65D"
      />
      <path
        d="M28.9655 40.0033C28.3113 41.2184 25.9279 43.9756 24.8998 43.555C24.1053 43.2279 24.5726 39.6762 25.1334 38.2275C25.554 37.1526 26.8625 36.5451 27.8439 36.8722C29.1992 37.3863 29.4796 38.9752 28.9655 40.0033Z"
        fill="white"
      />
      <path
        d="M37.1425 47.06C38.3576 47.7143 41.1148 50.0977 40.6942 51.1258C40.3671 51.9203 36.8154 51.4529 35.3667 50.8921C34.2918 50.4715 33.6843 49.163 34.0114 48.1816C34.5255 46.8264 36.1144 46.546 37.1425 47.06Z"
        fill="white"
      />
      <path
        d="M61.2104 51.78C62.4254 52.4343 65.1827 54.8176 64.7621 55.8458C64.4349 56.6402 60.8832 56.1729 59.4345 55.6121C58.3597 55.1915 57.7521 53.883 58.0793 52.9016C58.5466 51.5463 60.1823 51.266 61.2104 51.78Z"
        fill="white"
      />
      <path
        d="M46.536 35.4236C46.6762 34.0683 47.938 30.6101 49.0596 30.6568C49.9007 30.7036 50.7887 34.1618 50.7887 35.6573C50.7887 36.8256 49.7605 37.8537 48.7324 37.9004C47.2837 37.9939 46.3958 36.5919 46.536 35.4236Z"
        fill="white"
      />
      <path
        d="M33.1703 21.0766C34.2451 21.9178 36.535 24.7685 35.9275 25.7031C35.4602 26.4041 32.0487 25.3293 30.7402 24.5816C29.7588 23.974 29.3849 22.572 29.899 21.6374C30.6467 20.3756 32.2824 20.3756 33.1703 21.0766Z"
        fill="white"
      />
      <path
        d="M133.458 48.1816C134.533 49.0228 136.823 51.8735 136.216 52.8082C135.748 53.5092 132.337 52.4343 131.028 51.6866C130.047 51.079 129.673 49.6771 130.187 48.7424C130.935 47.5274 132.57 47.5273 133.458 48.1816Z"
        fill="white"
      />
      <path
        d="M49.7606 16.3566C49.9008 15.0013 51.0691 11.5431 52.1907 11.5431C53.0319 11.5431 53.9666 15.0481 54.0133 16.5435C54.06 17.7118 53.0319 18.7867 52.0038 18.8334C50.5551 18.8801 49.6672 17.5249 49.7606 16.3566Z"
        fill="white"
      />
      <path
        d="M62.1901 23.8806C61.8162 25.1891 59.9469 28.3669 58.8721 28.1333C58.0309 27.9463 57.7972 24.3946 58.0309 22.8992C58.2178 21.7776 59.4328 20.8897 60.461 21.0766C61.9097 21.2168 62.5172 22.759 62.1901 23.8806Z"
        fill="white"
      />
      <path
        d="M155.422 47.0133C155.048 48.3218 153.179 51.4996 152.104 51.266C151.263 51.079 151.029 47.5273 151.263 46.0319C151.45 44.9103 152.665 44.0224 153.693 44.2093C155.142 44.3962 155.749 45.9384 155.422 47.0133Z"
        fill="white"
      />
      <path
        d="M70.2292 33.7879C71.1639 34.7693 72.9397 37.9939 72.192 38.7884C71.6312 39.3959 68.4066 37.807 67.2383 36.8256C66.3504 36.0779 66.2102 34.6291 66.8645 33.7879C67.8458 32.7131 69.4348 32.9468 70.2292 33.7879Z"
        fill="white"
      />
      <path
        d="M84.4371 25.3293C83.6893 26.4976 81.0256 29.0212 80.0442 28.5071C79.2965 28.1333 80.0909 24.5816 80.7452 23.2263C81.2592 22.1982 82.6145 21.6841 83.5959 22.1515C84.9044 22.759 85.0446 24.3947 84.4371 25.3293Z"
        fill="white"
      />
      <path
        d="M85.2779 51.5931C84.5302 52.7614 81.8664 55.285 80.885 54.7709C80.1373 54.3971 80.9317 50.8454 81.586 49.4901C82.1001 48.462 83.4553 47.9479 84.4367 48.4153C85.7452 49.0228 85.9321 50.6585 85.2779 51.5931Z"
        fill="white"
      />
      <path
        d="M72.8942 13.0853C71.5857 13.4591 67.8938 13.5526 67.52 12.5245C67.2396 11.73 70.137 9.62703 71.539 9.06624C72.6138 8.64564 73.9691 9.15971 74.3897 10.1411C74.9505 11.4963 74.0158 12.8049 72.8942 13.0853Z"
        fill="white"
      />
      <path
        d="M113.972 54.584C112.663 54.9579 108.971 55.0514 108.598 54.0232C108.317 53.2288 111.215 51.1258 112.617 50.565C113.691 50.1444 115.047 50.6585 115.467 51.6399C116.075 52.9484 115.093 54.2569 113.972 54.584Z"
        fill="white"
      />
      <path
        d="M96.073 11.2159C95.6992 12.5244 93.8298 15.7023 92.755 15.4686C91.9138 15.2817 91.6801 11.6832 91.9605 10.2345C92.1475 9.11292 93.3625 8.225 94.3906 8.41193C95.8394 8.55213 96.4002 10.0943 96.073 11.2159Z"
        fill="white"
      />
      <path
        d="M16.721 44.9104C16.3471 46.2189 14.4778 49.3967 13.4029 49.163C12.5618 48.9761 12.3281 45.3777 12.6085 43.929C12.7954 42.8074 14.0105 41.9195 15.0386 42.1064C16.4406 42.2933 17.0481 43.8355 16.721 44.9104Z"
        fill="white"
      />
      <path
        d="M92.8468 34.2085C93.9684 33.4141 97.3799 32.1056 98.0809 32.9467C98.595 33.601 96.5855 36.5452 95.4639 37.5733C94.6227 38.321 93.1272 38.2743 92.4263 37.5266C91.4449 36.4517 91.9122 34.9095 92.8468 34.2085Z"
        fill="white"
      />
      <path
        d="M110.699 24.7217C110.419 26.0769 108.783 29.3482 107.709 29.208C106.867 29.1146 106.4 25.5161 106.54 24.0207C106.634 22.8991 107.802 21.9177 108.83 22.0112C110.232 22.1046 110.933 23.6001 110.699 24.7217Z"
        fill="white"
      />
      <path
        d="M113.318 17.151C112.196 16.3565 109.86 13.5526 110.421 12.6179C110.841 11.9169 114.346 12.8516 115.655 13.5993C116.636 14.1601 117.057 15.5621 116.589 16.4967C115.888 17.7585 114.253 17.8052 113.318 17.151Z"
        fill="white"
      />
      <path
        d="M119.066 37.1994C118.458 35.9844 117.664 32.3859 118.598 31.8252C119.299 31.4046 121.963 33.8347 122.757 35.0964C123.365 36.0778 123.085 37.4798 122.243 38.0874C121.028 38.8818 119.58 38.1808 119.066 37.1994Z"
        fill="white"
      />
      <path
        d="M130.281 19.9082C130.935 21.1233 131.777 24.675 130.842 25.2825C130.141 25.7498 127.477 23.3197 126.636 22.0579C125.982 21.1233 126.262 19.6745 127.103 19.067C128.225 18.2258 129.72 18.8801 130.281 19.9082Z"
        fill="white"
      />
      <path
        d="M138.741 37.4331C139.722 36.4517 142.853 34.5357 143.694 35.2834C144.349 35.8442 142.853 39.1155 141.919 40.2838C141.218 41.1717 139.769 41.3587 138.928 40.7511C137.759 39.8632 137.946 38.2275 138.741 37.4331Z"
        fill="white"
      />
      <path
        d="M5.42799 114.9C3.97927 121.817 4.3064 135.182 7.95156 139.388C9.35355 141.024 35.8511 149.763 85.2477 150.931C121.092 151.772 148.571 143.314 154.646 139.482C158.852 136.818 161.469 136.678 161.609 118.499C161.609 115.928 162.076 110.741 157.871 111.863C157.403 112.003 156.375 112.377 155.721 112.61C112.633 127.611 47.2071 124.621 11.7369 110.367C10.4751 109.853 6.50284 109.853 5.42799 114.9Z"
        fill="#E6A65D"
      />
      <path
        d="M6.31695 112.564C7.06468 109.105 36.2727 95.9734 81.1362 95.7865C111.746 95.6463 134.318 101.581 148.665 105.741C151.329 106.535 159.18 109.9 160.395 112.143C161.423 114.106 153.152 117.844 147.403 119.574C135.346 123.172 112.26 125.462 83.6598 124.901C72.1168 124.667 51.087 123.733 34.4034 120.929C20.477 118.639 5.5225 116.115 6.31695 112.564Z"
        fill="#D59C61"
      />
      <path
        d="M57.25 67.3054V95.5554H62.625V102.93H57.25V112.118H48.25V102.93H28.7188V95.2117C30.4479 93.2742 32.2396 91.1596 34.0938 88.8679C35.9479 86.5554 37.7396 84.1804 39.4688 81.7429C41.1979 79.3054 42.8021 76.8575 44.2812 74.3992C45.7812 71.92 47.0521 69.5554 48.0938 67.3054H57.25ZM37.5625 95.5554H48.25V79.8992C47.5208 81.2533 46.7292 82.6283 45.875 84.0242C45.0208 85.3992 44.125 86.7638 43.1875 88.1179C42.25 89.4513 41.3021 90.7533 40.3438 92.0242C39.3854 93.2742 38.4583 94.4513 37.5625 95.5554Z"
        fill="#FFBC0D"
      />
      <path
        d="M82.7248 112.899C72.0581 112.899 66.7248 105.41 66.7248 90.4304C66.7248 82.6596 68.1623 76.7429 71.0373 72.6804C73.9331 68.5971 78.1206 66.5554 83.5998 66.5554C94.0164 66.5554 99.2247 74.17 99.2247 89.3992C99.2247 96.9825 97.7977 102.795 94.9435 106.837C92.1102 110.878 88.0373 112.899 82.7248 112.899ZM83.1623 74.0867C78.8914 74.0867 76.756 79.4513 76.756 90.1804C76.756 100.285 78.8498 105.337 83.0373 105.337C87.1206 105.337 89.1623 100.128 89.1623 89.7117C89.1623 79.295 87.1623 74.0867 83.1623 74.0867Z"
        fill="#FFBC0D"
      />
      <path
        d="M131.387 67.3054V95.5554H136.762V102.93H131.387V112.118H122.387V102.93H102.856V95.2117C104.585 93.2742 106.377 91.1596 108.231 88.8679C110.085 86.5554 111.877 84.1804 113.606 81.7429C115.335 79.3054 116.939 76.8575 118.418 74.3992C119.918 71.92 121.189 69.5554 122.231 67.3054H131.387ZM111.7 95.5554H122.387V79.8992C121.658 81.2533 120.866 82.6283 120.012 84.0242C119.158 85.3992 118.262 86.7638 117.325 88.1179C116.387 89.4513 115.439 90.7533 114.481 92.0242C113.522 93.2742 112.595 94.4513 111.7 95.5554Z"
        fill="#FFBC0D"
      />
    </svg>
  );
};

export const NoResultsIcon = () => {
  return (
    <svg width="177" height="189" viewBox="0 0 177 189" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.9833 25.9898L0.300049 30.4912L32.9833 77.3061L74.6209 89.46L128.347 84.0583L176.7 30.4912L144.465 25.9898C144.465 25.9898 126.556 0 88.9478 0C51.3396 0 32.9833 25.9898 32.9833 25.9898Z" fill="#E6E6E6" />
      <path d="M0.300049 30.423L19.9619 27.72C29.0159 55.0262 57.0795 79.3885 84.4718 79.698C118.923 80.0847 145.805 59.5901 156.701 27.72L176.7 30.423C176.7 30.423 168.644 104.219 147.159 178.712L136.11 189C126.826 185.055 111.25 179.795 91.4541 179.408C69.2027 179.021 51.862 184.9 42.1941 189L30.608 179.099C30.5313 179.099 14.3415 118.066 0.300049 30.423Z" fill="#D90007" />
      <path d="M103.756 101.838C109.81 101.838 114.721 127.378 114.721 158.921H123.555C123.555 124.962 114.643 97.4725 103.73 97.4725C97.4941 97.4725 91.9858 105.709 88.3482 118.648C84.7106 105.709 79.2023 97.4725 73.0185 97.4725C62.1058 97.4725 53.2197 124.936 53.2197 158.895H62.0538C62.0538 127.352 66.9126 101.838 72.9665 101.838C79.0205 101.838 83.9312 125.43 83.9312 154.53H92.7133C92.7133 125.43 97.65 101.838 103.704 101.838" fill="#FFBC0D" />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-search"
      viewBox="0 0 16 16"
    >
      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
    </svg>
  );
};

export const CrossSmallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-x"
      viewBox="0 0 16 16"
    >
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
};

export const CrossIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-x-lg"
      viewBox="0 0 16 16"
    >
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
    </svg>
  );
};

export const QuestionmarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5C9.403 3.5 10.679 4.23 10.679 5.74C10.679 6.82 10.044 7.334 9.435 7.797C8.698 8.356 8.425 8.565 8.425 9.283V9.638H7.117L7.11 9.175C7.072 8.248 7.605 7.677 8.278 7.188C8.868 6.744 9.243 6.452 9.243 5.817C9.243 4.992 8.615 4.649 7.929 4.649C7.028 4.649 6.571 5.252 6.571 6.033H6.57ZM7.821 12.476C7.237 12.476 6.812 12.082 6.812 11.549C6.812 10.997 7.237 10.609 7.822 10.609C8.431 10.609 8.85 10.997 8.85 11.549C8.85 12.082 8.43 12.476 7.821 12.476Z"
        fill="#FFBC0D"
      />
    </svg>
  );
};

export const CheckCircleFillIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76515 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76515 3.51472 3.51472C5.76515 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76515 24 8.8174 24 12ZM18.045 7.455C17.9379 7.34823 17.8103 7.26416 17.6699 7.20782C17.5295 7.15149 17.3792 7.12404 17.228 7.12712C17.0768 7.1302 16.9277 7.16374 16.7897 7.22575C16.6518 7.28776 16.5277 7.37695 16.425 7.488L11.2155 14.1255L8.076 10.9845C7.86274 10.7858 7.58067 10.6776 7.28922 10.6827C6.99776 10.6879 6.71969 10.8059 6.51357 11.0121C6.30745 11.2182 6.18938 11.4963 6.18424 11.7877C6.17909 12.0792 6.28728 12.3612 6.486 12.5745L10.455 16.545C10.5619 16.6517 10.6892 16.7358 10.8294 16.7923C10.9695 16.8487 11.1196 16.8764 11.2706 16.8736C11.4217 16.8708 11.5706 16.8376 11.7085 16.776C11.8465 16.7144 11.9706 16.6256 12.0735 16.515L18.0615 9.03C18.2656 8.81775 18.3784 8.53391 18.3756 8.23944C18.3728 7.94496 18.2546 7.66332 18.0465 7.455H18.045Z"
        fill="#292929"
      />
    </svg>
  );
};

export const AlertIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.473 1.34899C13.3246 1.09033 13.1105 0.875438 12.8524 0.725998C12.5942 0.576557 12.3013 0.497864 12.003 0.497864C11.7048 0.497864 11.4118 0.576557 11.1537 0.725998C10.8956 0.875438 10.6815 1.09033 10.533 1.34899L0.247541 18.8495C-0.437959 20.0165 0.384041 21.5 1.71754 21.5H22.287C23.6205 21.5 24.444 20.015 23.757 18.8495L13.473 1.34899ZM12 6.49999C11.8105 6.5001 11.623 6.53991 11.4497 6.61688C11.2765 6.69385 11.1212 6.80625 10.9941 6.94686C10.8669 7.08746 10.7706 7.25315 10.7113 7.43324C10.6521 7.61333 10.6312 7.80384 10.65 7.99249L11.175 13.253C11.1927 13.4596 11.2872 13.6522 11.44 13.7924C11.5928 13.9327 11.7926 14.0106 12 14.0106C12.2074 14.0106 12.4073 13.9327 12.5601 13.7924C12.7128 13.6522 12.8074 13.4596 12.825 13.253L13.35 7.99249C13.3689 7.80384 13.348 7.61333 13.2888 7.43324C13.2295 7.25315 13.1332 7.08746 13.006 6.94686C12.8788 6.80625 12.7236 6.69385 12.5504 6.61688C12.3771 6.53991 12.1896 6.5001 12 6.49999ZM12.003 15.5C11.6052 15.5 11.2237 15.658 10.9424 15.9393C10.6611 16.2206 10.503 16.6022 10.503 17C10.503 17.3978 10.6611 17.7793 10.9424 18.0606C11.2237 18.342 11.6052 18.5 12.003 18.5C12.4009 18.5 12.7824 18.342 13.0637 18.0606C13.345 17.7793 13.503 17.3978 13.503 17C13.503 16.6022 13.345 16.2206 13.0637 15.9393C12.7824 15.658 12.4009 15.5 12.003 15.5Z"
        fill="black"
      />
    </svg>
  );
};

export const CloseFillIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12ZM17.781 7.281C17.9218 7.14017 18.0009 6.94916 18.0009 6.75C18.0009 6.55084 17.9218 6.35983 17.781 6.219C17.6402 6.07817 17.4492 5.99905 17.25 5.99905C17.0508 5.99905 16.8598 6.07817 16.719 6.219L12 10.9395L7.281 6.219C7.21127 6.14927 7.12848 6.09395 7.03738 6.05621C6.94627 6.01848 6.84862 5.99905 6.75 5.99905C6.65138 5.99905 6.55373 6.01848 6.46262 6.05621C6.37152 6.09395 6.28873 6.14927 6.219 6.219C6.14927 6.28873 6.09395 6.37152 6.05621 6.46262C6.01848 6.55373 5.99905 6.65138 5.99905 6.75C5.99905 6.84862 6.01848 6.94627 6.05621 7.03738C6.09395 7.12848 6.14927 7.21127 6.219 7.281L10.9395 12L6.219 16.719C6.07817 16.8598 5.99905 17.0508 5.99905 17.25C5.99905 17.4492 6.07817 17.6402 6.219 17.781C6.35983 17.9218 6.55084 18.0009 6.75 18.0009C6.94916 18.0009 7.14017 17.9218 7.281 17.781L12 13.0605L16.719 17.781C16.7887 17.8507 16.8715 17.906 16.9626 17.9438C17.0537 17.9815 17.1514 18.0009 17.25 18.0009C17.3486 18.0009 17.4463 17.9815 17.5374 17.9438C17.6285 17.906 17.7113 17.8507 17.781 17.781C17.8507 17.7113 17.906 17.6285 17.9438 17.5374C17.9815 17.4463 18.0009 17.3486 18.0009 17.25C18.0009 17.1514 17.9815 17.0537 17.9438 16.9626C17.906 16.8715 17.8507 16.7887 17.781 16.719L13.0605 12L17.781 7.281Z"
        fill="black"
      />
    </svg>
  );
};

export const EditIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
  </svg>
  );
};

export const CheckIcon = () => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#292929" className="bi bi-check2" viewBox="0 0 16 16">
    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
  </svg>
  );
};

export const ChevronUpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 20 20">
      <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
    </svg>
  );
};

export const ChevronCompactDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-compact-down" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z" />
    </svg>
  );
};

export const ChevronCompactUpIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-compact-up" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M7.776 5.553a.5.5 0 0 1 .448 0l6 3a.5.5 0 1 1-.448.894L8 6.56 2.224 9.447a.5.5 0 1 1-.448-.894l6-3z" />
    </svg>
  );
};

export const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#292929" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
      <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
    </svg>
  );
}

export const RedCircleError = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M80 40C80 50.6087 75.7857 60.7828 68.2843 68.2843C60.7828 75.7857 50.6087 80 40 80C29.3913 80 19.2172 75.7857 11.7157 68.2843C4.21427 60.7828 0 50.6087 0 40C0 29.3913 4.21427 19.2172 11.7157 11.7157C19.2172 4.21427 29.3913 0 40 0C50.6087 0 60.7828 4.21427 68.2843 11.7157C75.7857 19.2172 80 29.3913 80 40ZM59.27 24.27C59.7394 23.8006 60.0032 23.1639 60.0032 22.5C60.0032 21.8361 59.7394 21.1994 59.27 20.73C58.8006 20.2606 58.1639 19.9968 57.5 19.9968C56.8361 19.9968 56.1994 20.2606 55.73 20.73L40 36.465L24.27 20.73C24.0376 20.4976 23.7616 20.3132 23.4579 20.1874C23.1542 20.0616 22.8287 19.9968 22.5 19.9968C22.1713 19.9968 21.8458 20.0616 21.5421 20.1874C21.2384 20.3132 20.9624 20.4976 20.73 20.73C20.4976 20.9624 20.3132 21.2384 20.1874 21.5421C20.0616 21.8458 19.9968 22.1713 19.9968 22.5C19.9968 22.8287 20.0616 23.1542 20.1874 23.4579C20.3132 23.7616 20.4976 24.0376 20.73 24.27L36.465 40L20.73 55.73C20.2606 56.1994 19.9968 56.8361 19.9968 57.5C19.9968 58.1639 20.2606 58.8006 20.73 59.27C21.1994 59.7394 21.8361 60.0032 22.5 60.0032C23.1639 60.0032 23.8006 59.7394 24.27 59.27L40 43.535L55.73 59.27C55.9624 59.5024 56.2384 59.6868 56.5421 59.8126C56.8458 59.9384 57.1713 60.0032 57.5 60.0032C57.8287 60.0032 58.1542 59.9384 58.4579 59.8126C58.7616 59.6868 59.0376 59.5024 59.27 59.27C59.5024 59.0376 59.6868 58.7616 59.8126 58.4579C59.9384 58.1542 60.0032 57.8287 60.0032 57.5C60.0032 57.1713 59.9384 56.8458 59.8126 56.5421C59.6868 56.2384 59.5024 55.9624 59.27 55.73L43.535 40L59.27 24.27Z" fill="#DC3545" />
    </svg>
  );
}

export const FilterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 7C4 6.86739 4.05268 6.74021 4.14645 6.64645C4.24021 6.55268 4.36739 6.5 4.5 6.5H7.5C7.63261 6.5 7.75979 6.55268 7.85355 6.64645C7.94732 6.74021 8 6.86739 8 7C8 7.13261 7.94732 7.25979 7.85355 7.35355C7.75979 7.44732 7.63261 7.5 7.5 7.5H4.5C4.36739 7.5 4.24021 7.44732 4.14645 7.35355C4.05268 7.25979 4 7.13261 4 7ZM2 4C2 3.86739 2.05268 3.74021 2.14645 3.64645C2.24021 3.55268 2.36739 3.5 2.5 3.5H9.5C9.63261 3.5 9.75979 3.55268 9.85355 3.64645C9.94732 3.74021 10 3.86739 10 4C10 4.13261 9.94732 4.25979 9.85355 4.35355C9.75979 4.44732 9.63261 4.5 9.5 4.5H2.5C2.36739 4.5 2.24021 4.44732 2.14645 4.35355C2.05268 4.25979 2 4.13261 2 4ZM0 1C0 0.867392 0.0526785 0.740215 0.146447 0.646447C0.240215 0.552679 0.367392 0.5 0.5 0.5H11.5C11.6326 0.5 11.7598 0.552679 11.8536 0.646447C11.9473 0.740215 12 0.867392 12 1C12 1.13261 11.9473 1.25979 11.8536 1.35355C11.7598 1.44732 11.6326 1.5 11.5 1.5H0.5C0.367392 1.5 0.240215 1.44732 0.146447 1.35355C0.0526785 1.25979 0 1.13261 0 1Z" fill="#FFBC0D" />
    </svg>
  );
}

export const DashboardIconNav = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grid-1x2" viewBox="0 0 16 16">
      <path d="M6 1H1v14h5V1zm9 0h-5v5h5V1zm0 9v5h-5v-5h5zM0 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm9 0a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V1zm1 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1h-5z" />
    </svg>
  );
}

export const SupportIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
    </svg>
  );
}

export const AdminIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
      <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
      <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
    </svg>
  );
}

export const PencilIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
    </svg>
  );
}

export const WarningFillIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
    </svg>
  );
}

export const CheckCircleFillCardIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
    </svg>
  );
}

export const DropdownCaret = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none">
      <path d="M10 0.5H0L5 5.5L10 0.5Z" fill="#292929" />
    </svg>
  );
}

export const PlusCircleFill = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
    </svg>
  );
}

export const NoVarianceCircleFill = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="currentColor" className="bi bi-slash-circle-fill" viewBox="0 0 17 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.646-2.646a.5.5 0 0 0-.708-.708l-6 6a.5.5 0 0 0 .708.708l6-6z" />
    </svg>
  );
}

export const NeutralCircleFill = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
      <g clipPath="url(#clip0_2782_2505)">
        <g clipPath="url(#clip1_2782_2505)">
          <path fillRule="evenodd" clipRule="evenodd" d="M16.25 8C16.25 10.1217 15.4071 12.1566 13.9069 13.6569C12.4066 15.1571 10.3717 16 8.25 16C6.12827 16 4.09344 15.1571 2.59315 13.6569C1.09285 12.1566 0.25 10.1217 0.25 8C0.25 5.87827 1.09285 3.84344 2.59315 2.34315C4.09344 0.842855 6.12827 0 8.25 0C10.3717 0 12.4066 0.842855 13.9069 2.34315C15.4071 3.84344 16.25 5.87827 16.25 8Z" fill="#292929" />
          <path d="M4.91663 10H11.5833" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
          <path d="M4.91663 6H11.5833" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2782_2505">
          <rect x="0.25" width="16" height="16" rx="2" fill="white" />
        </clipPath>
        <clipPath id="clip1_2782_2505">
          <rect width="16" height="16" fill="white" transform="translate(0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const MinusCircleFill = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
    </svg>
  );
}

export const ChartVariationPositive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none" className="chart-variation-icon">
      <g clipPath="url(#clip0_2252_2519)">
        <path d="M0 0.5H0.5V8.5H0V0.5ZM0.5 8H8V8.5H0.5V8Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.195 2.65601L5.0205 5.37501L3.5 3.85351L1.677 5.67701L1.323 5.32301L3.5 3.14651L4.9795 4.62501L6.8045 2.34351L7.195 2.65551V2.65601Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5 2.25C5 2.1837 5.02634 2.12011 5.07322 2.07322C5.12011 2.02634 5.1837 2 5.25 2H7.25C7.3163 2 7.37989 2.02634 7.42678 2.07322C7.47366 2.12011 7.5 2.1837 7.5 2.25V4.25C7.5 4.3163 7.47366 4.37989 7.42678 4.42678C7.37989 4.47366 7.3163 4.5 7.25 4.5C7.1837 4.5 7.12011 4.47366 7.07322 4.42678C7.02634 4.37989 7 4.3163 7 4.25V2.5H5.25C5.1837 2.5 5.12011 2.47366 5.07322 2.42678C5.02634 2.37989 5 2.3163 5 2.25Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_2252_2519">
          <rect width="8" height="8" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const ChartVariationNeutral = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none">
      <g clipPath="url(#clip0_2782_2499)">
        <path d="M0.25 0.5H0.75V8.5H0.25V0.5ZM0.75 8H8.25V8.5H0.75V8Z" fill="#292929" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.1743 2.67678C6.22118 2.62989 6.28477 2.60355 6.35107 2.60355C6.41738 2.60355 6.48097 2.62989 6.52785 2.67678L7.94206 4.09099C7.98895 4.13787 8.01529 4.20146 8.01529 4.26777C8.01529 4.33407 7.98895 4.39766 7.94206 4.44454L6.52785 5.85876C6.48097 5.90564 6.41738 5.93198 6.35107 5.93198C6.28477 5.93198 6.22118 5.90564 6.1743 5.85876C6.12741 5.81187 6.10107 5.74828 6.10107 5.68198C6.10107 5.61568 6.12741 5.55209 6.1743 5.5052L7.41173 4.26777L6.1743 3.03033C6.12741 2.98345 6.10107 2.91986 6.10107 2.85355C6.10107 2.78725 6.12741 2.72366 6.1743 2.67678Z" fill="#292929" />
        <path fillRule="evenodd" clipRule="evenodd" d="M1.75 4.08337H7.58333V4.58337H1.75V4.08337Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_2782_2499">
          <rect width="8" height="8" fill="white" transform="translate(0.25 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const ChartVariationNegative = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
      <g clipPath="url(#clip0_2410_12248)">
        <path d="M0 0.5H0.5V8.5H0V0.5ZM0.5 8H8V8.5H0.5V8Z" fill="#198754" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.195 5.0205L5.0205 2.3025L3.5 3.823L1.677 2L1.323 2.3535L3.5 4.5305L4.9795 3.051L6.8045 5.333L7.195 5.0205Z" fill="#198754" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5 5.427C5 5.49331 5.02634 5.55689 5.07322 5.60378C5.12011 5.65066 5.1837 5.677 5.25 5.677H7.25C7.3163 5.677 7.37989 5.65066 7.42678 5.60378C7.47366 5.55689 7.5 5.49331 7.5 5.427V3.427C7.5 3.3607 7.47366 3.29711 7.42678 3.25023C7.37989 3.20334 7.3163 3.177 7.25 3.177C7.1837 3.177 7.12011 3.20334 7.07322 3.25023C7.02634 3.29711 7 3.3607 7 3.427V5.177H5.25C5.1837 5.177 5.12011 5.20334 5.07322 5.25023C5.02634 5.29711 5 5.3607 5 5.427Z" fill="#198754" />
      </g>
      <defs>
        <clipPath id="clip0_2410_12248">
          <rect width="8" height="8" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const SaveIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-save" viewBox="0 0 16 16">
      <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
    </svg>
  );
}

export const SignOutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-power" viewBox="0 0 16 16">
      <path d="M7.5 1v7h1V1h-1z" />
      <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
    </svg>
  );
}

export const ChevronRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
    </svg>
  );
}

export const ChevronLeftIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
      <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
    </svg>
  );
}

export const QuestionCircleIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle-fill" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
    </svg>
  );
}
