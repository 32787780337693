import { useState } from "react";
import { Label } from "reactstrap";
import "../Dropdown.scss";
import Checkbox from "./Checkbox";
import { useAppSelector } from "../../../app/hooks";
import { changeValue, DELETE_ITEM } from "../../../features/dropdownWithCheckbox/DropdownCheckboxSlice";
import Tags from '../../tags/Tags'
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useTranslation } from "react-i18next";
interface DropdownNormalSelector {
  labels: DropdownCheckboxSelectors[];
  isDisabled?: boolean;
  label: string;
  onChangeHandle?: any;
  name: string;
  error?: boolean;
  success?: boolean;
  storeId: string,
  message: string;
  messagePlural?: string;
  messageSingular?: string;
  placeholder: string;
  hasYellowBackground?: boolean;
}

export interface DropdownCheckboxSelectors {
  description: string;
  id: string | null;
}

const DropdownCheckbox = ({
  onChangeHandle = null,
  label,
  error,
  success,
  name,
  labels,
  message,
  storeId,
  messagePlural = "",
  messageSingular = "",
  placeholder,
  hasYellowBackground
}: DropdownNormalSelector) => {

  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);

  const changeStateValue = (dropId: string, idCheck: number, checkLabel: string, checkValue: boolean) => {
    return changeValue({
      storeId: dropId,
      id: idCheck,
      label: checkLabel,
      value: checkValue
    })
  }

  const closeDropdown = () => {
    setIsActive(false);
  }

  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  const handleChecked = (id: string | null) => {
    const valueCheckedObj = value.find((item) => {
      let obj;
      if (id === item.id && item.value === true) {
        return obj = item.value
      }
      return obj
    })
    return valueCheckedObj
  }

  const value = useAppSelector((store) => store.dropdowncheckbox.entities[storeId]?.value) || [];

  const totalNumberShowup = () => {
    if (value[0]?.id === null) {
      return value.length - 1
    } else {
      return value.length
    }
  }

  return (
    <div className={"dropdown-container-inside"}>
      <Label for={name}>{label}</Label>
      <div className="tags-dropdown">
        <Tags
          tagsArray={value}
          storeId={"tagsdropdown-" + storeId}
          onChangeAction={DELETE_ITEM}
          dropdownStoreID={storeId}
          onChangeHandle={onChangeHandle}/>
      </div>
      <div className="dropdown" ref={ref}>
        <div
          className={
            'dropdown-btn' +
            (isActive ? " active" : "") +
            (error ? " error" : "" ) +
            (success ? " success" : "") +
            (hasYellowBackground ? " focus-white" : " focus-yellow")
          }
          onClick={() => setIsActive(!isActive)}>
          {value.length > 1 ? totalNumberShowup() + " " + messagePlural : value.length === 1 ? totalNumberShowup() + " " + messageSingular : <div className="placeholder-text">{placeholder}</div>}
          <span className="fas fa-caret-down"></span>
        </div>
        <>
          {isActive && (
            <div className="dropdown-content" >
              {labels.map((option, index) => {
                return <Checkbox
                  key={index}
                  label={option.description}
                  checked={handleChecked(option.id)}
                  checkboxID={option.id}
                  dropdownStoreID={storeId}
                  onChangeAction={changeStateValue}
                  onChangeHandle={onChangeHandle} />
              })}
              {
                labels.length === 0 &&
                <span className="dropdown-no-options">{t("common.dropdown.noOptions")}</span>
              }
            </div>
          )}
        </>
        <span className={`${error ? "error" : ""} ${success ? "success" : ""}`}>
          {message}
        </span>
      </div>
    </div>
  );
};
export default DropdownCheckbox;
