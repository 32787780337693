import { useAppDispatch } from '../../app/hooks';
import { CrossIcon, CloseFillIcon, CheckCircleFillIcon, AlertIcon, SupportIcon } from '../../images/icons';
import './Toast.scss';


interface ToastData {
    data: toastMessages[],
    closeAction?: any
}

export interface toastMessages {
    type: string,
    message: string,
    id: number
}

const Toast = ({ data, closeAction }: ToastData) => {
    const dispatch = useAppDispatch();

    const handleClose = (id: number) => {
        closeAction && dispatch(closeAction(id));
    };

    return (
        <div className={"toast-box-container"}>
            {data.map((toast, index) => {
                return <div className='toast-chip' key={`toast-${index}`} data-type={toast.type}>
                       {toast.type === 'success' && <div className="toast-icon success"><CheckCircleFillIcon /></div>}
                       {toast.type === 'warning' && <div className="toast-icon warning"><AlertIcon /></div>}
                       {toast.type === 'error' && <div className="toast-icon error"><CloseFillIcon /></div>}
                       {toast.type === 'info' && <div className="toast-icon info"><SupportIcon /></div>}
                    <p className='toast-msg'>{ toast.message }</p>
                    <button className='toast-close-btn' onClick={ () => handleClose(toast.id) }>
                        <CrossIcon></CrossIcon>
                    </button>
                </div>
            })}
        </div>
    );
}

export default Toast;