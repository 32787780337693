import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import OffCanvasFilterFooter from '../../off-canvas-filter/off-canvas-filter-footer/OffCanvasFilterFooter';
import Checkbox from '../../../private/checkbox/Checkbox';
import Dropdown, { DropdownSelectors } from '../../../private/dropdown/Normal/DropDown';
import DropdownCheckbox from '../../../private/dropdown/WithCheckBox/DropdownWithCheckbox';

import { clearMissingObjective, getAllObjectives, getStoresFilter, setAppliedFilters, toggleMissingObjective } from '../../../features/administration/AdministrationSlice';
import { changeValue } from '../../../features/dropdown/dropdownSlice';
import { clearValue, updateTotalArray } from '../../../features/dropdownWithCheckbox/DropdownCheckboxSlice';
import { setLoading } from '../../../features/main/MainSlice';
import { closeFilters } from '../../../features/off-canvas-filter/OffCanvasFilterSlice';

import { administrationFiltersStoreIds } from '../../../global/Variables';
import { GetAllObjectivesResponse, GetStoresFilterResponse } from '../../../models/AdministrationModels';
import { ErrorInfo } from '../../../models/GlobalModels';
import { toggleModalError } from '../../../features/modal-error/ModalErrorSlice';

const AdministrationFilters = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { filters } = useAppSelector((store) => store.administration);
    const restaurantsFilter = useAppSelector((store) => store.dropdowncheckbox.entities[administrationFiltersStoreIds.restaurants]?.totalArray);
    const yearFilter = useAppSelector((store) => store.dropdown.entities[administrationFiltersStoreIds.year]?.value);

    const handleClearFilters = () => {
        dispatch(changeValue({
            id: administrationFiltersStoreIds.year,
            value: filters.year[0]
        }));
        dispatch(clearValue([administrationFiltersStoreIds.restaurants]));
        dispatch(clearMissingObjective());
        dispatch(setAppliedFilters({
            missingObjective: false,
            year: filters.year[0],
            restaurants: []
        }));
    };

    const handleApplyFilters = () => {
		dispatch(setLoading(true));
        dispatch(getAllObjectives()).unwrap()
            .then((resp: GetAllObjectivesResponse) => {
                if (
                    resp.nationalObjectives.statusCode === 200 &&
                    resp.mcOpCoObjectives.statusCode === 200 &&
                    resp.storesFilter.statusCode === 200
                ) {
                    let restaurants = resp.storesFilter.data;
                    restaurants.unshift({
                        id: null,
                        description: t("administration.filters.restaurantsSelectAll")
                    });
                    dispatch(updateTotalArray(
                        {
                            storeId: administrationFiltersStoreIds.restaurants,
                            totalArray: restaurants
                        }
                    ));
                    dispatch(setAppliedFilters({
                        missingObjective: filters.missingObjective,
                        year: yearFilter,
                        restaurants: []
                    }));
                    dispatch(closeFilters());
                } else {
                    dispatch(toggleModalError());
                }
                dispatch(setLoading(false));
            })
            .catch((error: ErrorInfo) => {
                dispatch(toggleModalError());
                dispatch(setLoading(false));
            });
    };

    const handleMissingObjective = (isChecked: boolean) => {
        dispatch(toggleMissingObjective(isChecked));
    };

    const handleChangeYear = (dropDownValue: DropdownSelectors) => {
        if (yearFilter && dropDownValue.id !== yearFilter.id) {
		    dispatch(setLoading(true));
            dispatch(clearValue([administrationFiltersStoreIds.restaurants]));
            dispatch(getStoresFilter()).unwrap()
                .then((resp: GetStoresFilterResponse) => {
                    if (resp.statusCode === 200) {
                        let restaurants = resp.data;
                        restaurants.unshift({
                            id: null,
                            description: t("administration.filters.restaurantsSelectAll")
                        });
                        dispatch(updateTotalArray(
                            {
                                storeId: administrationFiltersStoreIds.restaurants,
                                totalArray: restaurants
                            }
                        ));
                    } else {
                        dispatch(changeValue({
                            id: administrationFiltersStoreIds.year,
                            value: filters.year[0]
                        }));
                        dispatch(toggleModalError());
                    }
                    dispatch(setLoading(false));
                })
                .catch((error: ErrorInfo) => {
                    dispatch(changeValue({
                        id: administrationFiltersStoreIds.year,
                        value: filters.year[0]
                    }));
                    dispatch(toggleModalError());
                    dispatch(setLoading(false));
                });
        }
    };

    return (
        <div className="administration-filters-container">
            <div className="administration-filters-body">
            {/* YEAR */}
            <Dropdown
                hasYellowBackground={true}
                name={"administration-filters-year"}
                label={t("administration.filters.year")}
                placeholder={t("administration.filters.yearPlaceholder")}
                labels={filters.year}
                message={""}
                storeId={administrationFiltersStoreIds.year}
                handleOnChange={handleChangeYear}/>
            {/* RESTAURANTS */}
            <DropdownCheckbox
                hasYellowBackground={true}
                name={"administration-filters-restaurants"}
                label={t("administration.filters.restaurants")}
                message={""}
                labels={restaurantsFilter}
                storeId={administrationFiltersStoreIds.restaurants}
                messageSingular={t("administration.filters.restaurantsSelectedSingular")}
                messagePlural={t("administration.filters.restaurantsSelectedPlural")}
                placeholder={t("administration.filters.restaurantsPlaceholder")}/>
            {/* MISSING OBJECTIVE */}
            <Checkbox
                hasYellowBackground={true}
                label={t("administration.filters.missingObjective")}
                name={"administration-filters-missing"}
                value={filters.missingObjective}
                onChangeHandle={handleMissingObjective}/>
            </div>
            <OffCanvasFilterFooter handleClear={handleClearFilters} handleApply={handleApplyFilters} />
        </div>
    );
}

export default AdministrationFilters;
