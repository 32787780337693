export const apiInfo = {
    apiKey: '6730f03e-b72b-438e-b1cc-7984ada2620b'
};

export const oldestYearDashboardFilter: number = 2002;

export const oldestYearAdministrationFilter: number = 2022;

export const dashboardFiltersStoreIds = {
    year: 'dashboardFiltersYear',
    month: 'dashboardFiltersMonth',
    franchisees: 'dashboardFiltersFranchisees',
    restaurants: 'dashboardFiltersRestaurants',
    consultantsOps: 'dashboardFiltersConsultantsOps',
    region: 'dashboardFiltersRegion',
    typology: 'dashboardFiltersTipology'
};

export const administrationFiltersStoreIds = {
    year: 'administrationFiltersYear',
    restaurants: 'administrationFiltersRestaurants'
};

export const langPt: string = 'pt';
export const langEn: string = 'en';