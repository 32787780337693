import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { addOrUpdateTurnOver, addToast, changeMotives, setStep } from '../../../features/submissions/SubmissionsSlice';
import Buttons from '../../../private/buttons/Buttons';
import TooltipBox from '../../../private/tooltip/TooltipBox';
import { QuestionCircleIcon } from '../../../images/icons';
import { Input, Label } from 'reactstrap';
import { setLoading } from '../../../features/main/MainSlice';
import { AddOrUpdateTurnOverResponse } from '../../../models/SubmissionsModels';
import { ErrorInfo } from '../../../models/GlobalModels';

const SubmissionsStep2 = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
  const {
    currentForm,
    isEmployees
  } = useAppSelector((store) => store.submissions);

  const toast = {
    success: {
      type: 'success',
      message: t("submissions.toast.success"),
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(setStep(2));
  }, []);

  const setMotivesValue = (index: number, isLess90Days: boolean) => {
    if (isLess90Days) {
      return currentForm.step2.less90Days[index];
    } else {
      return currentForm.step2.more90Days[index];
    }
  }

  const handleMotives = (value: string, isLess90Days: boolean, motiveNumber: number) => {
    dispatch(changeMotives({
      value: value,
      isLess90Days: isLess90Days,
      motiveNumber: motiveNumber
    }));
  };

  const handleGoNext = () => {
    dispatch(setLoading(true));
    dispatch(addOrUpdateTurnOver()).unwrap()
      .then((resp: AddOrUpdateTurnOverResponse) => {
        if (resp.statusCode === 200) {
          dispatch(addToast(toast.success));
          navigate(`/${currentLanguage.lang}/submissions/search/${isEmployees ? 'crew' : 'management'}`);
        } else {
          navigate(`/${currentLanguage.lang}/error-page`);
        }
      })
      .catch((error: ErrorInfo) => {
        navigate(`/${currentLanguage.lang}/error-page`);
      });
  };

  return (
    <div className="submissions-step2">
      <div className="submissions-step2-body">
        <div className="submissions-step2-header">
          <span className="submissions-step2-title">{t("submissions.step2.title")}</span>
          <div className="submissions-step2-sub-titles">
            <span className="submissions-step2-sub-title">
              {t("submissions.step2.less90DaysLabel")}
              <span className="submissions-tooltip" id="tooltip-submissions-step2-less-90-days">
                <QuestionCircleIcon />
              </span>
              <TooltipBox value={t("submissions.step2.less90DaysTooltip")} target={'tooltip-submissions-step2-less-90-days'} placement={'right'} />
            </span>
            <span className="submissions-step2-sub-title">
              {t("submissions.step2.more90DaysLabel")}
              <span className="submissions-tooltip" id="tooltip-submissions-step2-more-90-days">
                <QuestionCircleIcon />
              </span>
              <TooltipBox value={t("submissions.step2.more90DaysTooltip")} target={'tooltip-submissions-step2-more-90-days'} placement={'right'} />
            </span>
          </div>
        </div>
        <div className="submissions-step2-questions">
          <div className="submissions-step2-question">
            <Label for={'less90Days1'} className="submissions-step2-question-label">{t("submissions.step2.question1")}</Label>
            <div className="submissions-multi-input-wrapper">
              <Input
                type="text"
                name={'less90Days1'}
                value={setMotivesValue(0, true)}
                onChange={(event) => handleMotives(event.target.value, true, 1)} />
              <Input
                type="text"
                name={'more90Days1'}
                value={setMotivesValue(0, false)}
                onChange={(event) => handleMotives(event.target.value, false, 1)} />
            </div>
          </div>
          <div className="submissions-step2-question">
            <Label for={'less90Days2'} className="submissions-step2-question-label">{t("submissions.step2.question2")}</Label>
            <div className="submissions-multi-input-wrapper">
              <Input
                type="text"
                name={'less90Days2'}
                value={setMotivesValue(1, true)}
                onChange={(event) => handleMotives(event.target.value, true, 2)} />
              <Input
                type="text"
                name={'more90Days2'}
                value={setMotivesValue(1, false)}
                onChange={(event) => handleMotives(event.target.value, false, 2)} />
            </div>
          </div>
          <div className="submissions-step2-question">
            <Label for={'less90Days3'} className="submissions-step2-question-label">{t("submissions.step2.question3")}</Label>
            <div className="submissions-multi-input-wrapper">
              <Input
                type="text"
                name={'less90Days3'}
                value={setMotivesValue(2, true)}
                onChange={(event) => handleMotives(event.target.value, true, 3)} />
              <Input
                type="text"
                name={'more90Days3'}
                value={setMotivesValue(2, false)}
                onChange={(event) => handleMotives(event.target.value, false, 3)} />
            </div>
          </div>
          <div className="submissions-step2-question">
            <Label for={'less90Days4'} className="submissions-step2-question-label">{t("submissions.step2.question4")}</Label>
            <div className="submissions-multi-input-wrapper">
              <Input
                type="text"
                name={'less90Days4'}
                value={setMotivesValue(3, true)}
                onChange={(event) => handleMotives(event.target.value, true, 4)} />
              <Input
                type="text"
                name={'more90Days4'}
                value={setMotivesValue(3, false)}
                onChange={(event) => handleMotives(event.target.value, false, 4)} />
            </div>
          </div>
          <div className="submissions-step2-question">
            <Label for={'less90Days5'} className="submissions-step2-question-label">{t("submissions.step2.question5")}</Label>
            <div className="submissions-multi-input-wrapper">
              <Input
                type="text"
                name={'less90Days5'}
                value={setMotivesValue(4, true)}
                onChange={(event) => handleMotives(event.target.value, true, 5)} />
              <Input
                type="text"
                name={'more90Days5'}
                value={setMotivesValue(4, false)}
                onChange={(event) => handleMotives(event.target.value, false, 5)} />
            </div>
          </div>
          <div className="submissions-step2-question">
            <Label for={'less90Days6'} className="submissions-step2-question-label">{t("submissions.step2.question6")}</Label>
            <div className="submissions-multi-input-wrapper">
              <Input
                type="text"
                name={'less90Days6'}
                value={setMotivesValue(5, true)}
                onChange={(event) => handleMotives(event.target.value, true, 6)} />
              <Input
                type="text"
                name={'more90Days6'}
                value={setMotivesValue(5, false)}
                onChange={(event) => handleMotives(event.target.value, false, 6)} />
            </div>
          </div>
          <div className="submissions-step2-question">
            <Label for={'less90Days7'} className="submissions-step2-question-label">{t("submissions.step2.question7")}</Label>
            <div className="submissions-multi-input-wrapper">
              <Input
                type="text"
                name={'less90Days7'}
                value={setMotivesValue(6, true)}
                onChange={(event) => handleMotives(event.target.value, true, 7)} />
              <Input
                type="text"
                name={'more90Days7'}
                value={setMotivesValue(6, false)}
                onChange={(event) => handleMotives(event.target.value, false, 7)} />
            </div>
          </div>
          <div className="submissions-step2-question">
            <Label for={'less90Days8'} className="submissions-step2-question-label">{t("submissions.step2.question8")}</Label>
            <div className="submissions-multi-input-wrapper">
              <Input
                type="text"
                name={'less90Days8'}
                value={setMotivesValue(7, true)}
                onChange={(event) => handleMotives(event.target.value, true, 8)} />
              <Input
                type="text"
                name={'more90Days8'}
                value={setMotivesValue(7, false)}
                onChange={(event) => handleMotives(event.target.value, false, 8)} />
            </div>
          </div>
        </div>
      </div>
      <Buttons
        label={t("submissions.submit")}
        handleOnClick={handleGoNext} />
    </div>
  );
}

export default SubmissionsStep2;