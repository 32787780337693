import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { toggleOpenConfirm } from '../../features/modal-confirm/ModalConfirmSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Buttons from "../buttons/Buttons";
import './ModalConfirm.scss';
import { useTranslation } from 'react-i18next';
import { LogoutIcon } from '../../images/icons';
import { useNavigate } from 'react-router-dom';
import { getLogout } from '../../features/authentication/AuthenticationSlice';
import { ErrorInfo } from '../../models/GlobalModels';

const ModalConfirm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { isOpen } = useAppSelector((store) => store.modalConfirm);
    const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);

    const handleToggleOpen = () => {
        dispatch(toggleOpenConfirm());
    };

    const handleSignOut = () => {
        dispatch(getLogout()).unwrap()
        .then((resp: any) => {
        })
        .catch((error: ErrorInfo) => {
            if (error.status === 302) {
              document.location.href = error.response;
            } else {
              navigate(`/${currentLanguage.lang}/error-page`);
            }
        });
    };

    const {t} = useTranslation();

    return (
        <div className='modal-confirm-container'>
            <Modal isOpen={isOpen} toggle={handleToggleOpen} centered={true} className="modal-confirm-wrapper">
                <ModalHeader toggle={handleToggleOpen}></ModalHeader>
                <ModalBody>
                    <span>
                        <LogoutIcon />
                    </span>
                    <h1>
                        {t("common.modalConfirm.title")}
                    </h1>
                    <p>
                        {t("common.modalConfirm.description")}
                    </p>
                    <div className='modal-confirm-footer'>
                        <Buttons label={t("common.modalConfirm.cancelButton")} handleOnClick={handleToggleOpen} />
                        <Buttons label={t("common.modalConfirm.yesButton")} handleOnClick={handleSignOut} />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalConfirm;