import { Input } from "reactstrap";
import './SmallInput.scss';

interface SmallInputData {
    value: string,
    placeholder?: string,
    hasError?: boolean
    id?: any,
    handleChangeInputValue?: any,
    handleEnter?: any
}

const SmallInput = ({ value, id, handleChangeInputValue, handleEnter, hasError = false, placeholder }: SmallInputData) => {
    const handleChangeValue = (value: string) => {
        handleChangeInputValue({
            id: id,
            value: value
        });
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && handleEnter) {
            handleEnter(id);
        }
    };

    return (
        <div className={"small-input-container" + (hasError ? " input-error" : "")}>
            <Input
                value={value}
                type={'text'}
                placeholder={placeholder}
                onWheel={(event: any) => event.target.blur()}
                onChange={(event) => handleChangeValue(event.target.value)}
                onKeyDown={(event) => handleKeyDown(event)} />
        </div>
    );
}

export default SmallInput;