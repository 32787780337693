import './OffCanvasFilterFooter.scss';
import Buttons from '../../../private/buttons/Buttons';
import { useTranslation } from 'react-i18next';

interface OffCanvasFilterFooterData {
    handleClear: any,
    handleApply: any
}

const OffCanvasFilterFooter = ({ handleClear, handleApply }: OffCanvasFilterFooterData) => {
    const { t } = useTranslation();
    return (
        <div className="off-canvas-filter-footer-container">
            <Buttons handleOnClick={ handleClear } label={t("canvasFilters.buttons.clean")} />
            <Buttons handleOnClick={ handleApply } label={t("canvasFilters.buttons.apply")} />
        </div>
    );
}

export default OffCanvasFilterFooter;