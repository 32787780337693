import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import Buttons from '../../../private/buttons/Buttons';
import { setLoading } from '../../../features/main/MainSlice';
import { monthsTranslationAlias } from '../../../global/Utils';
import { EditIcon } from '../../../images/icons';

const SubmissionsConsult = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
  const {
    currentForm,
    isEmployees
  } = useAppSelector((store) => store.submissions);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (currentForm.card === null) {
      navigate(`/${currentLanguage.lang}/submissions`);
    } else {
      dispatch(setLoading(false));
    }
  }, []);

  const setMotivesValue = (index: number, isLess90Days: boolean) => {
    if (isLess90Days) {
      if (!currentForm.step2.less90Days[index]) {
        return '-';
      } else {
        return currentForm.step2.less90Days[index];
      }
    } else {
      if (!currentForm.step2.more90Days[index]) {
        return '-';
      } else {
        return currentForm.step2.more90Days[index];
      }
    }
  }
  
  const handleEdit = () => {
    navigate(`/${currentLanguage.lang}/submissions/search/form/step1`);
  };

  return (
    <div className="submissions-consult">
      {
        currentForm.card &&
        <div className="submissions-consult-container">
          <div className="submissions-consult-header">
            <span className="submissions-consult-title">{t("submissions.consult.history")}</span>
            <div className="submissions-consult-store-name">
              <span className="submissions-consult-name">{currentForm.card.storeName}</span>
              <span className="submissions-consult-date">
                {(`${currentForm.card.year} ${t("common.months." + monthsTranslationAlias[currentForm.card.month - 1])}`)}
              </span>
            </div>
          </div>
          <div className="submissions-consult-body">
            <div className="submissions-consult-turnover">
              <div className="submissions-consult-turnover-question">
                <span className="submissions-consult-turnover-label">{t("submissions.step1.question1")}</span>
                <span className="submissions-consult-turnover-muti-value">
                  <span className="submissions-consult-turnover-value">
                    {`${currentForm.step1.exitsEmployees} ${isEmployees ? t("submissions.consult.employees") : t("submissions.consult.management")}`}
                  </span>
                  <span className="submissions-consult-turnover-value">{"|"}</span>
                  <span className="submissions-consult-turnover-value">{`${currentForm.step1.exitsContractors} ${t("submissions.consult.contractors")}`}</span>
                </span>
              </div>
              <div className="submissions-consult-turnover-question">
                <span className="submissions-consult-turnover-label">{t("submissions.step1.question2")}</span>
                <span className="submissions-consult-turnover-value">
                  {`${currentForm.step1.actives} ${t("submissions.consult.employees")}`}
                </span>
              </div>
              <div className="submissions-consult-turnover-question">
                <span className="submissions-consult-turnover-label">{t("submissions.step1.question3")}</span>
                <span className="submissions-consult-turnover-value">
                  {`${currentForm.step1.hirings} ${t("submissions.consult.employees")}`}
                </span>
              </div>
              <div className="submissions-consult-turnover-question">
                <span className="submissions-consult-turnover-label">{t("submissions.step1.question6")}</span>
                <span className="submissions-consult-turnover-value">
                  {`${currentForm.step1.didntStart} ${t("submissions.consult.employees")}`}
                </span>
              </div>
              <div className="submissions-consult-turnover-question">
                <span className="submissions-consult-turnover-label">{t("submissions.step1.question4")}</span>
                <span className="submissions-consult-turnover-value">
                  {`${currentForm.step1.exits90Days} ${t("submissions.consult.employees")}`}
                </span>
              </div>
              <div className="submissions-consult-turnover-question">
                <span className="submissions-consult-turnover-label">{t("submissions.step1.question5")}</span>
                <span className="submissions-consult-turnover-value">
                  {`${currentForm.step1.hirings90Days} ${t("submissions.consult.employees")}`}
                </span>
              </div>
            </div>
            <div className="submissions-consult-motives">
              <div className="submissions-consult-motives-header">
                <span className="submissions-consult-motives-header-title">{t("submissions.step2.title")}</span>
                <div className="submissions-consult-motives-values">
                  <span className="submissions-consult-motives-header-sub-title">{t("submissions.step2.less90DaysLabel")}</span>
                  <span className="submissions-consult-motives-header-sub-title">{t("submissions.step2.more90DaysLabel")}</span>
                </div>
              </div>
              <div className="submissions-consult-motive">
                <span className="submissions-consult-motive-label">{t("submissions.step2.question1")}</span>
                <div className="submissions-consult-motives-values">
                <span className="submissions-consult-motive-value">{setMotivesValue(0, true)}</span>
                  <span className="submissions-consult-motive-value">{setMotivesValue(0, false)}</span>
                </div>
              </div>
              <div className="submissions-consult-motive">
                <span className="submissions-consult-motive-label">{t("submissions.step2.question2")}</span>
                <div className="submissions-consult-motives-values">
                <span className="submissions-consult-motive-value">{setMotivesValue(1, true)}</span>
                  <span className="submissions-consult-motive-value">{setMotivesValue(1, false)}</span>
                </div>
              </div>
              <div className="submissions-consult-motive">
                <span className="submissions-consult-motive-label">{t("submissions.step2.question3")}</span>
                <div className="submissions-consult-motives-values">
                <span className="submissions-consult-motive-value">{setMotivesValue(2, true)}</span>
                  <span className="submissions-consult-motive-value">{setMotivesValue(2, false)}</span>
                </div>
              </div>
              <div className="submissions-consult-motive">
                <span className="submissions-consult-motive-label">{t("submissions.step2.question4")}</span>
                <div className="submissions-consult-motives-values">
                <span className="submissions-consult-motive-value">{setMotivesValue(3, true)}</span>
                  <span className="submissions-consult-motive-value">{setMotivesValue(3, false)}</span>
                </div>
              </div>
              <div className="submissions-consult-motive">
                <span className="submissions-consult-motive-label">{t("submissions.step2.question5")}</span>
                <div className="submissions-consult-motives-values">
                <span className="submissions-consult-motive-value">{setMotivesValue(4, true)}</span>
                  <span className="submissions-consult-motive-value">{setMotivesValue(4, false)}</span>
                </div>
              </div>
              <div className="submissions-consult-motive">
                <span className="submissions-consult-motive-label">{t("submissions.step2.question6")}</span>
                <div className="submissions-consult-motives-values">
                <span className="submissions-consult-motive-value">{setMotivesValue(5, true)}</span>
                  <span className="submissions-consult-motive-value">{setMotivesValue(5, false)}</span>
                </div>
              </div>
              <div className="submissions-consult-motive">
                <span className="submissions-consult-motive-label">{t("submissions.step2.question7")}</span>
                <div className="submissions-consult-motives-values">
                <span className="submissions-consult-motive-value">{setMotivesValue(6, true)}</span>
                  <span className="submissions-consult-motive-value">{setMotivesValue(6, false)}</span>
                </div>
              </div>
              <div className="submissions-consult-motive">
                <span className="submissions-consult-motive-label">{t("submissions.step2.question8")}</span>
                <div className="submissions-consult-motives-values">
                <span className="submissions-consult-motive-value">{setMotivesValue(7, true)}</span>
                  <span className="submissions-consult-motive-value">{setMotivesValue(7, false)}</span>
                </div>
              </div>
            </div>
          </div>
          {
            currentForm.card.isEditable &&
            <Buttons
              label={t("submissions.consult.edit")}
              handleOnClick={handleEdit}
              icon={<EditIcon />} />
          }
        </div>
      }
    </div>
  );
}

export default SubmissionsConsult;