import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import ResultCard from '../../../private/result-card/ResultCard';
import SearchBar from '../../../private/search-bar/SearchBar';
import { changeNumberResults, changePage, calcTotalPages, changeSearchValue, clearSearchValue, getAllPaged, nextPage, previousPage, toggleDropdown, toggleHideResults, setHideSearchBar, setCurrentCard, getDetail, setIsEdit, resetForm } from '../../../features/submissions/SubmissionsSlice';
import { DropdownSelectors } from '../../../private/dropdown/Normal/DropDown';
import { setLoading } from '../../../features/main/MainSlice';
import { GetAllPagedRequestData, GetAllPagedResponse, GetDetailRequestData, GetDetailResponse, SubmissionsSearchCards } from '../../../models/SubmissionsModels';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorInfo } from '../../../models/GlobalModels';
import { getCurrentPath } from '../../../global/Utils';

const SubmissionsSearch = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
  const {
    cards,
    isEntriesNumberOpen,
    entriesNumber,
    entriesNumberSelected,
    currentPage,
    totalPages,
    visiblePagesArray,
    searchValue,
    isInitialLoading,
    resultsNumber,
    isResultsNumberHidden,
    isSearchBarHidden,
    isEmployees
  } = useAppSelector((store) => store.submissions);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const currentPath = getCurrentPath(location.pathname);
    dispatch(setLoading(true));
    const requestData: GetAllPagedRequestData = {
      page: 1,
      pageSize: entriesNumber[0].id,
      filter: '',
      isManagement: currentPath === 'management'
    };
    dispatch(getAllPaged(requestData)).unwrap()
      .then((resp: GetAllPagedResponse) => {
        if (resp.statusCode === 200) {
          dispatch(clearSearchValue());
          dispatch(changePage(1));
          dispatch(toggleHideResults(true));
          dispatch(changeNumberResults(entriesNumber[0]));
          dispatch(calcTotalPages(1));
          dispatch(setHideSearchBar(resp.data.recordsTotal === 1));
          dispatch(setLoading(false));
          dispatch(resetForm());
        } else {
          navigate(`/${currentLanguage.lang}/error-page`);
        }
      })
      .catch((error: ErrorInfo) => {
        navigate(`/${currentLanguage.lang}/error-page`);
      });
  }, []);

  const getAllPages = (
      requestData: GetAllPagedRequestData | null = null,
      hasLoading: boolean = true,
      showResultsNumber: boolean | null = null,
      pageNumberToChange: number | null = null
    ) => {
    if (hasLoading) {
      dispatch(setLoading(true));
    }
    dispatch(getAllPaged(requestData)).unwrap()
      .then((resp: GetAllPagedResponse) => {
        if (resp.statusCode === 200) {
          dispatch(calcTotalPages(pageNumberToChange));
          if (pageNumberToChange !== null) {
            dispatch(changePage(pageNumberToChange));
          }
          if (showResultsNumber !== null) {
            dispatch(toggleHideResults(showResultsNumber));
          }
          if (hasLoading) {
            dispatch(setLoading(false));
          }
        } else {
          navigate(`/${currentLanguage.lang}/error-page`);
        }
      })
      .catch((error: ErrorInfo) => {
        navigate(`/${currentLanguage.lang}/error-page`);
      });
  };

  const handleToggleDropdown = () => {
    dispatch(toggleDropdown());
  };

  const handleChangeNumberResults = (option: DropdownSelectors) => {
    dispatch(changeNumberResults(option));
    const requestData: GetAllPagedRequestData = {
      page: currentPage,
      pageSize: option.id,
      filter: searchValue,
      isManagement: !isEmployees
    };
    getAllPages(requestData);
  };

  const handleChangeSearch = (value: string) => {
    dispatch(changeSearchValue(value));
    if (value.length > 2) {
      const requestData: GetAllPagedRequestData = {
        page: 1,
        pageSize: entriesNumberSelected.id,
        filter: value,
        isManagement: !isEmployees
      };
      getAllPages(requestData, false, false, 1);
    } else if (searchValue.length > value.length) {
      const requestData: GetAllPagedRequestData = {
        page: 1,
        pageSize: entriesNumberSelected.id,
        filter: '',
        isManagement: !isEmployees
      };
      getAllPages(requestData, false, true, 1);
    }
  };

  const handleClearSearch = () => {
    dispatch(clearSearchValue());
    const requestData: GetAllPagedRequestData = {
      page: 1,
      pageSize: entriesNumberSelected.id,
      filter: '',
      isManagement: !isEmployees
    };
    getAllPages(requestData, true, true, 1);
  };

  const handlePreviousPage = () => {
    if (currentPage !== 1) {
      dispatch(previousPage());
      const requestData: GetAllPagedRequestData = {
        page: currentPage - 1,
        pageSize: entriesNumberSelected.id,
        filter: searchValue,
        isManagement: !isEmployees
      };
      getAllPages(requestData, true);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      dispatch(nextPage());
      const requestData: GetAllPagedRequestData = {
        page: currentPage + 1,
        pageSize: entriesNumberSelected.id,
        filter: searchValue,
        isManagement: !isEmployees
      };
      getAllPages(requestData, true);
    }
  };

  const handleChangePage = (pageNumber: number) => {
    const requestData: GetAllPagedRequestData = {
      page: pageNumber,
      pageSize: entriesNumberSelected.id,
      filter: searchValue,
      isManagement: !isEmployees
    };
    getAllPages(requestData, true, null, pageNumber);
  };

  const handleFillIn = (card: SubmissionsSearchCards) => {
    dispatch(setCurrentCard(card));
    dispatch(setIsEdit(false));
    navigate(`/${currentLanguage.lang}/submissions/search/form/step1`);
  };

  const handleEdit = (card: SubmissionsSearchCards) => {
    dispatch(setCurrentCard(card));
    dispatch(setLoading(true));
    const requestData: GetDetailRequestData = {
      turnOverId: card.turnOverId,
      isManagement: !isEmployees
    };
    dispatch(getDetail(requestData)).unwrap()
      .then((resp: GetDetailResponse) => {
        if (resp.statusCode === 200) {
          dispatch(setIsEdit(true));
          navigate(`/${currentLanguage.lang}/submissions/search/form/step1`);
        } else {
          navigate(`/${currentLanguage.lang}/error-page`);
        }
      })
      .catch((error: ErrorInfo) => {
        navigate(`/${currentLanguage.lang}/error-page`);
      });
  };

  const handleConsult = (card: SubmissionsSearchCards) => {
    dispatch(setCurrentCard(card));
    dispatch(setLoading(true));
    const requestData: GetDetailRequestData = {
      turnOverId: card.turnOverId,
      isManagement: !isEmployees
    };
    dispatch(getDetail(requestData)).unwrap()
      .then((resp: GetDetailResponse) => {
        if (resp.statusCode === 200) {
          dispatch(setIsEdit(true));
          navigate(`/${currentLanguage.lang}/submissions/search/consult`);
        } else {
          navigate(`/${currentLanguage.lang}/error-page`);
        }
      })
      .catch((error: ErrorInfo) => {
        navigate(`/${currentLanguage.lang}/error-page`);
      });
  };

  if (isInitialLoading) {
    return (
      <div className="submissions-body-container"></div>
    );
  } else {
    return (
      <div className="submissions-body-container">
        {
          !isSearchBarHidden &&
          <SearchBar placeholder={t("submissions.searchPlaceholder")} value={searchValue} handleValue={handleChangeSearch} handleClear={handleClearSearch} />
        }
        <div className="submissions-search-results">
          <div className="submissions-search-results-wrapper">
            <div className="submissions-results-number-wrapper">
              {
                resultsNumber > 6 &&
                <div className="submissions-results-number">
                  <span className="submissions-results-number-label">{t("submissions.resultsNumber.see")}</span>
                  <Dropdown isOpen={isEntriesNumberOpen} toggle={handleToggleDropdown} direction="down">
                      <DropdownToggle caret>{entriesNumberSelected.description}</DropdownToggle>
                      <DropdownMenu>
                          {
                            entriesNumber.map((option: DropdownSelectors, index: number) => {
                              return <DropdownItem key={index} onClick={() => handleChangeNumberResults(option)} className={option.id === entriesNumberSelected.id ? 'selected-option' : ''}>
                                {option.description}
                              </DropdownItem>
                            })
                          }
                      </DropdownMenu>
                  </Dropdown>
                  <span className="submissions-results-number-label">{t("submissions.resultsNumber.entries")}</span>
                </div>
              }
              <span className="submissions-results-found">
                {                 
                  !isResultsNumberHidden &&
                  (`${resultsNumber > 0 ? (resultsNumber + ' ' + t("submissions.resultsNumber.resultsFound")) : t("submissions.resultsNumber.noResultsFound")}`)
                }
              </span>
            </div>
            <div className="submissions-search-cards">
              {
                cards.map((card: SubmissionsSearchCards, index: number) => (
                  <ResultCard
                    key={index}
                    cardData={card}
                    handleFillIn={handleFillIn}
                    handleEdit={handleEdit}
                    handleConsult={handleConsult}/>
                ))
              }
            </div>
          </div>
          {
            resultsNumber > 6 &&
            <div className='pagination-wrapper'>
              <span className='pagination-counter'>
                {
                  (`${(entriesNumberSelected.id * currentPage) - entriesNumberSelected.id + 1} ${t("submissions.resultsNumber.to")} `) +
                  (`${(entriesNumberSelected.id * currentPage) > resultsNumber ? resultsNumber : entriesNumberSelected.id * currentPage} ${t("submissions.resultsNumber.of")} `) +
                  (`${resultsNumber} ${t("submissions.resultsNumber.entries")}`)
                }
              </span>
              <div className='pagination-container'>
                  <button className='btn-pagination btn-previous-page' disabled={currentPage === 1} onClick={() => handlePreviousPage()}>
                    <span>«</span>
                  </button>
                  {
                    visiblePagesArray[0] !== 1 &&
                    <button
                      className="btn-pagination btn-unselected-page btn-furthest"
                      onClick={() => handleChangePage(1)}>
                      {1}
                    </button>
                  }
                  {
                    visiblePagesArray.map((pageNumber: number) => (
                      <button
                        key={pageNumber}
                        className={'btn-pagination btn-unselected-page' + (currentPage === pageNumber ? ' btn-active' : '')}
                        onClick={() => handleChangePage(pageNumber)}>
                        {pageNumber}
                      </button>
                    ))
                  }
                  {
                    visiblePagesArray[visiblePagesArray.length - 1] !== totalPages &&
                    <button
                      className="btn-pagination btn-unselected-page btn-furthest"
                      onClick={() => handleChangePage(totalPages)}>
                      {totalPages}
                    </button>
                  }
                  <button className='btn-pagination btn-next-page' disabled={currentPage === totalPages} onClick={() => handleNextPage()}>
                    <span>»</span>
                  </button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default SubmissionsSearch;