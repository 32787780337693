import { UncontrolledTooltip } from "reactstrap";
import './TooltipBox.scss';

interface TooltipData {
    value: string,
    target: string,
    placement: any
}


const TooltipBox = ({ value, target, placement }: TooltipData) => {
    return (
        <div className="tooltip-container">
            {placement === 'top' &&
                <UncontrolledTooltip
                    className="tooltip-text-container tooltip-top"
                    placement={placement}
                    target={target}
                    trigger="hover click"
                >{value}
                </UncontrolledTooltip>
            }

            {placement === 'right' &&
                <UncontrolledTooltip
                    className="tooltip-text-container tooltip-right"
                    placement={placement}
                    target={target}
                >{value}
                </UncontrolledTooltip>
            }
        </div>
    );
}

export default TooltipBox;