import "./DataCard.scss";
import { useTranslation } from "react-i18next";
import { ChartVariationNegative, ChartVariationNeutral, ChartVariationPositive, MinusCircleFill, NeutralCircleFill, PlusCircleFill, NoVarianceCircleFill } from "../../images/icons";
import TooltipBox from "../tooltip/TooltipBox";
import { getFormatedPercentageVariance } from "../../global/Utils";

interface DataCardData {
    id?: string,
    title?: string | number,
    subtitle?: string,
    icon?: React.ReactNode | null,
    variationData?: number | null,
    handleClick?: any
    isManagement?: boolean
    is90Days?: boolean
    hasVariation?: boolean,
    hasEvolutionTable?: boolean
}


const DataCard = ({ id, title, subtitle, variationData = null, icon = null, handleClick = null, isManagement, is90Days, hasVariation = false, hasEvolutionTable = false }: DataCardData) => {
    const { t } = useTranslation();

    if (icon) {
        return (
            <div className="data-card-container">
                <div className="data-card-icon">
                    {icon}
                </div>
                <div className="data-card-content">
                    <div className="data-card-header">
                        <div className="data-card-title">
                            {title}
                        </div>
                    </div>
                    <div className="data-card-subtitle">
                        {subtitle}
                    </div>
                </div>
            </div>
        );
    } else if (hasVariation && variationData !== null && hasEvolutionTable) {
        if (variationData === 0) {
            return (
                <div className={`data-card-container ${!isManagement ? 'neutral-variation' : ''}`}>
                    <div className="data-card-content">
                        <div className="data-card-header">
                            <div className="data-card-title">
                                {title}
                            </div>
                            {!isManagement &&
                                <div className='data-card-variation neutral-variation'>
                                    <div className='data-card-variation-value'>
                                        <ChartVariationNeutral />
                                        <span className="variation-value">{getFormatedPercentageVariance(variationData?.valueOf())}%</span>
                                    </div>

                                    {handleClick &&
                                        <div className="btn-modal-container">
                                            <TooltipBox value={t("dashboard.turnOver.dataCardLabels.buttonVariationTooltip")} target={'tooltip-evolution-' + id} placement="top" />
                                            <button className="btn-modal-trigger" id={'tooltip-evolution-' + id} onClick={() => handleClick(isManagement, is90Days)}>
                                                <NeutralCircleFill />
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className="data-card-subtitle">
                            {subtitle}
                        </div>
                    </div>
                </div>
            );
        } else if (variationData && variationData < 0) {
            return (
                <div className={`data-card-container ${!isManagement ? 'negative-variation' : ''}`}>
                    <div className="data-card-content">
                        <div className="data-card-header">
                            <div className="data-card-title">
                                {title}
                            </div>
                            {!isManagement &&
                                <div className='data-card-variation negative-variation'>
                                    <div className='data-card-variation-value'>
                                        <ChartVariationNegative />
                                        <span className="variation-value">{getFormatedPercentageVariance(variationData?.valueOf())}%</span>
                                    </div>
                                    {
                                        handleClick &&
                                        <div className="btn-modal-container">
                                            <TooltipBox value={t("dashboard.turnOver.dataCardLabels.buttonVariationTooltip")} target={'tooltip-evolution-' + id} placement="top" />
                                            <button className="btn-modal-trigger" id={'tooltip-evolution-' + id} onClick={() => handleClick(isManagement, is90Days)}>
                                                <MinusCircleFill />
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className="data-card-subtitle">
                            {subtitle}
                        </div>
                    </div>
                </div>
            );
        } else if (variationData && variationData > 0) {
            return (
                <div className={`data-card-container ${!isManagement ? 'positive-variation' : ''}`}>
                    <div className="data-card-content">
                        <div className="data-card-header">
                            <div className="data-card-title">
                                {title}
                            </div>
                            {!isManagement &&
                                <div className='data-card-variation positive-variation'>
                                    <div className='data-card-variation-value'>
                                        <ChartVariationPositive />
                                        <span className="variation-value">+{getFormatedPercentageVariance(variationData?.valueOf())}%</span>
                                    </div>
                                    {
                                        handleClick &&
                                        <div className="btn-modal-container">
                                            <TooltipBox value={t("dashboard.turnOver.dataCardLabels.buttonVariationTooltip")} target={'tooltip-evolution-' + id} placement="top" />
                                            <button className="btn-modal-trigger" id={'tooltip-evolution-' + id} onClick={() => handleClick(isManagement, is90Days)}>
                                                <PlusCircleFill />
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <div className="data-card-subtitle">
                            {subtitle}
                        </div>
                    </div>
                </div>
            );
        }
    } else if (!hasVariation && variationData === null && hasEvolutionTable) {
        return (
            <div className="data-card-container no-variation">
                <div className="data-card-content">
                    <div className="data-card-header">
                        <div className="data-card-title">
                            {title}
                        </div>
                        <div className='data-card-variation no-variation'>
                            {
                                handleClick &&
                                <div className="btn-modal-container">
                                    <TooltipBox value={t("dashboard.turnOver.dataCardLabels.buttonNoVariationTooltip")} target={'tooltip-evolution-' + id} placement="top" />
                                    <button className="btn-modal-trigger" id={'tooltip-evolution-' + id} onClick={() => handleClick(isManagement, is90Days)}>
                                        <NoVarianceCircleFill />
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="data-card-subtitle">
                        {subtitle}
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="data-card-container">
                <div className="data-card-content">
                    <div className="data-card-header">
                        <div className="data-card-title">
                            {title}
                        </div>
                    </div>
                    <div className="data-card-subtitle">
                        {subtitle}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="data-card-container">
        </div>
    );
};

export default DataCard;