import { createSlice } from "@reduxjs/toolkit";

import { langPt, langEn } from "../../global/Variables";

const initialState = {
    isOpen: false,
    currentLanguage:  {
        label: 'PT',
        lang: langPt
    },
    languages: [
        {
            label: 'PT',
            lang: langPt
        },
        {
            label: 'EN',
            lang: langEn
        }
    ]
};

const dropdownLanguagesSlice = createSlice({
    name: 'dropdownLanguages',
    initialState,
    reducers: {
        toggleOpen: (state) => {
            state.isOpen = !state.isOpen;
        },
        changeLanguage: (state, action) => {
            state.currentLanguage = action.payload;
        }
    }
});

export const { toggleOpen, changeLanguage } = dropdownLanguagesSlice.actions;

export default dropdownLanguagesSlice.reducer;