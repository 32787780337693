import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    filtersComponent: ''
};

const offCanvasFilterSlice = createSlice({
    name: 'offCanvasFilter',
    initialState,
    reducers: {
        closeFilters: (state) => {
            state.isOpen = false;
        },
        openFilters: (state, action) => {
            state.filtersComponent = action.payload;
            state.isOpen = true;
        }
    }
});

export const { closeFilters, openFilters } = offCanvasFilterSlice.actions;

export default offCanvasFilterSlice.reducer;