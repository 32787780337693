import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { getEvolutionTable, modalToggleSwitch, toggleModalGrid } from '../../features/dashboard/DashboardSlice';
import { setLoading } from '../../features/main/MainSlice';

import { getFormatedPercentageVariance } from '../../global/Utils';

import Switch from '../switch/Switch';

import { EvolutionTableData, EvolutionTableRequestData, GetEvolutionTableResponse } from '../../models/DashboardModels';
import { ErrorInfo } from '../../models/GlobalModels';
import './ModalGrid.scss';

const ModalGrid = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { modalGrid } = useAppSelector((store) => store.dashboard);
    const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);

    const handleToggleOpen = () => {
        dispatch(toggleModalGrid());
    };

    const handleModalSwitch = (isChecked: boolean) => {
        dispatch(modalToggleSwitch(isChecked));
        const requestData: EvolutionTableRequestData = {
            isManagement: modalGrid.isManagement,
            is90Days: modalGrid.is90Days,
            isLastMonth: isChecked
        };
        dispatch(setLoading(true));
        dispatch(getEvolutionTable(requestData)).unwrap()
            .then((resp: GetEvolutionTableResponse) => {
                if (resp.statusCode === 200) {
                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    };

    const setCellRowValues = (cellRowValue: any, isPercentage: boolean) => {
        if (isPercentage) {
            return getFormatedPercentageVariance(cellRowValue?.valueOf());
        } else {
            return cellRowValue;
        }

    };

    const tableSmallDashboard = useRef<HTMLElement>(null);
    const tableHeader = useRef<HTMLTableSectionElement>(null);

    useEffect(() => {
        setTimeout(() => {
            const tableElement = tableSmallDashboard?.current?.firstElementChild;
            const tableHeaderRow = tableHeader?.current;
            if (modalGrid.isOpen) {
                tableElement?.addEventListener('scroll', () => {
                    if (tableElement?.scrollTop > 0) {
                        tableHeaderRow?.classList.add('header-box-shadow');
                    } else {
                        tableHeaderRow?.classList.remove('header-box-shadow');
                    }
                });
            }
        }, 0);
    }, [modalGrid.isOpen]);

    return (
        <div className="modal-grid-container">
            <Modal isOpen={modalGrid.isOpen} toggle={handleToggleOpen} centered={true} className="modal-grid-wrapper">
                <ModalHeader toggle={handleToggleOpen}>
                    {t("dashboard.turnOver.modals.tableEvolutionLabels.modalTitle")}
                </ModalHeader>
                <ModalBody>
                    {modalGrid.isLastMonth !== null &&
                        <>
                            <Switch labels={['Ano Homólogo', 'Mês Anterior']} name="switchModalTableEvolution" value={modalGrid.isLastMonth} onChangeHandle={handleModalSwitch} />
                            <div className='dashboard-modal-table-evolution' ref={tableSmallDashboard as any}>
                                <Table responsive>
                                    <thead id="tableHeader" ref={tableHeader}>
                                        <tr>
                                            <th>{t("dashboard.turnOver.modals.tableEvolutionLabels.restaurant")}</th>
                                            {modalGrid.isLastMonth ?
                                                <th>
                                                    {
                                                        t("dashboard.turnOver.modals.tableEvolutionLabels.tn") +
                                                        ' ' +
                                                        (modalGrid.is90Days ? t("dashboard.turnOver.modals.tableEvolutionLabels.tn90Days") + ' ' : '') +
                                                        t("dashboard.turnOver.modals.tableEvolutionLabels.tnPreviousMonth")
                                                    }
                                                </th> :
                                                <th>
                                                    {
                                                        t("dashboard.turnOver.modals.tableEvolutionLabels.tn") +
                                                        ' ' +
                                                        (modalGrid.is90Days ? t("dashboard.turnOver.modals.tableEvolutionLabels.tn90Days") + ' ' : '') +
                                                        t("dashboard.turnOver.modals.tableEvolutionLabels.tnHomologousYear")
                                                    }
                                                </th>
                                            }
                                            <th>
                                                {
                                                    t("dashboard.turnOver.modals.tableEvolutionLabels.tn") +
                                                    (modalGrid.is90Days ? ' ' + t("dashboard.turnOver.modals.tableEvolutionLabels.tn90Days") : '')
                                                }
                                            </th>
                                            <th>{t("dashboard.turnOver.modals.tableEvolutionLabels.evolution")}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {modalGrid.data?.map((cellRow: EvolutionTableData, row: number) => {
                                            return (
                                                <tr key={row}>
                                                    <td>{setCellRowValues(cellRow.restaurantName, false)}</td>
                                                    <td>{setCellRowValues(cellRow.turnOverSelectedPercentage, true)}%</td>
                                                    <td>{setCellRowValues(cellRow.turnOverPercentage, true)}%</td>
                                                    <td className={'small-table-highlight-cell-container' +
                                                        (cellRow.evolution === 0 ? ' darkgrey-text-cell' : '') +
                                                        (cellRow.evolution < 0 ? ' green-text-cell' : '') +
                                                        (cellRow.evolution > 0 ? ' red-text-cell' : '')}>
                                                        {(cellRow.evolution > 0 ? '+' : '') + setCellRowValues(cellRow.evolution, true)}%
                                                    </td>
                                                </tr>
                                            );

                                        })}

                                    </tbody>
                                </Table>
                            </div>
                        </>}
                </ModalBody>
            </Modal>
        </div >
    );
}

export default ModalGrid;