import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Collapse, Nav, NavItem } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { toggleOpenConfirm } from '../../features/modal-confirm/ModalConfirmSlice';
import { AdminIcon, DashboardIconNav, PencilIcon, SignOutIcon, SupportIcon } from '../../images/icons';
import { AuthorizationMenu } from '../../models/AuthenticationModels';
import './NavMenu.scss';

const NavMenu = () => {
    const { isOpen } = useAppSelector((store) => store.navMenu);
    const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
    const { menu } = useAppSelector((store) => store.authentication);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleSignOut = () => {
        dispatch(toggleOpenConfirm());
    };

    return (
        <nav className="nav-menu-container">
            <Collapse isOpen={isOpen} horizontal>
                <Nav>
                    {
                        menu.map((menu: AuthorizationMenu) => {
                        if (menu.menuId === 1 && menu.visible) {
                            return (
                                <NavItem key={'dashboard'}>
                                    <NavLink to={`/${currentLanguage.lang}/dashboard`}>
                                        <DashboardIconNav />
                                       <span>{t("dashboard.menuLabel")}</span>
                                    </NavLink>
                                </NavItem>
                            );
                        } else if (menu.menuId === 2 && menu.visible) {
                            return (
                                <NavItem key={'support'}>
                                    <NavLink to={`/${currentLanguage.lang}/support`}>
                                        <SupportIcon />
                                        <span>{t("support.menuLabel")}</span>
                                    </NavLink>
                                </NavItem>
                            );
                        } else if (menu.menuId === 3 && menu.visible) {
                            return (
                                <NavItem key={'administration'}>
                                    <NavLink to={`/${currentLanguage.lang}/administration`}>
                                        <AdminIcon />
                                        <span>{t("administration.menuLabel")}</span>
                                    </NavLink>
                                </NavItem>
                            );
                        } else if (menu.menuId === 4 && menu.visible) {
                            return (
                                <NavItem key={'submissions'}>
                                    <NavLink to={`/${currentLanguage.lang}/submissions`}>
                                        <PencilIcon />
                                        <span>{t("submissions.menuLabel")}</span>
                                    </NavLink>
                                </NavItem>
                            );
                        } else {
                            return false;
                        }
                        })
                    }
                </Nav>
                <div className='sign-out' onClick={() => handleSignOut()}>
                    <SignOutIcon />
                    {t("common.signOut")}
                </div>
            </Collapse>
        </nav>
    );
}

export default NavMenu;