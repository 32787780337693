import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { toggleAllRows, toggleLargeGrid, toggleRow } from '../../features/dashboard/DashboardSlice';

import { formatCompare, monthsTranslationAlias, getFormatedPercentageVariance } from '../../global/Utils';
import { ChevronCompactUpIcon, ChevronCompactDownIcon, ChevronUpIcon } from '../../images/icons';
import { EvolutionRestaurantTable } from '../../models/DashboardModels';
import './ModalGridLarge.scss';


const ModalGrid = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { modalLargeGrid, filters } = useAppSelector((store) => store.dashboard);
    const { timeFilterType } = useAppSelector((store) => store.dashboard.filters.appliedFilters);

    const handleOpenAll = () => {
        dispatch(toggleAllRows());
    };

    const handleToggleOpen = () => {
        dispatch(toggleLargeGrid());
    };

    const handleToggleRow = (row: number) => {
        dispatch(toggleRow(row));
    };

    const setCellRowValues = (hasValues: boolean | null, cellRowValue: number, isPercentage: boolean) => {
        if (hasValues) {
            if (isPercentage) {
                return getFormatedPercentageVariance(cellRowValue?.valueOf());
            } else {
                return cellRowValue;
            }
        } else {
            return '-';
        }
    };

    const isMonthlyOrYtd = (filterType: string) => {
        if (filterType === "2" || filterType === "3") {
            return true;
        } else {
            return false;
        }
    }

    const setCellRowComparisonValues = (cellRowComparisonValue: number, hasValues: boolean | null) => {
        let compareValue = formatCompare(cellRowComparisonValue, hasValues);
        return (<span className="compare-cell">{compareValue}</span>)
    };

    const tableLargeDashboard = useRef<HTMLElement>(null);
    const tableHeader = useRef<HTMLTableSectionElement>(null);

    useEffect(() => {
        setTimeout(() => {
            const tableElement = tableLargeDashboard?.current?.firstElementChild;
            const tableHeaderRow = tableHeader?.current;
            if (modalLargeGrid.isOpen) {
                tableElement?.addEventListener('scroll', () => {
                    if (tableElement?.scrollTop > 0) {
                        tableHeaderRow?.classList.add('header-box-shadow');
                    } else {
                        tableHeaderRow?.classList.remove('header-box-shadow');
                    }
                });
            }
        }, 0);
    }, [modalLargeGrid.isOpen]);

    return (
        <div className="modal-grid-container">
            <Modal isOpen={modalLargeGrid.isOpen} toggle={handleToggleOpen} centered={true} className="modal-grid-wrapper modal-large" modalClassName="modal-ttm-large-table">
                <ModalHeader toggle={handleToggleOpen}>
                    <span className='modal-title-ttm-1'>
                        {
                            (timeFilterType === '1' ? t("dashboard.filters.ttmMonthlyYtdRadios.ttm") : '') +
                            (timeFilterType === '2' ? t("dashboard.filters.ttmMonthlyYtdRadios.monthly") : '') +
                            (timeFilterType === '3' ? t("dashboard.filters.ttmMonthlyYtdRadios.ytd") : '') +
                            ' ' +
                            (filters.appliedFilters.year ? filters.appliedFilters.year : '')
                        }
                    </span>
                    <span className='modal-title-ttm-2'>
                        {
                            (timeFilterType === '1' ? t("dashboard.filters.ttmMonthlyYtdRadios.ttm") : '') +
                            (timeFilterType === '2' ? t("dashboard.filters.ttmMonthlyYtdRadios.monthly") : '') +
                            (timeFilterType === '3' ? t("dashboard.filters.ttmMonthlyYtdRadios.ytd") : '') +
                            ' ' +
                            (filters.appliedFilters.year ? filters.appliedFilters.year - 1 : '')
                        }</span>
                </ModalHeader>
                <ModalBody>
                    <div className="dashboard-modal-table-ttm" ref={tableLargeDashboard as any}>
                        <Table responsive>
                            <thead id="tableHeader" ref={tableHeader}>
                                <tr>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.numRestaurant")}
                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.restaurant")}
                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.year")}

                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.month")}

                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.actives")}

                                        <br />
                                        <span className="large-table-sub-header">
                                            {t("dashboard.turnOver.modals.tableLargeTtmLabels.employees")}

                                        </span>
                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.actives")}
                                        <br />
                                        <span className="large-table-sub-header">
                                            {t("dashboard.turnOver.modals.tableLargeTtmLabels.manager")}

                                        </span>
                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.exits")}
                                        <br />
                                        <span className="large-table-sub-header">
                                            {t("dashboard.turnOver.modals.tableLargeTtmLabels.employees")}
                                        </span>
                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.exits")}
                                        <br />
                                        <span className="large-table-sub-header">
                                            {t("dashboard.turnOver.modals.tableLargeTtmLabels.manager")}

                                        </span>
                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.tnMonthly")}
                                        <br />
                                        <span className="large-table-sub-header">
                                            {t("dashboard.turnOver.modals.tableLargeTtmLabels.90daysEmployees")}
                                        </span>
                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.tnMonthly")}
                                        <br />
                                        <span className="large-table-sub-header">
                                            {t("dashboard.turnOver.modals.tableLargeTtmLabels.90daysManager")}
                                        </span>
                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.tnMonthly")}
                                        <br />
                                        <span className="large-table-sub-header">
                                            {t("dashboard.turnOver.modals.tableLargeTtmLabels.employees")}
                                        </span>
                                    </th>
                                    <th>
                                        {t("dashboard.turnOver.modals.tableLargeTtmLabels.tnMonthly")}
                                        <br />
                                        <span className="large-table-sub-header">
                                            {t("dashboard.turnOver.modals.tableLargeTtmLabels.manager")}
                                        </span>
                                    </th>
                                    <th>
                                        <span className="large-table-header-open-all" onClick={() => handleOpenAll()}>
                                            {t("dashboard.turnOver.modals.tableLargeTtmLabels.evolution")}
                                            {
                                                modalLargeGrid.areAllComparesOpen ?
                                                    <ChevronCompactUpIcon /> :
                                                    <ChevronCompactDownIcon />
                                            }
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {modalLargeGrid.data.map((cellRow: EvolutionRestaurantTable, row: number) => {
                                    return (row % 2 !== 0 ?
                                        <tr
                                            key={row}
                                            className={
                                                "large-table-compare-row" +
                                                (modalLargeGrid.isCompareOpen[row] ? " large-table-compare-open" : "") +
                                                (row === modalLargeGrid.data.length - 1 ? " large-table-compare-totals" : "")
                                            }>
                                            <td>{row !== modalLargeGrid.data.length - 1 ? "" : t("dashboard.turnOver.modals.tableLargeTtmLabels.totals")}</td>
                                            <td></td>
                                            <td>{row !== modalLargeGrid.data.length - 1 ? cellRow.year : ""}</td>
                                            <td>{row !== modalLargeGrid.data.length - 1 ? t("common.months." + monthsTranslationAlias[cellRow.month - 1]) : ""}</td>
                                            <td>
                                                {setCellRowValues(cellRow.hasValues, cellRow.activesEmployee, false)}
                                                {setCellRowComparisonValues(cellRow.activesEmployeeDiff, cellRow.hasValues)}
                                            </td>
                                            <td>
                                                {setCellRowValues(cellRow.hasValues, cellRow.activesManager, false)}
                                                {setCellRowComparisonValues(cellRow.activesManagerDiff, cellRow.hasValues)}
                                            </td>
                                            <td>
                                                {setCellRowValues(cellRow.hasValues, cellRow.exitsEmployee, false)}
                                                {setCellRowComparisonValues(cellRow.exitsEmployeeDiff, cellRow.hasValues)}
                                            </td>
                                            <td>
                                                {setCellRowValues(cellRow.hasValues, cellRow.exitsManager, false)}
                                                {setCellRowComparisonValues(cellRow.exitsManagerDiff, cellRow.hasValues)}
                                            </td>
                                            <td>
                                                {(() => {
                                                    if (isMonthlyOrYtd(timeFilterType)) {
                                                        return (
                                                            "-"
                                                        )
                                                    } else {
                                                        setCellRowValues(cellRow.hasValues, cellRow.turnOver90DaysEmployeePercentage, true);
                                                        setCellRowComparisonValues(cellRow.turnOver90DaysEmployeePercentageDiff, cellRow.hasValues);
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                            {(() => {
                                                    if (isMonthlyOrYtd(timeFilterType)) {
                                                        return (
                                                            "-"
                                                        )
                                                    } else {
                                                        setCellRowValues(cellRow.hasValues, cellRow.turnOver90DaysManagerPercentage, true);
                                                        setCellRowComparisonValues(cellRow.turnOver90DaysManagerPercentageDiff, cellRow.hasValues);
                                                    }
                                                })()}
                                            </td>
                                            <td>
                                                {setCellRowValues(cellRow.hasValues, cellRow.turnOverEmployeePercentage, true)}
                                                {setCellRowComparisonValues(cellRow.turnOverEmployeePercentageDiff, cellRow.hasValues)}
                                            </td>
                                            <td>
                                                {setCellRowValues(cellRow.hasValues, cellRow.turnOverManagerPercentage, true)}
                                                {setCellRowComparisonValues(cellRow.turnOverManagerPercentageDiff, cellRow.hasValues)}
                                            </td>
                                            <td>
                                                <div
                                                    className="large-table-compare-column"
                                                    onClick={() => handleToggleRow(row)}>
                                                    <ChevronUpIcon />
                                                </div>
                                            </td>
                                        </tr> :
                                        <tr
                                            key={row}
                                            className={"large-table-row" + (row === modalLargeGrid.data.length - 2 ? " large-table-totals" : "")}>
                                            <td>{row !== modalLargeGrid.data.length - 2 ? cellRow.restaurantNumber : t("dashboard.turnOver.modals.tableLargeTtmLabels.totals")}</td>
                                            <td>{row !== modalLargeGrid.data.length - 2 ? cellRow.restaurantName : ""}</td>
                                            <td>{row !== modalLargeGrid.data.length - 2 ? cellRow.year : ""}</td>
                                            <td>{row !== modalLargeGrid.data.length - 2 ? t("common.months." + monthsTranslationAlias[cellRow.month - 1]) : ""}</td>
                                            <td>{setCellRowValues(cellRow.hasValues, cellRow.activesEmployee, false)}</td>
                                            <td>{setCellRowValues(cellRow.hasValues, cellRow.activesManager, false)}</td>
                                            <td>{setCellRowValues(cellRow.hasValues, cellRow.exitsEmployee, false)}</td>
                                            <td>{setCellRowValues(cellRow.hasValues, cellRow.exitsManager, false)}</td>
                                            <td>{isMonthlyOrYtd(timeFilterType) ? "-" : setCellRowValues(cellRow.hasValues, cellRow.turnOver90DaysEmployeePercentage, true)}</td>
                                            <td>{isMonthlyOrYtd(timeFilterType) ? "-" : setCellRowValues(cellRow.hasValues, cellRow.turnOver90DaysManagerPercentage, true)}</td>
                                            <td>{setCellRowValues(cellRow.hasValues, cellRow.turnOverEmployeePercentage, true)}</td>
                                            <td>{setCellRowValues(cellRow.hasValues, cellRow.turnOverManagerPercentage, true)}</td>
                                            <td>
                                                <div
                                                    className={"large-table-compare-column" + (modalLargeGrid.isCompareOpen[row + 1] ? " large-table-compare-column-open" : "")}
                                                    onClick={() => handleToggleRow(row + 1)}>
                                                    {t("dashboard.turnOver.modals.tableLargeTtmLabels.evolution")}
                                                    {!modalLargeGrid.isCompareOpen[row + 1] &&
                                                        <ChevronCompactDownIcon />
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalGrid;