import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import { useNavigate } from 'react-router-dom';

import Dropdown from '../../../private/dropdown/Normal/DropDown';
import OffCanvasFilterFooter from '../../off-canvas-filter/off-canvas-filter-footer/OffCanvasFilterFooter';
import DropdownCheckbox from '../../../private/dropdown/WithCheckBox/DropdownWithCheckbox';
import RadioButtons from '../../../private/radio-buttons/RadioButtons';

import {
    checkTtmMonthlyYtd,
    getFilters,
    postInitialInfo,
    setAppliedFilters,
    setCustomDropdownHeader,
    setDefaultDropdownHeader,
    toggleIsFiltersOpens,
} from '../../../features/dashboard/DashboardSlice';
import { changeValue } from '../../../features/dropdown/dropdownSlice';
import { clearValue, updateTotalArray } from '../../../features/dropdownWithCheckbox/DropdownCheckboxSlice';
import { setLoading } from '../../../features/main/MainSlice';
import { toggleModalError } from '../../../features/modal-error/ModalErrorSlice';
import { closeFilters } from '../../../features/off-canvas-filter/OffCanvasFilterSlice';

import { GetFiltersResponse, PostInitialInfoRequestData, PostInitialInfoResponse } from '../../../models/DashboardModels';
import { dashboardFiltersStoreIds } from '../../../global/Variables';
import { ErrorInfo } from '../../../models/GlobalModels';
import { getSelectedCheckbox } from '../../../global/Utils';

const DashboardFilters = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { filters } = useAppSelector((store) => store.dashboard);
    const { currentOption } = useAppSelector((store) => store.dashboard.dropdownHeader);
    const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
    const { appliedFiltersDropdownHeader } = useAppSelector((store) => store.dashboard);
    const selectedYear = useAppSelector((store) => store.dropdown.entities[dashboardFiltersStoreIds.year]?.value?.id);
    const selectedMonth = useAppSelector((store) => store.dropdown.entities[dashboardFiltersStoreIds.month]?.value);
    const selectedTtmMonthlyYtd = useAppSelector((store) => store.dashboard.filters.ttmMonthlyYtdValue);
    const franchiseesFilter = useAppSelector((store) => store.dropdowncheckbox.entities[dashboardFiltersStoreIds.franchisees]);
    const restaurantsFilter = useAppSelector((store) => store.dropdowncheckbox.entities[dashboardFiltersStoreIds.restaurants]);
    const consultantsOpsFilter = useAppSelector((store) => store.dropdowncheckbox.entities[dashboardFiltersStoreIds.consultantsOps]);
    const regionFilter = useAppSelector((store) => store.dropdowncheckbox.entities[dashboardFiltersStoreIds.region]);
    const typologyFilter = useAppSelector((store) => store.dropdowncheckbox.entities[dashboardFiltersStoreIds.typology]);
	const { cardsInfo } = useAppSelector((store) => store.dashboard);
	const { appliedFilters } = useAppSelector((store) => store.dashboard.filters);

    const handleClearFilters = () => {
        const currentMonth = new Date().getMonth();
        dispatch(changeValue({
            id: dashboardFiltersStoreIds.month,
            value: filters.month[currentMonth === 0 ? 11 : currentMonth - 1]
        }));
        dispatch(changeValue({
            id: dashboardFiltersStoreIds.year,
            value: currentMonth === 0 ? filters.year[1] : filters.year[0]
        }));
        dispatch(checkTtmMonthlyYtd(filters.ttmMonthlyYtdRadios[0].value));
        dispatch(clearValue([
            dashboardFiltersStoreIds.franchisees,
            dashboardFiltersStoreIds.restaurants,
            dashboardFiltersStoreIds.consultantsOps,
            dashboardFiltersStoreIds.region,
            dashboardFiltersStoreIds.typology
        ]));

        dispatch(setAppliedFilters({
            year: currentMonth === 0 ? filters.year[1].id  : filters.year[0].id,
            month: filters.month[currentMonth === 0 ? 11 : currentMonth - 1],
            ttmMonthlyYtdValue: filters.ttmMonthlyYtdRadios[0].value,
            franchiseeIds: [],
            storeIds: [],
            consultantsIds: [],
            regionIds: [],
            tipologyIds: []
        }));


        const postInitialInfoRequestData: PostInitialInfoRequestData = {
            isResetFilters: true,
            dashboardViewType: null
        };
        dispatch(postInitialInfo(postInitialInfoRequestData)).unwrap()
            .then((resp: PostInitialInfoResponse) => {
            if (
                resp.cardsAndWhenChart.statusCode === 200 &&
                resp.whereChartByMarket.statusCode === 200 &&
                resp.whereChartByType.statusCode === 200 &&
                resp.filters.statusCode === 200 &&
                resp.whyChartLess90Days.statusCode === 200 &&
                resp.whyChartMore90Days.statusCode === 200
            ) {
                dispatch(setDefaultDropdownHeader(resp.cardsAndWhenChart.data.dashboardViews[0]));

                let franchisees = resp.filters.data.franchisees;
                if (franchisees.length > 1) {
                    franchisees.unshift({
                        id: null,
                        description: t("dashboard.filters.franchiseesSelectAll")
                    });
                }
                dispatch(updateTotalArray(
                    {
                        storeId: dashboardFiltersStoreIds.franchisees,
                        totalArray: franchisees
                    }
                ));

                let restaurants = resp.filters.data.stores;
                if (restaurants.length > 1) {
                    restaurants.unshift({
                        id: null,
                        description: t("dashboard.filters.restaurantsSelectAll")
                    });
                }
                dispatch(updateTotalArray(
                    {
                        storeId: dashboardFiltersStoreIds.restaurants,
                        totalArray: restaurants
                    }
                ));

                let consultantsOps = resp.filters.data.consultants;
                if (consultantsOps.length > 1) {
                    consultantsOps.unshift({
                        id: null,
                        description: t("dashboard.filters.consultantsOpsSelectAll")
                    });
                }
                dispatch(updateTotalArray(
                    {
                        storeId: dashboardFiltersStoreIds.consultantsOps,
                        totalArray: consultantsOps
                    }
                ));

                let region = resp.filters.data.markets;
                if (region.length > 1) {
                    region.unshift({
                        id: null,
                        description: t("dashboard.filters.regionSelectAll")
                    });
                }
                dispatch(updateTotalArray(
                    {
                        storeId: dashboardFiltersStoreIds.region,
                        totalArray: region
                    }
                ));

                let typology = resp.filters.data.storeTypes;
                if (typology.length > 1) {
                    typology.unshift({
                        id: null,
                        description: t("dashboard.filters.typologySelectAll")
                    });
                }
                dispatch(updateTotalArray(
                    {
                        storeId: dashboardFiltersStoreIds.typology,
                        totalArray: typology
                    }
                ));

                dispatch(setLoading(false));
            } else {
                navigate(`/${currentLanguage.lang}/error-page`);
            }
            })
            .catch((error: ErrorInfo) => {
            navigate(`/${currentLanguage.lang}/error-page`);
            });
    };

    const handleApplyFilters = () => {
        dispatch(setLoading(true));
        let hasFiltersApplied = false;
        if (
            (franchiseesFilter?.value && franchiseesFilter?.value.length > 0) ||
            (restaurantsFilter?.value && restaurantsFilter?.value.length > 0) ||
            (consultantsOpsFilter?.value && consultantsOpsFilter?.value.length > 0) ||
            (regionFilter?.value && regionFilter?.value.length > 0) ||
            (typologyFilter?.value && typologyFilter?.value.length > 0)
        ) {
            hasFiltersApplied = true;
        }

        dispatch(setAppliedFilters({
            year: selectedYear ? selectedYear : null,
            month: selectedMonth ? selectedMonth : null,
            ttmMonthlyYtdValue: selectedTtmMonthlyYtd,
            franchiseeIds: getSelectedCheckbox(franchiseesFilter?.value),
            storeIds: getSelectedCheckbox(restaurantsFilter?.value),
            consultantsIds: getSelectedCheckbox(consultantsOpsFilter?.value),
            regionIds: getSelectedCheckbox(regionFilter?.value),
            tipologyIds: getSelectedCheckbox(typologyFilter?.value)
        }));

        const postInitialInfoRequestData: PostInitialInfoRequestData = {
            isResetFilters: false,
            dashboardViewType: hasFiltersApplied ? appliedFiltersDropdownHeader.id : (currentOption?.id === appliedFiltersDropdownHeader.id ? null : currentOption?.id) || null
        };
        dispatch(postInitialInfo(postInitialInfoRequestData)).unwrap()
        .then((resp: PostInitialInfoResponse) => {
                if (
                    resp.cardsAndWhenChart.statusCode === 200 &&
                    resp.whereChartByMarket.statusCode === 200 &&
                    resp.whereChartByType.statusCode === 200 &&
                    resp.filters.statusCode === 200 &&
                    resp.whyChartLess90Days.statusCode === 200 &&
                    resp.whyChartMore90Days.statusCode === 200
                ) {
                    if (hasFiltersApplied) {
                        dispatch(setCustomDropdownHeader());
                    } else if (currentOption?.id === appliedFiltersDropdownHeader.id) {
                        dispatch(setDefaultDropdownHeader(
                            cardsInfo?.dashboardViews[0]
                        ));
                    }
                    dispatch(closeFilters());
                    dispatch(toggleIsFiltersOpens(false));
                    dispatch(setLoading(false));
                } else {
                    dispatch(toggleModalError());
                }
                dispatch(setLoading(false));
            })
            .catch((error: ErrorInfo) => {
                dispatch(toggleModalError());
                dispatch(setLoading(false));
            });
    };

    const loadFranchisees =  (resp: GetFiltersResponse) => {
        let franchisees = resp.data.franchisees;
        if (franchisees.length > 1) {
            franchisees.unshift({
                id: null,
                description: t("dashboard.filters.franchiseesSelectAll")
            });
        }
        dispatch(updateTotalArray(
            {
                storeId: dashboardFiltersStoreIds.franchisees,
                totalArray: franchisees
            }
        ));
    };

    const loadStores =  (resp: GetFiltersResponse) => {
        let restaurants = resp.data.stores;
        if (restaurants.length > 1) {
            restaurants.unshift({
                id: null,
                description: t("dashboard.filters.restaurantsSelectAll")
            });
        }
        dispatch(updateTotalArray(
            {
                storeId: dashboardFiltersStoreIds.restaurants,
                totalArray: restaurants
            }
        ));
    };

    const loadConsultants =  (resp: GetFiltersResponse) => {
        let consultantsOps = resp.data.consultants;
        if (consultantsOps.length > 1) {
            consultantsOps.unshift({
                id: null,
                description: t("dashboard.filters.consultantsOpsSelectAll")
            });
        }
        dispatch(updateTotalArray(
            {
                storeId: dashboardFiltersStoreIds.consultantsOps,
                totalArray: consultantsOps
            }
        ));
    };
    
    const loadRegions =  (resp: GetFiltersResponse) => {
        let region = resp.data.markets;
                    if (region.length > 1) {
                        region.unshift({
                            id: null,
                            description: t("dashboard.filters.regionSelectAll")
                        });
                    }
                    dispatch(updateTotalArray(
                        {
                            storeId: dashboardFiltersStoreIds.region,
                            totalArray: region
                        }
                    ));
    };
    
    const loadTypologies =  (resp: GetFiltersResponse) => {
        let typology = resp.data.storeTypes;
        if (typology.length > 1) {
            typology.unshift({
                id: null,
                description: t("dashboard.filters.typologySelectAll")
            });
        }
        dispatch(updateTotalArray(
            {
                storeId: dashboardFiltersStoreIds.typology,
                totalArray: typology
            }
        ));
    };

    const handleUpdateDropdownFranchisees = () => {
        dispatch(setLoading(true));
        dispatch(getFilters(appliedFiltersDropdownHeader.id)).unwrap()
            .then((resp: GetFiltersResponse) => {
                if (resp.statusCode === 200) {

                    loadStores(resp);
                    loadConsultants(resp);
                    loadRegions(resp);
                    loadTypologies(resp);

                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    };

    const handleUpdateDropdownStores = () => {
        dispatch(setLoading(true));
        dispatch(getFilters(appliedFiltersDropdownHeader.id)).unwrap()
            .then((resp: GetFiltersResponse) => {
                if (resp.statusCode === 200) {
                   
                    loadFranchisees(resp);
                    loadConsultants(resp);
                    loadRegions(resp);
                    loadTypologies(resp)

                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    };
    
    
    const handleUpdateDropdownConsultants = () => {
        dispatch(setLoading(true));
        dispatch(getFilters(appliedFiltersDropdownHeader.id)).unwrap()
            .then((resp: GetFiltersResponse) => {
                if (resp.statusCode === 200) {

                    loadFranchisees(resp);
                    loadStores(resp);
                    loadRegions(resp);
                    loadTypologies(resp);

                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    };

    
    const handleUpdateDropdownRegions = () => {
        dispatch(setLoading(true));
        dispatch(getFilters(appliedFiltersDropdownHeader.id)).unwrap()
            .then((resp: GetFiltersResponse) => {
                if (resp.statusCode === 200) {
                    
                    loadFranchisees(resp);
                    loadStores(resp);
                    loadConsultants(resp);
                    loadTypologies(resp);

                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    };

    const handleUpdateDropdownTypologies = () => {
        dispatch(setLoading(true));
        dispatch(getFilters(appliedFiltersDropdownHeader.id)).unwrap()
            .then((resp: GetFiltersResponse) => {
                if (resp.statusCode === 200) {
                    
                    loadFranchisees(resp);
                    loadStores(resp);
                    loadConsultants(resp);
                    loadRegions(resp);

                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    };

    return (
        <div className="dashboard-filters-container">
            <div className="dashboard-filters-body">
                {/* YEAR */}
                <Dropdown
                    hasYellowBackground={true}
                    name={"dashboard-filters-year"}
                    label={t("dashboard.filters.year")}
                    placeholder={t("dashboard.filters.yearPlaceholder")}
                    labels={filters.year}
                    message={""}
                    storeId={dashboardFiltersStoreIds.year} />
                {/* MONTH */}
                <Dropdown
                    hasYellowBackground={true}
                    name={"dashboard-filters-month"}
                    label={t("dashboard.filters.month")}
                    placeholder={t("dashboard.filters.monthPlaceholder")}
                    labels={filters.month}
                    message={""}
                    storeId={dashboardFiltersStoreIds.month} />
                {/* RADIO BUTTONS */}
                <RadioButtons
                    name={"dashboard-filters-ttmMonthlyYtdRadios"}
                    hasYellowBackground={true}
                    isInline
                    labels={filters.ttmMonthlyYtdRadios}
                    checkedValue={filters.ttmMonthlyYtdValue}
                    onChangeAction={checkTtmMonthlyYtd}
                />
                {/* FRANQUIADOS */}
                <DropdownCheckbox
                    hasYellowBackground={true}
                    name={"dashboard-filters-franchisees"}
                    label={t("dashboard.filters.franchisees")}
                    message={""}
                    labels={franchiseesFilter?.totalArray}
                    storeId={dashboardFiltersStoreIds.franchisees}
                    messageSingular={t("dashboard.filters.franchiseesSelectedSingular")}
                    messagePlural={t("dashboard.filters.franchiseesSelectedPlural")}
                    placeholder={t("dashboard.filters.franchiseesPlaceholder")}
                    onChangeHandle={handleUpdateDropdownFranchisees} />
                {/* RESTAURANTES */}
                <DropdownCheckbox
                    hasYellowBackground={true}
                    name={"dashboard-filters-restaurants"}
                    label={t("dashboard.filters.restaurants")}
                    message={""}
                    labels={restaurantsFilter?.totalArray}
                    storeId={dashboardFiltersStoreIds.restaurants}
                    messageSingular={t("dashboard.filters.restaurantsSelectedSingular")}
                    messagePlural={t("dashboard.filters.restaurantsSelectedPlural")}
                    placeholder={t("dashboard.filters.restaurantsPlaceholder")}
                    onChangeHandle={handleUpdateDropdownStores} />
                {/* CONSULTORES */}
                <DropdownCheckbox
                    hasYellowBackground={true}
                    name={"dashboard-filters-consultantsOps"}
                    label={t("dashboard.filters.consultantsOps")}
                    message={""}
                    labels={consultantsOpsFilter?.totalArray}
                    storeId={dashboardFiltersStoreIds.consultantsOps}
                    messageSingular={t("dashboard.filters.consultantsOpsSelectedSingular")}
                    messagePlural={t("dashboard.filters.consultantsOpsSelectedPlural")}
                    placeholder={t("dashboard.filters.consultantsOpsPlaceholder")}
                    onChangeHandle={handleUpdateDropdownConsultants} />
                {/* REGIÕES */}
                <DropdownCheckbox
                    hasYellowBackground={true}
                    name={"dashboard-filters-region"}
                    label={t("dashboard.filters.region")}
                    message={""}
                    labels={regionFilter?.totalArray}
                    storeId={dashboardFiltersStoreIds.region}
                    messageSingular={t("dashboard.filters.regionSelectedSingular")}
                    messagePlural={t("dashboard.filters.regionSelectedPlural")}
                    placeholder={t("dashboard.filters.regionPlaceholder")}
                    onChangeHandle={handleUpdateDropdownRegions} />
                {/* TIPOLOGIAS */}
                <DropdownCheckbox
                    hasYellowBackground={true}
                    name={"dashboard-filters-typology"}
                    label={t("dashboard.filters.typology")}
                    message={""}
                    labels={typologyFilter?.totalArray}
                    storeId={dashboardFiltersStoreIds.typology}
                    messageSingular={t("dashboard.filters.typologySelectedSingular")}
                    messagePlural={t("dashboard.filters.typologySelectedPlural")}
                    placeholder={t("dashboard.filters.typologyPlaceholder")}
                    onChangeHandle={handleUpdateDropdownTypologies} />
            </div>
            <OffCanvasFilterFooter handleClear={handleClearFilters} handleApply={handleApplyFilters} />
        </div>
    );
}

export default DashboardFilters;
