import "./Button.scss";

interface ButtonsData {
  label: string,
  icon?: React.ReactNode,
  isDisabled?: boolean,
  handleOnClick?: any,
  type?: any
}

const Buttons = ({
  label,
  icon,
  isDisabled = false,
  handleOnClick = null,
  type = 'button'
}: ButtonsData) => {

  return (
    <button
      className="button-container"
      disabled={isDisabled}
      onClick={() => handleOnClick !== null && handleOnClick()}
      type={type}>
      {icon}
      {label}
    </button>
  );
};

export default Buttons;