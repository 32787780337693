import { Input } from "reactstrap";
import { CrossIcon, SearchIcon } from "../../images/icons";
import './SearchBar.scss';

interface SearchBarData {
    placeholder: string,
    value: string,
    handleValue: any
    handleClear: any
}

const SearchBar = ({ placeholder, value, handleValue, handleClear }: SearchBarData) => {

    return (
        <div className="search-bar-container">
            <SearchIcon />
            <Input name="search-bar" value={value || ''} placeholder={placeholder} onChange={(event) => {
                handleValue(event.target.value);
            }} />
            <div className="search-bar-clear" onClick={() => handleClear()}>
                <CrossIcon />
            </div>
        </div>
    );
}

export default SearchBar;