import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RootState } from "../../app/store";
import { getRequestConfig, isLocalhost } from "../../global/Utils";
import { AuthorizationResponse, AuthenticationState } from "../../models/AuthenticationModels";

export const getRefresh: any = createAsyncThunk(
    'authentication/getRefresh',
    async (requestData: any, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = {
                ...getRequestConfig(allStates, true),
                params: {
                    isLocalhost: isLocalhost
                },
                withCredentials: true
            };
            const resp = await axios.get('/auth/refresh', requestConfig);

            return resp.data;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getAuthorization: any = createAsyncThunk(
    'authentication/getAuthorization',
    async (accessToken: string, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = {
                ...getRequestConfig(allStates),
                params: {
                    isLocalhost: isLocalhost
                },
                withCredentials: true
            };
            const resp = await axios.get('/auth/authorization', requestConfig);
            return resp.data as AuthorizationResponse;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const postLogout: any = createAsyncThunk(
    'authentication/postLogout',
    async (code: string, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = {
                ...getRequestConfig(allStates, true),
                params: {
                    isLocalhost: isLocalhost
                },
                withCredentials: true
            };
            const requestBody = code;
            const resp = await axios.post('/auth/logout', requestBody, requestConfig);
            return resp.data;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const getLogout: any = createAsyncThunk(
    'authentication/getLogout',
    async (requestData: any, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = {
                ...getRequestConfig(allStates, true),
                params: {
                    isLocalhost: isLocalhost
                },
                withCredentials: true
            };
            const resp = await axios.get('/auth/logout', requestConfig);
            return resp.data;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const forceLogout: any = createAsyncThunk(
    'authentication/forceLogout',
    async (requestData: any, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = {
                ...getRequestConfig(allStates, true),
                withCredentials: true
            }
            const resp = await axios.get('/auth/forceLogout', requestConfig);
            return resp.data;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

export const postAuthorize: any = createAsyncThunk(
    'authentication/postAuthorize',
    async (code: string, thunkAPI) => {
        const allStates = thunkAPI.getState() as RootState;
        try {
            const requestConfig = {
                ...getRequestConfig(allStates, true),
                params: {
                    isLocalhost: isLocalhost
                },
                withCredentials: true
            };
            const requestBody = code;
            const resp = await axios.post('/auth/authorize', requestBody, requestConfig);
            return resp.data;
        } catch (error) {
            const err = error as AxiosError;
            const response = err.response?.data as string;
            return thunkAPI.rejectWithValue({
                response: response,
                status: err.response?.status
            });
        }
    }
);

const initialState: AuthenticationState = {
    isAuth: false,
    signOut: false,
    profileId: null,
    userId: '',
    accessToken: null,
    menu: [],
    userName: null,
    path: "/",
    urlSearch: ""
};

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        signOut: (state) => {
            state.isAuth = false;
            state.signOut = true;
            state.profileId = null;
            state.userId = '';
            state.accessToken = null;
            state.menu = [];
            state.userName = null;
        },
        setProfileId: (state, action) => {
            state.profileId = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        }
    },
    extraReducers: {
        // getRefresh
        [getRefresh.pending]: (state) => {
        },
        [getRefresh.fulfilled]: (state, action) => {
            state.accessToken = action.payload;
        },
        [getRefresh.rejected]: (state, action) => {
            state.isAuth = false;
        },
        // getAuthorization
        [getAuthorization.pending]: (state) => {
        },
        [getAuthorization.fulfilled]: (state, action) => {
            state.menu = action.payload.menu;
            state.profileId = action.payload.profileId;
            state.userName = action.payload.name;
            state.isAuth = true;
        },
        [getAuthorization.rejected]: (state, action) => {
        },
        // postLogout
        [postLogout.pending]: (state) => {
        },
        [postLogout.fulfilled]: (state, action) => {
            // state.accessToken = null;
            // state.isAuth = false;
            // state.signOut = true;
        },
        [postLogout.rejected]: (state, action) => {
        },
        // postLogout
        [postLogout.pending]: (state) => {
        },
        [postLogout.fulfilled]: (state, action) => {
        },
        [postLogout.rejected]: (state, action) => {
        },
        // postAuthorize
        [postAuthorize.pending]: (state) => {
        },
        [postAuthorize.fulfilled]: (state, action) => {
            state.accessToken = action.payload;
        },
        [postAuthorize.rejected]: (state, action) => {
        },
    }
});

export const { signOut, setProfileId, setUserId } = authenticationSlice.actions;

export default authenticationSlice.reducer;