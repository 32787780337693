import { useTranslation } from "react-i18next";
import { monthsTranslationAlias } from "../../global/Utils";
import { CheckCircleFillCardIcon, EditIcon, PencilIcon, SearchIcon, WarningFillIcon } from "../../images/icons";
import { SubmissionsSearchCards } from "../../models/SubmissionsModels";
import './ResultCard.scss';

interface ResultCardData {
    cardData: SubmissionsSearchCards
    handleFillIn?: any,
    handleEdit?: any,
    handleConsult?: any
}

const ResultCard = ({ cardData, handleFillIn, handleEdit, handleConsult }: ResultCardData) => {
    const { t } = useTranslation();

    return (
        <div className="result-card-container">
            <div className="result-card-labels">
                {
                    cardData.isFilledIn ?
                    <CheckCircleFillCardIcon /> :
                    <WarningFillIcon />
                }
                <div className="result-card-sub-title">{`${cardData.year} ${t("common.months." + monthsTranslationAlias[cardData.month - 1])}`}</div>
                <div className="result-card-title">{ cardData.storeName }</div>
            </div>
            <div className="result-card-buttons">
                {
                    (!cardData.isFilledIn && cardData.isAvailableToFillIn) &&
                    <div className="result-card-edit" onClick={() => handleFillIn(cardData)}>
                        <PencilIcon />
                    </div>
                }
                {
                    (cardData.isFilledIn && !cardData.isAvailableToFillIn) &&
                    <div className="result-card-consult" onClick={() => handleConsult(cardData)}>
                        <SearchIcon />
                    </div>
                }
                {
                    (cardData.isFilledIn && cardData.isEditable) &&
                    <div className="result-card-admin-edit" onClick={() => handleEdit(cardData)}>
                        <EditIcon />
                    </div>
                }
            </div>
        </div>
    );
}

export default ResultCard;