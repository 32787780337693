import { useAppSelector } from "../../app/hooks";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DashboardTurnover from "./dashboard-turnover/DashboardTurnover";
import DashboardDetails from "./dashboard-details/DashboardDetails";

import {
  checkTtmMonthlyYtd,
  getFilters,
  postInitialInfo,
  setAppliedFilters,
  setDefaultDropdownHeader,
  setMonthsFilter,
  setTTMMonthlyYtdRadiosFilter,
  setYearsFilter,
  toggleIsFiltersOpens
} from "../../features/dashboard/DashboardSlice";
import { setLoading } from "../../features/main/MainSlice";
import { changeValue, dropdownAdded, dropdownRemoved } from "../../features/dropdown/dropdownSlice";
import { dropCheckboxAdded, dropCheckboxRemoved, setValue, updateTotalArray } from "../../features/dropdownWithCheckbox/DropdownCheckboxSlice";

import { ErrorInfo } from "../../models/GlobalModels";
import { GetFiltersResponse, PostInitialInfoRequestData, PostInitialInfoResponse } from "../../models/DashboardModels";
import { monthsTranslationAlias } from '../../global/Utils';
import { dashboardFiltersStoreIds, oldestYearDashboardFilter } from '../../global/Variables';
import { DropdownSelectors } from "../../private/dropdown/Normal/DropDown";
import { RadioButtonsLabels } from "../../private/radio-buttons/RadioButtons";
import "./Dashboard.scss";



const Dashboard = () => {
  const { isInitialLoading } = useAppSelector((store) => store.dashboard);
  const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
  const { cardsInfo, isFiltersOpen } = useAppSelector((store) => store.dashboard);
  const { appliedFilters } = useAppSelector((store) => store.dashboard.filters);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(setLoading(true));

    dispatch(toggleIsFiltersOpens(null));

    let monthsValues: DropdownSelectors[] = [];
    const currentMonth = new Date().getMonth();
    monthsTranslationAlias.forEach((monthAlias, index) => {
      monthsValues.push({
        id: index + 1,
        description: t("common.months." + monthAlias)
      });
    });
    dispatch(setMonthsFilter(monthsValues));
    dispatch(dropdownAdded({
      id: dashboardFiltersStoreIds.month,
      value: monthsValues[currentMonth === 0 ? 11 : currentMonth - 1]
    }));

    let yearsValues: DropdownSelectors[] = [];
    const currentYear = new Date().getFullYear();
    for (let i = oldestYearDashboardFilter; i <= currentYear; i++) {
      yearsValues.unshift({
        id: i,
        description: i + ''
      });
    }
    dispatch(setYearsFilter(yearsValues));
    dispatch(dropdownAdded({
      id: dashboardFiltersStoreIds.year,
      value: currentMonth === 0 ? yearsValues[1] : yearsValues[0]
    }));

    const ttmMonthlyYtdRadios: RadioButtonsLabels[] = [
      { label: t("dashboard.filters.ttmMonthlyYtdRadios.ttm"), value: '1' },
      { label: t("dashboard.filters.ttmMonthlyYtdRadios.monthly"), value: '2' },
      { label: t("dashboard.filters.ttmMonthlyYtdRadios.ytd"), value: '3' }
    ];
    dispatch(setTTMMonthlyYtdRadiosFilter(ttmMonthlyYtdRadios));
    dispatch(checkTtmMonthlyYtd(ttmMonthlyYtdRadios[0].value));

    dispatch(setAppliedFilters({
      year: currentMonth === 0 ? yearsValues[1].id : yearsValues[0].id,
      month: monthsValues[currentMonth === 0 ? 11 : currentMonth - 1],
      ttmMonthlyYtdValue: ttmMonthlyYtdRadios[0].value,
      franchiseeIds: [],
      storeIds: [],
      consultantsIds: [],
      regionIds: [],
      tipologyIds: []
    }));

    const postInitialInfoRequestData: PostInitialInfoRequestData = {
      isResetFilters: true,
      dashboardViewType: null
    };
    dispatch(postInitialInfo(postInitialInfoRequestData)).unwrap()
      .then((resp: PostInitialInfoResponse) => {
        if (
          resp.cardsAndWhenChart.statusCode === 200 &&
          resp.whereChartByMarket.statusCode === 200 &&
          resp.whereChartByType.statusCode === 200 &&
          resp.filters.statusCode === 200 &&
          resp.whyChartLess90Days.statusCode === 200 &&
          resp.whyChartMore90Days.statusCode === 200
        ) {
          dispatch(setDefaultDropdownHeader(resp.cardsAndWhenChart.data.dashboardViews[0]));

          let franchisees = resp.filters.data.franchisees;
          if (franchisees.length > 1) {
            franchisees.unshift({
              id: null,
              description: t("dashboard.filters.franchiseesSelectAll")
            });
          }
          dispatch(dropCheckboxAdded(
            {
              id: dashboardFiltersStoreIds.franchisees,
              value: [],
              totalArray: franchisees
            }
          ));

          let restaurants = resp.filters.data.stores;
          if (restaurants.length > 1) {
            restaurants.unshift({
              id: null,
              description: t("dashboard.filters.restaurantsSelectAll")
            });
          }
          dispatch(dropCheckboxAdded(
            {
              id: dashboardFiltersStoreIds.restaurants,
              value: [],
              totalArray: restaurants
            }
          ));

          let consultantsOps = resp.filters.data.consultants;
          if (consultantsOps.length > 1) {
            consultantsOps.unshift({
              id: null,
              description: t("dashboard.filters.consultantsOpsSelectAll")
            });
          }
          dispatch(dropCheckboxAdded(
            {
              id: dashboardFiltersStoreIds.consultantsOps,
              value: [],
              totalArray: consultantsOps
            }
          ));

          let region = resp.filters.data.markets;
          if (region.length > 1) {
            region.unshift({
              id: null,
              description: t("dashboard.filters.regionSelectAll")
            });
          }
          dispatch(dropCheckboxAdded(
            {
              id: dashboardFiltersStoreIds.region,
              value: [],
              totalArray: region
            }
          ));

          let typology = resp.filters.data.storeTypes;
          if (typology.length > 1) {
            typology.unshift({
              id: null,
              description: t("dashboard.filters.typologySelectAll")
            });
          }
          dispatch(dropCheckboxAdded(
            {
              id: dashboardFiltersStoreIds.typology,
              value: [],
              totalArray: typology
            }
          ));

          dispatch(setLoading(false));
        } else {
          navigate(`/${currentLanguage.lang}/error-page`);
        }
      })
      .catch((error: ErrorInfo) => {
        navigate(`/${currentLanguage.lang}/error-page`);
      });
  }, []);

  useEffect(() => {
    if (!isFiltersOpen && isFiltersOpen !== null) {
      dispatch(changeValue({
        id: dashboardFiltersStoreIds.month,
        value: appliedFilters.month
      }));
      dispatch(changeValue({
        id: dashboardFiltersStoreIds.year,
        value: {
          id: appliedFilters.year,
          description: appliedFilters.year + ''
        }
      }));

      dispatch(checkTtmMonthlyYtd(appliedFilters.timeFilterType + ''));

      dispatch(getFilters()).unwrap()
        .then((resp: GetFiltersResponse) => {
          if (resp.statusCode === 200) {
            let franchisees = resp.data.franchisees;
            if (franchisees.length > 1) {
              franchisees.unshift({
                id: null,
                description: t("dashboard.filters.franchiseesSelectAll")
              });
            }
            dispatch(setValue(
              {
                storeId: dashboardFiltersStoreIds.franchisees,
                totalArray: franchisees,
                value: appliedFilters.franchiseeIds
              }
            ));

            let restaurants = resp.data.stores;
            if (restaurants.length > 1) {
              restaurants.unshift({
                id: null,
                description: t("dashboard.filters.restaurantsSelectAll")
              });
            }
            dispatch(setValue(
              {
                storeId: dashboardFiltersStoreIds.restaurants,
                totalArray: restaurants,
                value: appliedFilters.storeIds
              }
            ));

            let consultantsOps = resp.data.consultants;
            if (consultantsOps.length > 1) {
              consultantsOps.unshift({
                id: null,
                description: t("dashboard.filters.consultantsOpsSelectAll")
              });
            }
            dispatch(setValue(
              {
                storeId: dashboardFiltersStoreIds.consultantsOps,
                totalArray: consultantsOps,
                value: appliedFilters.consultantsIds
              }
            ));

            let region = resp.data.markets;
            if (region.length > 1) {
              region.unshift({
                id: null,
                description: t("dashboard.filters.regionSelectAll")
              });
            }
            dispatch(setValue(
              {
                storeId: dashboardFiltersStoreIds.region,
                totalArray: region,
                value: appliedFilters.regionIds
              }
            ));

            let typology = resp.data.storeTypes;
            if (typology.length > 1) {
              typology.unshift({
                id: null,
                description: t("dashboard.filters.typologySelectAll")
              });
            }
            dispatch(setValue(
              {
                storeId: dashboardFiltersStoreIds.typology,
                totalArray: typology,
                value: appliedFilters.tipologyIds
              }
            ));

            dispatch(setLoading(false));
          } else {
            navigate(`/${currentLanguage.lang}/error-page`);
          }
        })
        .catch((error: ErrorInfo) => {
          navigate(`/${currentLanguage.lang}/error-page`);
        });
    }
  }, [isFiltersOpen]);

  useLayoutEffect(() => () => {
    dispatch(dropdownRemoved(dashboardFiltersStoreIds.year));
    dispatch(dropdownRemoved(dashboardFiltersStoreIds.month));
    dispatch(dropCheckboxRemoved(dashboardFiltersStoreIds.franchisees));
    dispatch(dropCheckboxRemoved(dashboardFiltersStoreIds.restaurants));
    dispatch(dropCheckboxRemoved(dashboardFiltersStoreIds.consultantsOps));
    dispatch(dropCheckboxRemoved(dashboardFiltersStoreIds.region));
    dispatch(dropCheckboxRemoved(dashboardFiltersStoreIds.typology));
  }, [])

  if (isInitialLoading) {
    return <div className="dashboard-container"></div>
  } else {
    return (
      <div className="dashboard-container">
        <DashboardTurnover />
        {cardsInfo?.hasTurnOverData &&
          <DashboardDetails />
        }
      </div>
    );
  }
};

export default Dashboard;
