import { Link } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { toggleOpen } from '../../features/nav-menu/NavMenuSlice';
import { LogoIcon } from '../../images/icons';
import './NavbarMenu.scss';
import DropdownLanguages from '../../private/dropdownLanguages/DropdownLanguages';
import ProfileBadge from '../../private/profile-badge/ProfileBadge';

const NavbarMenu = () => {
    const dispatch = useAppDispatch();

    const { isOpen } = useAppSelector((store) => store.navMenu);
    const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);

    const handleToggleOpen = () => {
        dispatch(toggleOpen());
    };
 
    return (
        <Navbar className="navbar-menu-container">
            <div className="navbar-menu-left-wrapper">
                <div onClick={handleToggleOpen} className={"navbar-menu-button" + (isOpen ? " navbar-menu-button-open" : "")}>
                    <span className="navbar-menu-button-line"></span>
                    <span className="navbar-menu-button-line"></span>
                    <span className="navbar-menu-button-line"></span>
                </div>
                <div className="navbar-menu-logo">
                    <Link to={`/${currentLanguage.lang}/dashboard`}>
                        <LogoIcon />
                        MCD TurnOver
                    </Link>
                </div>
            </div>
            <div className="navbar-menu-right-wrapper">
               <DropdownLanguages />
               <ProfileBadge />
            </div>
        </Navbar>
    );
}

export default NavbarMenu;