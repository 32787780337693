import { FormGroup, Label } from "reactstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { changeValue } from "../../../features/dropdown/dropdownSlice";
import "../Dropdown.scss";
import { useState } from "react";
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useTranslation } from "react-i18next";
interface DropdownNormalSelector {
  labels: DropdownSelectors[];
  name: string;
  label: string;
  message: string;
  onChangeAction?: any;
  isFocused?: boolean;
  error?: boolean;
  success?: boolean;
  storeId: string;
  placeholder?: string;
  hasYellowBackground?: boolean;
  handleOnChange?: any
}

export interface DropdownSelectors {
  id: number,
  description: string
}

const Dropdown = ({
  labels,
  name,
  label,
  error,
  success,
  message,
  storeId,
  placeholder = '',
  hasYellowBackground,
  handleOnChange = null
}: DropdownNormalSelector) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);

  const closeDropdown = () => {
    setIsActive(false);
  }

  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  const value = useAppSelector((store) => store.dropdown.entities[storeId]?.value);

  const handleClick = (dropDownValue: DropdownSelectors) => {
    dispatch(changeValue({
      id: storeId,
      value: dropDownValue
    }));
    setIsActive(false);
    if (handleOnChange) {
      handleOnChange(dropDownValue);
    }
  };

  return (
    <div className={"dropdown-container-inside"}>
      <Label for={name}>{label}</Label>
      <FormGroup check>
        <div className="dropdown">
          <div
            className={
              'dropdown-btn' +
              (isActive ? " active" : "") +
              (error ? " error" : "") +
              (success ? " success" : "") +
              (hasYellowBackground ? " focus-white" : " focus-yellow")
            }
            ref={ref}
            onClick={() => setIsActive(!isActive)}>
            <div className="dropdown-value-container">
              {
                !value ?
                <div className="placeholder-text">{placeholder}</div> :
                value.description
              }
            </div>
            <span className="fas fa-caret-down"></span>
          </div>
          {
            isActive &&
            <div className="dropdown-content">
              {labels.map((dropdownSelector, index) => {
                return (
                  <div
                    key={index}
                    className={`dropdown-item ${value && value.id === dropdownSelector.id ? 'selected' : ''}`}
                    onClick={() => handleClick(dropdownSelector)} >
                    {dropdownSelector.description}
                  </div>
                );
              })}
              {
                labels.length === 0 &&
                <span className="dropdown-no-options">{t("common.dropdown.noOptions")}</span>
              }
            </div>
          }
          <span className={`${error ? "error" : ""} ${success ? "success" : ""}`}>
            {message}
          </span>
        </div>
      </FormGroup>
    </div>
  );
};
export default Dropdown;
