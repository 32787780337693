import { CheckIcon, CrossIcon, EditIcon } from "../../images/icons";
import SmallInput from "../small-input/SmallInput";
import './EditCard.scss';

interface EditCardData {
    title: string,
    value: string,
    isEditing: boolean,
    hasError: boolean,
    editValue: string,
    sufix?: string,
    handleEdit: any,
    handleCancel: any,
    handleChangeValue: any,
    handleChangeInputValue: any
}

const EditCard = ({
    title,
    value,
    isEditing,
    hasError,
    editValue,
    sufix = '',
    handleEdit,
    handleCancel,
    handleChangeInputValue,
    handleChangeValue
}: EditCardData) => {

    return (
        <div className="edit-card-container">
            {
                isEditing || value === null ?
                <div className="edit-card">
                    <div className="edit-card-labels">
                        <span className="edit-card-title">{ title }</span>
                        <SmallInput
                            value={editValue}
                            handleChangeInputValue={handleChangeInputValue}
                            handleEnter={handleChangeValue}
                            hasError={hasError}/>
                    </div>
                    <div className="edit-card-icon confirm" onClick={() => handleChangeValue()}>
                        <CheckIcon />                        
                    </div>
                    <div className="edit-card-icon cancel" onClick={() => handleCancel()}>
                        <CrossIcon />                        
                    </div>
                </div> :
                <div className="edit-card">
                    <div className="edit-card-labels">
                        <span className="edit-card-title">{ title }</span>
                        <span className="edit-card-value">{ value + sufix }</span>
                    </div>
                    <div className="edit-card-icon edit" onClick={() => handleEdit()}>
                        <EditIcon />
                    </div>
                </div>
            }
        </div>
    );
}

export default EditCard;