import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

type dropdown = {
  id: string;
  value: {
    id: number,
    description: string
  } | null
};

const dropdownAdapter = createEntityAdapter<dropdown>();

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState: dropdownAdapter.getInitialState(),
  reducers: {
    dropdownAdded: dropdownAdapter.addOne,
    dropdownRemoved: dropdownAdapter.removeOne,
    changeValue: (state, action) => {
      dropdownAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          value: action.payload.value,
        },
      });
    },
    clearDropdownValue: (state, action) => {
      action.payload.forEach((dropdown: string) => {
        dropdownAdapter.updateOne(state, {
          id: dropdown,
          changes: {
            value: null
          },
        });
      })      
    },
  },
});

export const { dropdownAdded, dropdownRemoved, changeValue, clearDropdownValue } = dropdownSlice.actions;

export default dropdownSlice.reducer;