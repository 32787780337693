import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { changeObjective, existsObjective, resetForm, setIsBeforeMarch } from '../../../features/submissions/SubmissionsSlice';
import SmallInput from '../../../private/small-input/SmallInput';
import SteplineNavigation from '../../../private/stepline/StepNavigation';
import { getCurrentPath, monthsTranslationAlias } from '../../../global/Utils';
import { setLoading } from '../../../features/main/MainSlice';
import { ExistsObjectiveResponse } from '../../../models/SubmissionsModels';
import { ErrorInfo } from '../../../models/GlobalModels';

const SubmissionsForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);
  const {
    currentForm,
    stepLabels,
    activeStep,
    isEdit,
    isEmployees,
    isBeforeMarch
  } = useAppSelector((store) => store.submissions);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const currentPath = getCurrentPath(location.pathname);
    if (currentPath === 'form' || currentForm.card === null) {
      navigate(`/${currentLanguage.lang}/submissions`);
    } else if (!isEdit && isEmployees) {
      let currentFormMonth = currentForm.card.month;      
      if (currentFormMonth < 4) {
        dispatch(setIsBeforeMarch(true));
        dispatch(setLoading(true));
        dispatch(existsObjective()).unwrap()
        .then((resp: ExistsObjectiveResponse) => {
          if (resp.statusCode === 200) {
            dispatch(setLoading(false));
          } else {
            navigate(`/${currentLanguage.lang}/error-page`);
          }
        })
        .catch((error: ErrorInfo) => {
          navigate(`/${currentLanguage.lang}/error-page`);
        });
      } else {
        dispatch(setIsBeforeMarch(false));
        dispatch(setLoading(false));
      }
    } else {
      dispatch(setIsBeforeMarch(false));
      dispatch(setLoading(false));
    }
  }, []);

  useEffect(() => () => {
    dispatch(resetForm());
  }, []);

  const handleChangeObjective = (inputValue: any) => {
    dispatch(changeObjective(inputValue.value));
  };

  return (
    <div className="submissions-form">
      <div className="submissions-form-header">
        {
          currentForm.card &&
          <div className="submissions-form-title">
            {currentForm.card.storeName}
            <span className="submissions-form-sub-title">
              {(`${currentForm.card.year} ${t("common.months." + monthsTranslationAlias[currentForm.card.month - 1])}`)}
            </span>
          </div>
        }
        {
          (isBeforeMarch && !currentForm.hasObjective) &&
          <div className="submissions-form-objective">
            {t("submissions.objectiveLabel")}
            <SmallInput
              value={currentForm.step1.objective}
              id={'objective'}
              handleChangeInputValue={handleChangeObjective}
              placeholder={'%'} />
          </div>
        }
      </div>
      <div className="submissions-form-body">
        <SteplineNavigation labels={stepLabels} activeStep={activeStep} />
        <Outlet />
      </div>
    </div>
  );

}

export default SubmissionsForm;