import { FormGroup, Input, Label } from "reactstrap";
import './Checkbox.scss';
interface CheckboxData {
    label: string;
    name: string;
    value: boolean;
    isSmall?: boolean;
    isDisabled?: boolean;
    hasYellowBackground?: boolean;
    onChangeHandle: any,
}

const Checkbox = ({ label, name, isSmall = false, isDisabled = false, onChangeHandle, value, hasYellowBackground = false }: CheckboxData) => {
    return (
        <div className={"checkbox-container" +
            (isSmall ? ' checkbox-small' : ' checkbox-normal') + 
            (hasYellowBackground ? ' check-box-black' : ' check-box-yellow')}>
            <FormGroup check>
                <Input
                    type="checkbox"
                    name={name}
                    disabled={isDisabled}
                    checked={value}
                    onChange={(event) => {onChangeHandle(event.target.checked)}}/>
                {' '}
                <Label for={name} check>
                    { label }
                </Label>
            </FormGroup>
        </div>
    );
}

export default Checkbox;