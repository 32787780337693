
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Legend,
} from 'chart.js';

import {
    toggleRegionChart,
    toggleRegionChartYear,
    toggleRegionChartMonth,
    toggleTypologyChart,
    toggleTypologyChartYear,
    toggleTypologyChartMonth,
    getWhereChartByMarket,
    getWhereChartByType,
    toggleWhyChartMore90Days,
    toggleWhyChartLess90Days,
    getWhyChartLess90Days,
    getWhyChartMore90Days
} from "../../../features/dashboard/DashboardSlice";
import { setLoading } from "../../../features/main/MainSlice";

import Switch from "../../../private/switch/Switch";
import Checkbox from "../../../private/checkbox/Checkbox";

import "./DashboardDetails.scss";
import styleVariables from "../../../styles/variables.module.scss";
import { useNavigate } from "react-router-dom";
import { ErrorInfo } from "../../../models/GlobalModels";
import { GetWhereChartResponse, GetWhyChartResponse, WhereChartRequestData, WhyChartRequestData } from "../../../models/DashboardModels";

const DashboardDetails = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { details } = useAppSelector((store) => store.dashboard);
    const { cardsInfo } = useAppSelector((store) => store.dashboard);
    const { currentLanguage } = useAppSelector((store) => store.dropdownLanguages);

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        ChartDataLabels,
        Legend
    );

    const graphOptions: any = {
        responsive: true,
        maintainAspectRatio: false,
        hover: { mode: null },
    }

    const legendLabel: any = {
        position: 'bottom',
        align: 'start',
        onClick: false,
        labels: {
            boxWidth: 12,
            boxHeight: 12,
            padding: 8,
            color: styleVariables.fontColor,
            font: {
                size: parseInt(styleVariables.fontSize),
                family: styleVariables.fontFamily
            }
        }
    }

    const dataLabelStyles: any = {
        color: styleVariables.fontColor,
        anchor: 'end',
        align: 'end',
        offset: -4,
        font: {
            size: parseInt(styleVariables.fontSize),
            family: styleVariables.fontFamily
        }
    }

    const ticksStyles: any = {
        color: styleVariables.fontColor,
        font: {
            size: parseInt(styleVariables.fontSize),
            family: styleVariables.fontFamily
        }
    }

    const exitsBorder: any = {
        borderColor: 'transparent',
        borderWidth: {
            left: 3,
            right: 3
        }
    }

    const exitsOptions: any = {
        ...graphOptions,
        barThickness: 30,
        plugins: {
            legend: {
                ...legendLabel
            },
            datalabels: {
                ...dataLabelStyles
            }
        },
        scales: {
            y: {
                display: false
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    ...ticksStyles
                }
            }
        },
        layout: {
            padding: {
                top: 14,
            }
        }
    };

    const employeesExitsData: any = {
        labels: cardsInfo?.turnOverDetailsWhenInfo.employeesExitsChartData?.xLabels,
        datasets: [
            {
                label: t("dashboard.details.exits.employees.legend1"),
                data: cardsInfo?.turnOverDetailsWhenInfo.employeesExitsChartData?.yValues[0],
                backgroundColor: styleVariables.primaryYellow,
                ...exitsBorder
            },
            {
                label: t("dashboard.details.exits.employees.legend2"),
                data: cardsInfo?.turnOverDetailsWhenInfo.employeesExitsChartData?.yValues[1],
                backgroundColor: styleVariables.primaryRed,
                ...exitsBorder
            }
        ]
    };

    const managementStaffExitsData: any = {
        labels: cardsInfo?.turnOverDetailsWhenInfo.managementExitsChartData?.xLabels,
        datasets: [
            {
                label: t("dashboard.details.exits.managementStaff.legend1"),
                data: cardsInfo?.turnOverDetailsWhenInfo.managementExitsChartData?.yValues[0],
                backgroundColor: styleVariables.primaryYellow,
                ...exitsBorder
            },
            {
                label: t("dashboard.details.exits.managementStaff.legend2"),
                data: cardsInfo?.turnOverDetailsWhenInfo.managementExitsChartData?.yValues[1],
                backgroundColor: styleVariables.primaryRed,
                ...exitsBorder
            }
        ]
    };

    const regionsOptions: any = {
        ...graphOptions,
        barThickness: 16,
        plugins: {
            legend: {
                ...legendLabel
            },
            datalabels: {
                ...dataLabelStyles,
                offset: -2,
                rotation: -90,
                formatter: (value: any) => {
                    return value + ' %';
                }
            }
        },
        scales: {
            y: {
                display: false
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    ...ticksStyles,
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 90,
                    labelOffset: -10
                }
            }
        },
        layout: {
            padding: {
                top: 60
            }
        }
    };

    const regionsTNData: any = {
        label: `${t("dashboard.details.tn")} ${t("dashboard.details.region")}`,
        data: details.region.tn,
        backgroundColor: styleVariables.primaryYellow
    }

    const regionsData: any = {
        labels: details.region.labels,
        datasets: details.region.isLastYear ?
            [
                {
                    label: t("dashboard.details.lastYear"),
                    data: details.region.compareTn,
                    backgroundColor: styleVariables.lightGrey
                },
                regionsTNData
            ] :
            (details.region.isLastMonth ?
                [
                    {
                        label: t("dashboard.details.lastMonth"),
                        data: details.region.compareTn,
                        backgroundColor: styleVariables.lightGrey
                    },
                    regionsTNData
                ] :
                [
                    regionsTNData
                ]
            )
    };

    const typologyOptions: any = {
        ...graphOptions,
        indexAxis: 'y',
        barThickness: 16,
        plugins: {
            legend: {
                ...legendLabel
            },
            datalabels: {
                ...dataLabelStyles,
                offset: 0,
                formatter: (value: any) => {
                    return value + '%';
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    drawBorder: false,
                    lineWidth: (ticks: any) => {
                        return ticks.tick.value === 100 ? 1 : 0;
                    }
                },
                ticks: {
                    stepSize: 50,
                    ...ticksStyles,
                    callback: (val: any) => {
                        return val + '%';
                    }
                },
                suggestedMin: 0,
                suggestedMax: 100
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    ...ticksStyles
                }
            }
        },
        layout: {
            padding: {
                right: 60
            }
        }
    };

    const typologyTNData: any = {
        label: `${t("dashboard.details.tn")} ${t("dashboard.details.typology")}`,
        data: details.typology.tn,
        backgroundColor: styleVariables.primaryYellow
    }

    const typologyData: any = {
        labels: details.typology.labels,
        datasets: details.typology.isLastYear ?
            [
                {
                    label: t("dashboard.details.lastYear"),
                    data: details.typology.compareTn,
                    backgroundColor: styleVariables.lightGrey
                },
                typologyTNData
            ] :
            (details.typology.isLastMonth ?
                [
                    {
                        label: t("dashboard.details.lastMonth"),
                        data: details.typology.compareTn,
                        backgroundColor: styleVariables.lightGrey
                    },
                    typologyTNData
                ] :
                [
                    typologyTNData
                ]
            )
    };

    const whyOptions: any = {
        ...graphOptions,
        indexAxis: 'y',
        barThickness: 16,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                ...dataLabelStyles,
                offset: 0,
                formatter: (value: any) => {
                    return value + '%';
                }
            }
        },
        scales: {
            y: {
                display: true,
                grid: {
                    display: false
                },
                ticks: {
                    ...ticksStyles
                }
            },
            x: {
                display: false
            }
        },
        layout: {
            padding: {
                right: 30
            }
        },
    };

    const whyLess90DaysData: any = {
        labels: details.whyLess90Days.labels,
        datasets: [{
            label: `${t("dashboard.details.tn")} ${t("dashboard.details.byMotives")}`,
            data: details.whyLess90Days.tn,
            backgroundColor: styleVariables.primaryYellow
        }]
    };

    const whyMore90DaysData: any = {
        labels: details.whyMore90Days.labels,
        datasets: [{
            label: `${t("dashboard.details.tn")} ${t("dashboard.details.byMotives")}`,
            data: details.whyMore90Days.tn,
            backgroundColor: styleVariables.primaryYellow
        }]
    };

    const switchLabels = [
        t("dashboard.details.employees"),
        t("dashboard.details.managementStaff")
    ];

    const lastYearLabel = t("dashboard.details.lastYear");
    const lastMonthLabel = t("dashboard.details.lastMonth");

    const handleSwitchRegion = (isChecked: boolean) => {
        dispatch(toggleRegionChart(isChecked));
        handleGetWhereChart(true, isChecked, details.region.isLastYear, details.region.isLastMonth);
    };

    const handleCheckboxRegionYear = (isChecked: boolean) => {
        dispatch(toggleRegionChartYear(isChecked));
        handleGetWhereChart(true, details.region.isManagementStaff, isChecked, isChecked ? !isChecked : details.region.isLastMonth);
    };

    const handleCheckboxRegionMonth = (isChecked: boolean) => {
        dispatch(toggleRegionChartMonth(isChecked));
        handleGetWhereChart(true, details.region.isManagementStaff, isChecked ? !isChecked : details.region.isLastYear, isChecked);
    };

    const handleSwitchTypology = (isChecked: boolean) => {
        dispatch(toggleTypologyChart(isChecked));
        handleGetWhereChart(false, isChecked, details.typology.isLastYear, details.typology.isLastMonth);
    };

    const handleCheckboxTypologyYear = (isChecked: boolean) => {
        dispatch(toggleTypologyChartYear(isChecked));
        handleGetWhereChart(false, details.typology.isManagementStaff, isChecked, isChecked ? !isChecked : details.typology.isLastMonth);
    };

    const handleCheckboxTypologyMonth = (isChecked: boolean) => {
        dispatch(toggleTypologyChartMonth(isChecked));
        handleGetWhereChart(false, details.typology.isManagementStaff, isChecked ? !isChecked : details.typology.isLastYear, isChecked);
    };

    const handleSwitchWhyLess90Days = (isChecked: boolean) => {
        dispatch(toggleWhyChartLess90Days(isChecked));
        const requestData: WhyChartRequestData = {
            isManagementStaff: isChecked,
            isLessThan90Days: true
        };
        dispatch(setLoading(true));
        dispatch(getWhyChartLess90Days(requestData)).unwrap()
            .then((resp: GetWhyChartResponse) => {
                if (resp.statusCode === 200) {
                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    };

    const handleSwitchWhyMore90Days = (isChecked: boolean) => {
        dispatch(toggleWhyChartMore90Days(isChecked));
        const requestData: WhyChartRequestData = {
            isManagementStaff: isChecked,
            isLessThan90Days: false
        };
        dispatch(setLoading(true));
        dispatch(getWhyChartMore90Days(requestData)).unwrap()
            .then((resp: GetWhyChartResponse) => {
                if (resp.statusCode === 200) {
                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    };

    const handleGetWhereChart = (isMarket: boolean, isManagementStaff: boolean, isLastYear: boolean, isLastMonth: boolean) => {
        const requestData: WhereChartRequestData = {
            isManagementStaff: isManagementStaff,
            isLastYear: isLastYear,
            isLastMonth: isLastMonth,
        };
        dispatch(setLoading(true));
        dispatch(isMarket ? getWhereChartByMarket(requestData) : getWhereChartByType(requestData)).unwrap()
            .then((resp: GetWhereChartResponse) => {
                if (resp.statusCode === 200) {
                    dispatch(setLoading(false));
                } else {
                    navigate(`/${currentLanguage.lang}/error-page`);
                }
            })
            .catch((error: ErrorInfo) => {
                navigate(`/${currentLanguage.lang}/error-page`);
            });
    };

    if (details === null || cardsInfo === null) {
        return (
            <div className="dashboard-details-container"></div>
        )
    } else {
        return (
            <div className="dashboard-details-container">
                <h3 className="dashboard-details-title">{t("dashboard.details.title")}</h3>
                <div className="dashboard-details-sections">
                    {
                        cardsInfo.turnOverDetailsWhenInfo.hasTurnOverDetailsInfo &&
                        <section className="dashboard-details-section">
                            <span className="dashboard-details-section-title">
                                {t("dashboard.details.when")}
                            </span>
                            <div className="dashboard-details-section-graphs">
                                <div className="dashboard-details-graphs-container">
                                    <div className="dashboard-details-graph-wrapper">
                                        <div className="dashboard-details-graph-title">
                                            {t("dashboard.details.exits.title") + ' '}
                                            <span className="dashboard-details-graph-sub-title">
                                                {t("dashboard.details.exits.subTitle") + ' '}
                                            </span>
                                            {t("dashboard.details.employees")}
                                        </div>
                                        <div className="dashboard-details-graph">
                                            <Bar options={exitsOptions} data={employeesExitsData} />
                                        </div>
                                    </div>
                                </div>
                                <div className="dashboard-details-graphs-container">
                                    <div className="dashboard-details-graph-wrapper">
                                        <div className="dashboard-details-graph-title">
                                            {t("dashboard.details.exits.title") + ' '}
                                            <span className="dashboard-details-graph-sub-title">
                                                {t("dashboard.details.exits.subTitle") + ' '}
                                            </span>
                                            {t("dashboard.details.managementStaff")}
                                        </div>
                                        <div className="dashboard-details-graph">
                                            <Bar options={exitsOptions} data={managementStaffExitsData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    <section className="dashboard-details-section">
                        <span className="dashboard-details-section-title">
                            {t("dashboard.details.where")}
                        </span>
                        <div className="dashboard-details-section-graphs">
                            <div className="dashboard-details-graphs-container">
                                <div className="dashboard-details-graph-options">
                                    <Switch labels={switchLabels} name={"region-switch"} onChangeHandle={handleSwitchRegion} value={details.region.isManagementStaff} />
                                    <div className="dashboard-details-checkbox-wrapper">
                                        <Checkbox
                                            label={lastYearLabel}
                                            name={"region-last-year"}
                                            isSmall={true}
                                            onChangeHandle={handleCheckboxRegionYear}
                                            value={details.region.isLastYear} />
                                        <Checkbox
                                            label={lastMonthLabel}
                                            name={"region-last-month"}
                                            isSmall={true}
                                            onChangeHandle={handleCheckboxRegionMonth}
                                            value={details.region.isLastMonth} />
                                    </div>
                                </div>
                                <div className="dashboard-details-graph-wrapper">
                                    <div className="dashboard-details-graph-title">
                                        {
                                            t("dashboard.details.tn") + ' ' +
                                            (details.region.isManagementStaff ? t("dashboard.details.managementStaff") : t("dashboard.details.employees")) + ' ' +
                                            t("dashboard.details.region")
                                        }
                                    </div>
                                    <div className="dashboard-details-graph">
                                        <Bar options={regionsOptions} data={regionsData} />
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-details-graphs-container">
                                <div className="dashboard-details-graph-options">
                                    <Switch labels={switchLabels} name={"typology-switch"} onChangeHandle={handleSwitchTypology} value={details.typology.isManagementStaff} />
                                    <div className="dashboard-details-checkbox-wrapper">
                                        <Checkbox
                                            label={lastYearLabel}
                                            name={"typology-last-year"}
                                            isSmall={true}
                                            onChangeHandle={handleCheckboxTypologyYear}
                                            value={details.typology.isLastYear} />
                                        <Checkbox
                                            label={lastMonthLabel}
                                            name={"typology-last-month"}
                                            isSmall={true}
                                            onChangeHandle={handleCheckboxTypologyMonth}
                                            value={details.typology.isLastMonth} />
                                    </div>
                                </div>
                                <div className="dashboard-details-graph-wrapper">
                                    <div className="dashboard-details-graph-title">
                                        {
                                            t("dashboard.details.tn") + ' ' +
                                            (details.typology.isManagementStaff ? t("dashboard.details.managementStaff") : t("dashboard.details.employees")) + ' ' +
                                            t("dashboard.details.typology")
                                        }
                                    </div>
                                    <div className="dashboard-details-graph">
                                        <Bar options={typologyOptions} data={typologyData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="dashboard-details-section">
                        <span className="dashboard-details-section-title">
                            {t("dashboard.details.why")}
                        </span>
                        <div className="dashboard-details-section-graphs">
                            <div className="dashboard-details-graphs-container">
                                <div className="dashboard-details-graph-options">
                                    <Switch labels={switchLabels} name={"why-less-90-days-switch"} onChangeHandle={handleSwitchWhyLess90Days} value={details.whyLess90Days.isManagementStaff} />
                                </div>
                                <div className="dashboard-details-graph-wrapper">
                                    <div className="dashboard-details-graph-title">
                                        {
                                            t("dashboard.details.tn") + ' ' +
                                            (details.whyLess90Days.isManagementStaff ? t("dashboard.details.managementStaff") : t("dashboard.details.employees")) + ' '
                                        }
                                        <span className="dashboard-details-graph-sub-title">{t("dashboard.details.less90DaysTitle")}</span>
                                    </div>
                                    <div className="dashboard-details-graph">
                                        <Bar options={whyOptions} data={whyLess90DaysData} />
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-details-graphs-container">
                                <div className="dashboard-details-graph-options">
                                    <Switch labels={switchLabels} name={"why-more-90-days-switch"} onChangeHandle={handleSwitchWhyMore90Days} value={details.whyMore90Days.isManagementStaff} />
                                </div>
                                <div className="dashboard-details-graph-wrapper">
                                    <div className="dashboard-details-graph-title">
                                        {
                                            t("dashboard.details.tn") + ' ' +
                                            (details.whyMore90Days.isManagementStaff ? t("dashboard.details.managementStaff") : t("dashboard.details.employees")) + ' '
                                        }
                                        <span className="dashboard-details-graph-sub-title">{t("dashboard.details.more90DaysTitle")}</span>
                                    </div>
                                    <div className="dashboard-details-graph">
                                        <Bar options={whyOptions} data={whyMore90DaysData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }

};

export default DashboardDetails;