import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false
};

const modalConfirmSlice = createSlice({ 
    name: 'modalConfirm',
    initialState,
    reducers: {
        toggleOpenConfirm: (state) => {
            state.isOpen = !state.isOpen;
        }
    }
});

export const { toggleOpenConfirm } = modalConfirmSlice.actions;

export default modalConfirmSlice.reducer;