import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect } from "react";
import { tagsAdded, toggleShowMore } from "../../features/tags/TagsSlice";
import { CrossIcon } from "../../images/icons";
import './Tags.scss';


interface TagsData {
    tagsArray: TagsLabels[];
    onChangeAction?: any;
    storeId: string;
    dropdownStoreID?: string
    onChangeHandle?: any;
}

interface TagsLabels {
    label: string;
    id: string;
}

const Tags = ({ tagsArray, onChangeAction, storeId, dropdownStoreID, onChangeHandle = null }: TagsData) => {
    const dispatch = useAppDispatch();
    const numberDisplay = 5;
    const tagsArrayToDisplay = tagsArray.slice(0, numberDisplay);

    useEffect(() => {
        dispatch(tagsAdded(
            {
                id: storeId,
                isShowingMore: false
            }
        ));
    }, [storeId, dispatch]);

    const isShowingMore = useAppSelector((store) => store.tags.entities[storeId]?.isShowingMore);

    const handleShowMore = () => {
        dispatch(toggleShowMore(storeId));
    }

    const handleRemove = (tagId: string) => {
        dispatch(onChangeAction({
            storeId: dropdownStoreID,
            id: tagId,
        }));
        if (onChangeHandle) {
            onChangeHandle();
        }
    }
    const spanContent = (index: number, label: string, id: string) => {
        if (id === null){
            return;
        } else {
           return (
            <span className="tag-container" key={index}>
                { label }
                <div className="tag-remove-btn" onClick={() => onChangeAction && handleRemove(id)}>
                    <CrossIcon/>
                </div>
            </span>
           )
        }
    }

    return (
        <div className="tags-container">
            {
                (isShowingMore ? tagsArray : tagsArrayToDisplay)?.map((tag, index) => {
                   return spanContent(index, tag.label, tag.id)
                })
            }
            {
                tagsArray.length > numberDisplay &&
                <span className="tags-show-more" onClick={handleShowMore}>{`${isShowingMore ? 'Esconder' : 'Mostrar Mais'} (${tagsArray.length - numberDisplay})`}</span>
            }
        </div>
    );
}

export default Tags;