import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import './LoadingPage.scss';


const LoadingPage = () => {
	const { t } = useTranslation();

    return (
        <div className='loading-page-container'>
            <div className='spinner-container'>
                <Spinner>
                </Spinner>
                {t("common.loading")} ...
            </div>
        </div>
    );
}

export default LoadingPage;