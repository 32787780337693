import { useTranslation } from "react-i18next";
import Header from "../../../private/header/Header";
import DataCard from "../../../private/data-card/DataCard";
import { RestaurantIcon } from "../../../images/icons";
import { useAppSelector } from "../../../app/hooks";
import { useDispatch } from "react-redux";

import ModalGrid from "../../../private/modal-grid/ModalGrid";
import ModalGridLarge from "../../../private/modal-grid-large/ModalGridLarge";

import {
	getEvolutionRestaurantTable,
	getEvolutionTable,
	postInitialInfo,
	toggleIsFiltersOpens,
	toggleLargeGrid,
	toggleModalGrid,
	updateModalGrid,
	updateOption
} from "../../../features/dashboard/DashboardSlice";
import { clearValue, updateTotalArray } from "../../../features/dropdownWithCheckbox/DropdownCheckboxSlice";
import { toggleModalError } from "../../../features/modal-error/ModalErrorSlice";
import { openFilters } from "../../../features/off-canvas-filter/OffCanvasFilterSlice";
import { setLoading } from "../../../features/main/MainSlice";

import {
	DashboardViews,
	EvolutionTableRequestData,
	GetEvolutionRestaurantTableResponse,
	GetEvolutionTableResponse,
	PostInitialInfoRequestData,
	PostInitialInfoResponse
} from "../../../models/DashboardModels";
import { ErrorInfo } from "../../../models/GlobalModels";
import { dashboardFiltersStoreIds } from "../../../global/Variables";
import "./DashboardTurnover.scss";
import { getFormatedPercentageVariance } from "../../../global/Utils";


const DashboardTurnover = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const dataCards = useAppSelector((store) => store.dashboard.cardsInfo);
	const { currentOption } = useAppSelector((store) => store.dashboard.dropdownHeader);
	const { appliedFilters } = useAppSelector((store) => store.dashboard.filters);
    const { appliedFiltersDropdownHeader } = useAppSelector((store) => store.dashboard);
	
    const handleToggleFilters = () => {
		dispatch(openFilters('dashboard'));
		dispatch(toggleIsFiltersOpens(true));
    };

	const handleSelect = (selectedOption: DashboardViews) => {
		dispatch(setLoading(true));

		const postInitialInfoRequestData: PostInitialInfoRequestData = {
		  isResetFilters: true,
		  dashboardViewType: selectedOption.id
		};
		dispatch(postInitialInfo(postInitialInfoRequestData)).unwrap()
			.then((resp: PostInitialInfoResponse) => {
				if (
					resp.cardsAndWhenChart.statusCode === 200 &&
					resp.whereChartByMarket.statusCode === 200 &&
					resp.whereChartByType.statusCode === 200 &&
					resp.filters.statusCode === 200 &&
					resp.whyChartLess90Days.statusCode === 200 &&
					resp.whyChartMore90Days.statusCode === 200
				) {
					if (currentOption?.id === appliedFiltersDropdownHeader.id) {
						dispatch(clearValue([
							dashboardFiltersStoreIds.franchisees,
							dashboardFiltersStoreIds.restaurants,
							dashboardFiltersStoreIds.consultantsOps,
							dashboardFiltersStoreIds.region,
							dashboardFiltersStoreIds.typology
						]));
						
						const filters = resp.filters.data
						let franchisees = filters.franchisees;
						if (franchisees.length > 1) {
							franchisees.unshift({
								id: null,
								description: t("dashboard.filters.franchiseesSelectAll")
							});
						}
						dispatch(updateTotalArray(
							{
								storeId: dashboardFiltersStoreIds.franchisees,
								totalArray: franchisees
							}
						));

						let restaurants = filters.stores;
						if (restaurants.length > 1) {
							restaurants.unshift({
								id: null,
								description: t("dashboard.filters.restaurantsSelectAll")
							});
						}
						dispatch(updateTotalArray(
							{
								storeId: dashboardFiltersStoreIds.restaurants,
								totalArray: restaurants
							}
						));

						let consultantsOps = filters.consultants;
						if (consultantsOps.length > 1) {
							consultantsOps.unshift({
								id: null,
								description: t("dashboard.filters.consultantsOpsSelectAll")
							});
						}
						dispatch(updateTotalArray(
							{
								storeId: dashboardFiltersStoreIds.consultantsOps,
								totalArray: consultantsOps
							}
						));

						let region = filters.markets;
						if (region.length > 1) {
							region.unshift({
								id: null,
								description: t("dashboard.filters.regionSelectAll")
							});
						}
						dispatch(updateTotalArray(
							{
								storeId: dashboardFiltersStoreIds.region,
								totalArray: region
							}
						));

						let typology = filters.storeTypes;
						if (typology.length > 1) {
							typology.unshift({
								id: null,
								description: t("dashboard.filters.typologySelectAll")
							});
						}
						dispatch(updateTotalArray(
							{
								storeId: dashboardFiltersStoreIds.typology,
								totalArray: typology
							}
						));

						dispatch(setLoading(false));
					}
					dispatch(updateOption(selectedOption));
				} else {
                    dispatch(toggleModalError());
				}
				dispatch(setLoading(false));
			})
			.catch((error: ErrorInfo) => {
				dispatch(toggleModalError());
				dispatch(setLoading(false));
			});
	};

    const handleModalGrid = (isManagement: boolean, is90Days: boolean) => {
		const requestData: EvolutionTableRequestData = {
			isManagement: isManagement,
			is90Days: is90Days,
			isLastMonth: false
		};
		dispatch(setLoading(true));
		dispatch(getEvolutionTable(requestData)).unwrap()
			.then((resp: GetEvolutionTableResponse) => {
                if (resp.statusCode === 200) {
					dispatch(updateModalGrid({
						isManagement: isManagement,
						is90Days: is90Days
					}));
					dispatch(toggleModalGrid());
                } else {
                    dispatch(toggleModalError());
                }
				dispatch(setLoading(false));
			})
			.catch((error: ErrorInfo) => {
				dispatch(toggleModalError());
				dispatch(setLoading(false));
			});
    };

    const handleLargeModalGrid = (isManagement: boolean, is90Days: boolean) => {
		const requestData: EvolutionTableRequestData = {
			isManagement: isManagement,
			is90Days: is90Days,
			isLastMonth: false
		};
		dispatch(setLoading(true));
		dispatch(getEvolutionRestaurantTable(requestData)).unwrap()
			.then((resp: GetEvolutionRestaurantTableResponse) => {
                if (resp.statusCode === 200) {
					dispatch(toggleLargeGrid());
                } else {
					dispatch(toggleModalError());
                }
				dispatch(setLoading(false));
			})
			.catch((error: ErrorInfo) => {
				dispatch(toggleModalError());
				dispatch(setLoading(false));
			});
    };

	if (dataCards === null || currentOption === null) {
		return (
			<div className="turnover-container"></div>
		);
	} else {
		return (
			<div className="turnover-container">
				<div className="turnover-header">
					<Header
						title={t("dashboard.header.title")}
						subtitle={t("dashboard.header.subtitle")}
						isDropdown={true}
						hasButton={true}
						handleToggleFilters={handleToggleFilters}
						handleSelect={handleSelect} />
					{
						dataCards.hasTurnOverData ?
						((currentOption.id === '1') &&
						<DataCard
							title={dataCards?.totalStores}
							subtitle={t("dashboard.turnOver.dataCardLabels.totalRests")}
							icon={<RestaurantIcon />} />) :
						<p className="no-results-message">{t("dashboard.turnOver.noResults")}</p>
					}
				</div>
				{
					dataCards.hasTurnOverData &&
					<section className="turnover-section">
						<h3>{t("dashboard.turnOver.titles.turnOver")}</h3>
	
						<div className="turnover-cards-statistics">
							<div className="turnover-cards-info">
								<div className="turnover-cards-header">
									<h6>{t("dashboard.turnOver.titles.employees")}</h6>
									{
										dataCards.turnOverInfo.employeesInfo.objectivePercentage !== null &&
										<div className="turnover-objective-variation">
											<p>{t("dashboard.turnOver.titles.objective")} <span>{dataCards.turnOverInfo.employeesInfo.objectivePercentage}%</span></p>
											<span className="mx-1">&bull;</span>
											<p className={
												"turnover-variation" +
												(dataCards.turnOverInfo.employeesInfo.objectiveVariancePercentage > 0 ? " positive-variation" : "") +
												(dataCards.turnOverInfo.employeesInfo.objectiveVariancePercentage < 0 ? " negative-variation" : "") 
											}>
												{
													(dataCards.turnOverInfo.employeesInfo.objectiveVariancePercentage > 0 ? "+" : "") +
													getFormatedPercentageVariance(dataCards.turnOverInfo.employeesInfo.objectiveVariancePercentage) +
													'%'
												}
											</p>
										</div>
									}
								</div>

								<div className="turnover-cards-wrapper">
									<p className="turnover-row-title">
										{
											(appliedFilters.timeFilterType === '1' ? t("dashboard.filters.ttmMonthlyYtdRadios.ttm") : '') +
											(appliedFilters.timeFilterType === '2' ? t("dashboard.filters.ttmMonthlyYtdRadios.monthly") : '') +
											(appliedFilters.timeFilterType === '3' ? t("dashboard.filters.ttmMonthlyYtdRadios.ytd") : '') +
											' - ' +
											appliedFilters.month?.description +
											' ' +
											appliedFilters.year
										}
									</p>
									<div className="turnover-cards-row">
										<DataCard
											id="tnNationalEmpl"
											variationData={dataCards.turnOverInfo.employeesInfo.turnOverVariancePercentage}
											title={dataCards.turnOverInfo.employeesInfo.turnOverPercentage + '%'}
											hasVariation={dataCards.turnOverInfo.employeesInfo.objectivePercentage !== null}
											hasEvolutionTable={dataCards.turnOverInfo.employeesInfo.hasEvolutionTable}
											subtitle={t("dashboard.turnOver.dataCardLabels.tnNational")}
											handleClick={
												dataCards.turnOverInfo.employeesInfo.hasEvolutionTable ?
												(dataCards.turnOverInfo.employeesInfo.isMultiRestaurantTable ? handleModalGrid : handleLargeModalGrid) :
												null
											}
											isManagement={false}
											is90Days={false} />
										<DataCard
											title={dataCards.turnOverInfo.employeesInfo.exits}
											subtitle={t("dashboard.turnOver.dataCardLabels.exits")} />
										<DataCard
											title={dataCards.turnOverInfo.employeesInfo.actives}
											subtitle={t("dashboard.turnOver.dataCardLabels.actives")} />
										<DataCard
											title={dataCards.turnOverInfo.employeesInfo.entrances}
											subtitle={t("dashboard.turnOver.dataCardLabels.hirings")} />
										<DataCard
											title={dataCards.turnOverInfo.employeesInfo.notStarted}
											subtitle={t("dashboard.turnOver.dataCardLabels.notStarted")} />
									</div>
									{(appliedFilters.timeFilterType === "1") &&
										<>
											<p className="turnover-row-title">{t("dashboard.turnOver.titles.90days")}</p>
											<div className="turnover-cards-row">
												<DataCard
													id="tn90daysNationalEmpl"
													variationData={dataCards.turnOverInfo.employeesInfo.turnOver90DaysVariancePercentage}
													title={dataCards.turnOverInfo.employeesInfo.turnOver90DaysPercentage + '%'}
													hasVariation={dataCards.turnOverInfo.employeesInfo.objectivePercentage !== null}
													hasEvolutionTable={dataCards.turnOverInfo.employeesInfo.hasEvolutionTable}
													subtitle={t("dashboard.turnOver.dataCardLabels.tn90daysNational")}
													handleClick={
														dataCards.turnOverInfo.employeesInfo.hasEvolutionTable ?
														(dataCards.turnOverInfo.employeesInfo.isMultiRestaurantTable ? handleModalGrid : handleLargeModalGrid) :
														null
													}
													isManagement={false}
													is90Days={true} />
												<DataCard
													title={dataCards.turnOverInfo.employeesInfo.exits90Days}
													subtitle={t("dashboard.turnOver.dataCardLabels.exits90days")} />
												<DataCard
													title={dataCards.turnOverInfo.employeesInfo.entrances90Days}
													subtitle={t("dashboard.turnOver.dataCardLabels.hirings90days")} />
											</div>
										</>
									}
								</div>
							</div>

							<div className="turnover-cards-info">
								<div className="turnover-cards-header">
									<h6>{t("dashboard.turnOver.titles.teamManagement")}</h6>
									{
										dataCards.turnOverInfo.employeesInfo.objectivePercentage !== null &&
										<div className="turnover-objective-variation-placeholder">
										</div>
									}
								</div>
	
								<div className="turnover-cards-wrapper">
									<p className="turnover-row-title">
										{
											(appliedFilters.timeFilterType === '1' ? t("dashboard.filters.ttmMonthlyYtdRadios.ttm") : '') +
											(appliedFilters.timeFilterType === '2' ? t("dashboard.filters.ttmMonthlyYtdRadios.monthly") : '') +
											(appliedFilters.timeFilterType === '3' ? t("dashboard.filters.ttmMonthlyYtdRadios.ytd") : '') +
											' - ' +
											appliedFilters.month?.description +
											' ' +
											appliedFilters.year
										}
									</p>
									<div className="turnover-cards-row">
										<DataCard
											id="tnNationalTeam"
											variationData={dataCards.turnOverInfo.employeeManagementInfo.turnOverVariancePercentage}
											title={dataCards.turnOverInfo.employeeManagementInfo.turnOverPercentage + '%'}
											hasVariation={dataCards.turnOverInfo.employeeManagementInfo.objectivePercentage !== null}
											hasEvolutionTable={dataCards.turnOverInfo.employeesInfo.hasEvolutionTable}
											subtitle={t("dashboard.turnOver.dataCardLabels.tnNational")}
											handleClick={
												dataCards.turnOverInfo.employeeManagementInfo.hasEvolutionTable ?
												(dataCards.turnOverInfo.employeeManagementInfo.isMultiRestaurantTable ? handleModalGrid : handleLargeModalGrid) :
												null
											}
											isManagement={true}
											is90Days={false} />
										<DataCard
											title={dataCards.turnOverInfo.employeeManagementInfo.exits}
											subtitle={t("dashboard.turnOver.dataCardLabels.exits")} />
										<DataCard
											title={dataCards.turnOverInfo.employeeManagementInfo.actives}
											subtitle={t("dashboard.turnOver.dataCardLabels.actives")} />
										<DataCard
											title={dataCards.turnOverInfo.employeeManagementInfo.entrances}
											subtitle={t("dashboard.turnOver.dataCardLabels.hirings")} />
										<DataCard
											title={dataCards.turnOverInfo.employeeManagementInfo.notStarted}
											subtitle={t("dashboard.turnOver.dataCardLabels.notStarted")} />
									</div>
	
									{(appliedFilters.timeFilterType === "1") &&
										<>
											<p className="turnover-row-title">{t("dashboard.turnOver.titles.90days")}</p>
											<div className="turnover-cards-row">
												<DataCard
													variationData={dataCards.turnOverInfo.employeeManagementInfo.turnOver90DaysVariancePercentage}
													title={dataCards.turnOverInfo.employeeManagementInfo.turnOver90DaysPercentage + '%'}
													hasVariation={dataCards.turnOverInfo.employeeManagementInfo.objectivePercentage !== null}
													hasEvolutionTable={dataCards.turnOverInfo.employeesInfo.hasEvolutionTable}
													subtitle={t("dashboard.turnOver.dataCardLabels.tn90daysNational")}
													handleClick={
														dataCards.turnOverInfo.employeeManagementInfo.hasEvolutionTable ?
														(dataCards.turnOverInfo.employeeManagementInfo.isMultiRestaurantTable ? handleModalGrid : handleLargeModalGrid) :
														null
													}
													isManagement={true}
													is90Days={true} />
												<DataCard
													title={dataCards.turnOverInfo.employeeManagementInfo.exits90Days}
													subtitle={t("dashboard.turnOver.dataCardLabels.exits90days")} />
												<DataCard
													title={dataCards.turnOverInfo.employeeManagementInfo.entrances90Days}
													subtitle={t("dashboard.turnOver.dataCardLabels.hirings90days")} />
											</div>
										</>
									}
								</div>
							</div>
						</div>
						<ModalGrid />
						<ModalGridLarge />
					</section>
				}
			</div>
		);
	}

};

export default DashboardTurnover;
