import './Accordion.scss';

interface AccordionData {
    accordionData: AccordionLabels[];
    uniqueId: string;
    childComponent?: any;
}

export interface AccordionLabels {
    id: number,
    label: string,
    description: string
}

const Accordion = ({ accordionData, uniqueId, childComponent }: AccordionData) => {
    return (
        <div className="accordion-container">
            <div className="accordion accordion-flush" id={uniqueId}>
                {
                    accordionData.map((accordion, index) => {
                        return <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={"flush-heading" + uniqueId + index}>
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={"#flush-collapse" + uniqueId + index}
                                    aria-expanded="true"
                                    aria-controls={"#flush-collapse" + uniqueId + index}>
                                    <div className="accordion-label">
                                        {accordion.label}
                                    </div>
                                    <div className="accordion-icon"></div>
                                </button>
                            </h2>
                            <div id={"flush-collapse" + uniqueId + index}
                                className="accordion-collapse collapse"
                                aria-labelledby={"flush-collapse" + uniqueId + index}
                                data-bs-parent={"#" + uniqueId}>
                                {!childComponent &&
                                    <div className="accordion-body" dangerouslySetInnerHTML={{__html: accordion.description}}>
                                    </div>}
                                {childComponent &&
                                    <div className="accordion-body">
                                        {childComponent && childComponent[index]}
                                    </div>}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    );
}

export default Accordion;