interface StepData {
  label: number;
  activeStep: number
}

const Step = ({ label, activeStep }: StepData) => {

  return (
    <div className={`stepBlock ${activeStep === label ? "selected" : ""}`}>
      <div className={"circleWrapper"}>
        <div className={"circle"}>{label}</div>
      </div>
      <div className="line"></div>
    </div>
  );
};

export default Step;
