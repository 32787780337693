import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { closeFilters } from '../../features/off-canvas-filter/OffCanvasFilterSlice';
import { CrossSmallIcon } from '../../images/icons';
import AdministrationFilters from '../administration/administration-filters/AdministrationFilters';
import DashboardFilters from '../dashboard/dashboard-filters/DashboardFilters';
import './OffCanvasFilter.scss';
import { toggleIsFiltersOpens } from '../../features/dashboard/DashboardSlice';
import { toggleIsFiltersOpensAdministration } from '../../features/administration/AdministrationSlice';

const OffCanvasFilter = () => {
    const dispatch = useAppDispatch();
    const { isOpen } = useAppSelector((store) => store.offCanvasFilter);
    const { filtersComponent } = useAppSelector((store) => store.offCanvasFilter);

    const handleClose = () => {
        dispatch(closeFilters());
        if (filtersComponent === 'dashboard') {
		    dispatch(toggleIsFiltersOpens(false));
        } else if (filtersComponent === 'administration') {
		    dispatch(toggleIsFiltersOpensAdministration(false));
        }
    };

    const {t} = useTranslation();

    return (
        <div className={"off-canvas-filter-container" + (isOpen ? ' off-canvas-filter-container-open' : '')}>
            <Collapse isOpen={isOpen} horizontal>
                <div className="off-canvas-filter-wrapper">
                    <div className="off-canvas-filter-header">
                    {t("common.header.filters")}
                        <div className="off-canvas-filter-header-button" onClick={ handleClose }>
                            <CrossSmallIcon />
                        </div>
                    </div>
                    <div className="off-canvas-filter-body">
                        { filtersComponent === 'administration' && <AdministrationFilters /> }
                        { filtersComponent === 'dashboard' && <DashboardFilters /> }
                    </div>
                </div>
            </Collapse>
        </div>
    );
}

export default OffCanvasFilter;