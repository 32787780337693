import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false
};

const navMenuSlice = createSlice({
    name: 'navMenu',
    initialState,
    reducers: {
        toggleOpen: (state) => {
            state.isOpen = !state.isOpen;
        }
    }
});

export const { toggleOpen } = navMenuSlice.actions;

export default navMenuSlice.reducer;