import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { toggleModalError } from '../../features/modal-error/ModalErrorSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useTranslation } from 'react-i18next';

import Buttons from "../buttons/Buttons";

import { RedCircleError } from '../../images/icons';
import './ModalError.scss';

const ModalError = () => {
    const dispatch = useAppDispatch();

    const { isOpen } = useAppSelector((store) => store.modalError);

    const handleToggleOpen = () => {
        dispatch(toggleModalError());
    }

    const {t} = useTranslation()

    return (
        <div className='modal-error-container'>
            <Modal isOpen={isOpen} toggle={handleToggleOpen} centered={true} className="modal-error-wrapper">
                <ModalBody>
                    <span>
                        <RedCircleError />
                    </span>
                    <h1>{t("common.modalError.title")}</h1>
                    <p>{t("common.modalError.description")}</p>
                    <div className='modal-error-footer'>
                        <Buttons label={t("common.modalError.okButton")} handleOnClick={handleToggleOpen} />
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

export default ModalError;